<template>
    <!-- Page d'accueil -->
    <div class="ma-0 pa-0" style="position: absolute; width: 100%;">
        <LoadingPage class="ma-0 pa-0" v-if="!this.departures_loaded" />
        <div class="home" v-if="this.departures_loaded">
            <!-- Dialog du Formulaire de départ -->
            <v-dialog v-model="form_component" persistent scrollable width="900" class="departure_form"
                :retain-focus="false" id="dialog_component">
                <component id="component" @closeForm="closeForm" @savingSnack="triggerSnack" :id_selected="id_selected"
                    :real_departure_date_selected="real_departure_date_selected" :isRecapProp="isRecap" :isConsolide="false"
                    :isEditor="false" :uo_list="uo_list" :poles_list="poles_list" :key="form_component"
                    :is="current_component" background="white" :status_id="status_id" :departure="departure_item"
                    :departure_to_generate="departure_item">
                </component>
            </v-dialog>
            <v-row class="ma-0 pa-0 mb-5" align="center" no-gutters>
                <!-- Titre page d'accueil -->
                <h1 data-test="homepage_title" style="margin-right: 1.8rem; white-space: nowrap;">Gestion des départs</h1>

                <!-- Bouton ajout départ (que si RH) -->
                <v-btn v-if="this.$store.state.role_selected == 1"
                    class="btn_add_departure white--text font-weight-bold ma-0 pa-0 mr-5"
                    :class="$vuetify.breakpoint.xs ? 'mb-5' : ''" :width="$vuetify.breakpoint.xs ? '100%' : '10rem'"
                    height="2.4rem" elevation="0" color="depart_pink" v-on:click="openForm(null)"
                    style="text-transform: none !important; border-radius: 7px" id="btn_add_departure">
                    + Nouveau départ
                </v-btn>

                <!-- Stats départs-->
                <!-- Départs à saisir -->
                <div v-if="this.role_selected != 1">
                    <v-chip id="stat_saisir" v-if="this.departs_tab == 0" color="depart_saisir_medium"
                        class="mr-5 depart_saisir--text">
                        <div class="ma-0 pa-0" v-if="this.departs_saisir">{{ this.departs_saisir }}&nbsp;</div>
                        <div class="ma-0 pa-0" v-else>0&nbsp;</div>
                        départ<div v-if="this.departs_saisir > 1" class="ma-0 pa-0">s&nbsp;</div> à saisir
                    </v-chip>
                </div>

                <template v-if="this.role_selected == 4">
                    <v-chip id="stat_saisir" v-if="this.departs_tab == 1" color="depart_saisir_medium"
                        class="mr-5 depart_saisir--text">
                        <div class="ma-0 pa-0" v-if="this.departs_saisir">{{ this.departs_saisir }}&nbsp;</div>
                        <div class="ma-0 pa-0" v-else>0&nbsp;</div>
                        départ<div v-if="this.departs_saisir > 1" class="ma-0 pa-0">s&nbsp;</div> en cours de saisie
                    </v-chip>

                    <v-chip id="stat_saisir" v-if="this.departs_tab == 2" color="depart_saisir_medium"
                        class="mr-5 depart_saisir--text">
                        <div class="ma-0 pa-0" v-if="this.departs_saisir">{{ this.departs_saisir }}&nbsp;</div>
                        <div class="ma-0 pa-0" v-else>0&nbsp;</div>
                        départ<div v-if="this.departs_saisir > 1" class="ma-0 pa-0">s&nbsp;</div> à supprimer
                    </v-chip>
                </template>


                <template v-else>
                    <!-- Départs en attente -->
                    <v-chip id="stat_attente"
                        v-if="this.departs_tab == 0 && this.role_selected != 3 && this.role_selected != 4"
                        color="depart_attente_medium" class="depart_attente--text">
                        <div class="ma-0 pa-0" v-if="this.departs_attente">{{ this.departs_attente }}&nbsp;</div>
                        <div class="ma-0 pa-0" v-else>0&nbsp;</div>
                        départ<div v-if="this.departs_attente > 1" class="ma-0 pa-0">s&nbsp;</div> en attente<div
                            v-if="this.departs_attente > 1" class="ma-0 pa-0">s</div>
                    </v-chip>

                    <!-- Départs finalisés -->
                    <v-chip id="stat_finalise" v-if="this.departs_tab == 1" color="depart_finalise_medium"
                        class="depart_finalise--text">
                        <div class="ma-0 pa-0" v-if="this.departs_finalise">{{ this.departs_finalise }}&nbsp;</div>
                        <div class="ma-0 pa-0" v-else>0&nbsp;</div>
                        départ<div v-if="this.departs_finalise > 1" class="ma-0 pa-0">s&nbsp;</div> finalisé<div
                            v-if="this.departs_finalise > 1" class="ma-0 pa-0">s</div>
                    </v-chip>

                    <!-- Départs finalisés -->
                    <v-chip id="stat_finalise" v-if="this.departs_tab == 2" color="depart_finalise_medium"
                        class="depart_finalise--text">
                        <div class="ma-0 pa-0" v-if="this.departs_finalise">{{ this.departs_finalise }}&nbsp;</div>
                        <div class="ma-0 pa-0" v-else>0&nbsp;</div>
                        départ<div v-if="this.departs_finalise > 1" class="ma-0 pa-0">s&nbsp;</div> dans l'historique<div
                            v-if="this.departs_finalise > 1" class="ma-0 pa-0">s</div>
                    </v-chip>
                </template>

            </v-row>
            <div>
                <div style="display: flex;">
                    <!-- Tableau des départs -->
                    <v-card fluid width="100%" elevation="0">
                        <!-- Searchbar -->
                        <v-row class="ma-0 pa-0" align="center" justify="space-between">
                            <v-col id="searchbar" class="ma-0 pa-0"
                                :class="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'mb-5' : ''"
                                :cols="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '12' : '10'">
                                <v-row class="ma-0 pa-0 pl-3 rounded-lg depart_grey" align="center">
                                    <v-text-field v-model="filters.search"
                                        label="Rechercher par matricule, prénom, nom ou senior manager..." height="2.5rem"
                                        single-line hide-details flat solo background-color="depart_grey"
                                        class="ma-0 pa-0 rounded-lg" v-on:keyup.enter="searchCollaborateur"></v-text-field>
                                    <v-btn class="ma-0 pa-0" elevation="0" v-on:click="searchCollaborateur">
                                        <v-icon large class="ma-0 pa-0 rounded-lg depart_grey">mdi-magnify</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                            <v-col class="ma-0 pa-0" align="center">
                                <!-- Bouton 'Filtres' -->
                                <v-menu id="filters" offset-y transition="slide-y-transition" v-model="filter_window"
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on: onMenu, attrs }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: onTooltip }">
                                                <v-col class="ma-0 pa-0">
                                                    <v-btn v-on="{ ...onMenu, ...onTooltip }" v-bind="attrs" elevation="0"
                                                        width="80%" class="ma-0 pa-0">
                                                        <v-icon class="ma-0 pa-0">mdi-filter</v-icon>
                                                    </v-btn>
                                                    <v-badge offset-x="10" offset-y="-10" bordered color="depart_blue" v-if="filters.poles_selected != 'all'
                                                        || filters.uo_selected != 'all' || filters.status_selected != 'all'
                                                        || filters.types_selected != 'all' || filters.date_begin != 'none'
                                                        || filters.date_finished != 'none'">
                                                        <span slot="badge">!</span>
                                                    </v-badge>
                                                </v-col>
                                            </template>
                                            <span>Filtres</span>
                                        </v-tooltip>
                                    </template>
                                    <FiltersComponent :role_selected="role_selected" @applyFilters="applyFilters" />
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-row class="ma-0 pa-0" align="end" justify="space-between">
                            <div class="ma-0 pa-0">
                                <!-- Selection départs -->
                                <v-tabs v-if="$store.state.role_selected == 1" id="tabs_departs"
                                    class="ma-0 pa-0 mt-8 ml-2 rounded-lg" v-model="departs_tab"
                                    @change="updatePagination(), getDeparts()">
                                    <v-tab id="tab_current" active-class="active_tab">Process en cours</v-tab>
                                    <v-tab id="tab_process_done" active-class="active_tab">
                                        Process finalisés (Départs en cours)
                                    </v-tab>
                                    <v-tab id="tab_finalised" active-class="active_tab">Historique des départs</v-tab>
                                    <v-tab id="tab_process_to_generate" class="pink-tab">
                                        Départs à génerer
                                        <v-badge v-if="countCollabWithoutDepart !== 0" class="bdg-top mt-1" color="red"
                                            :content="countCollabWithoutDepart">
                                        </v-badge>
                                    </v-tab>

                                </v-tabs>


                                <v-tabs v-else-if="$store.state.role_selected == 4" id="tabs_departs"
                                    class="ma-0 pa-0 mt-5 rounded-lg" v-model="departs_tab"
                                    @change="updatePagination(), getDeparts()">
                                    <v-tab id="tab_current" active-class="active_tab">Départs à saisir</v-tab>
                                    <v-tab id="tab_is_typ" active-class="active_tab">
                                        En cours de saisie
                                    </v-tab>
                                    <v-tab id="tab_is_to_delete" active-class="active_tab">Départs à supprimer</v-tab>
                                    <v-tab id="tab_finalised" active-class="active_tab">Départs Finalisés</v-tab>
                                </v-tabs>

                                <!-- Selection départs -->
                                <v-tabs v-else id="tabs_departs" class="ma-0 pa-0 mt-5 rounded-lg" v-model="departs_tab"
                                    @change="updatePagination(), getDeparts()">
                                    <v-tab id="tab_current" active-class="active_tab">Départs en cours</v-tab>
                                    <v-tab id="tab_finalised" active-class="active_tab">Départs finalisés</v-tab>
                                </v-tabs>
                            </div>

                            <v-row class="ma-0 pa-0" align="center" justify="end">
                                <!-- Recharger les départs -->
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-0 pa-0" elevation="0"
                                            v-on:click.stop="updatePagination(), getDeparts()" v-on="on">
                                            <v-icon>
                                                mdi-refresh
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Recharger les départs</span>
                                </v-tooltip>

                                <!-- Pagination -->
                                <v-pagination v-if="$store.state.role_selected === 1 && departs_tab === 3" class="ma-0 pa-0"
                                    v-model="page" color="depart_blue"
                                    :length="Math.ceil(countCollabWithoutDepart / departures_per_page)" :total-visible="5"
                                    prev-icon="mdi-menu-left" next-icon="mdi-menu-right" @input="changePage"></v-pagination>



                                <v-pagination v-else class="ma-0 pa-0" v-model="page" color="depart_blue"
                                    :length="Math.ceil(total_departures / departures_per_page)" :total-visible="5"
                                    prev-icon="mdi-menu-left" next-icon="mdi-menu-right" @input="changePage"></v-pagination>
                            </v-row>
                        </v-row>

                        <v-divider></v-divider>

                        <v-data-table :loading="isLoading" height="70vh" :headers="headers" :items="departures" fixed-header
                            id="departures_table" :item-class="statusRowBackground" :items-per-page="departures_per_page"
                            hide-default-footer>
                            <!-- Ligne de la v-data-table -->
                            <!-- Manager -->
                            <template v-slot:[`item.manager_firstname`]="{ item }">
                                <p class="ma-0 pa-0">{{ item.manager_firstname }} {{ item.manager_lastname }}</p>
                            </template>

                            <!-- Date de départ réel -->
                            <template v-slot:[`item.rdv_pc_date`]="{ item }">
                                <p class="ma-0 pa-0" v-if="item.rdv_pc_date">{{ new
                                    Date(item.rdv_pc_date).toLocaleDateString('fr') }}</p>
                                <p class="ma-0 pa-0" v-else></p>
                            </template>


                            <!-- Date de départ réel -->
                            <template v-slot:[`item.delivery_pc_date`]="{ item }">
                                <p class="ma-0 pa-0" v-if="item.delivery_pc_date">{{ new
                                    Date(item.delivery_pc_date).toLocaleDateString('fr') }}</p>
                                <p class="ma-0 pa-0" v-else></p>
                            </template>

                            <!-- Date de remise anticipée du PC  -->
                            <template v-slot:[`item.early_rdv_pc_date`]="{ item }">
                                <p class="ma-0 pa-0" v-if="item.early_rdv_pc_date">{{ new
                                    Date(item.early_rdv_pc_date).toLocaleDateString('fr') }}</p>
                                <p class="ma-0 pa-0" v-else></p>
                            </template>

                            <!-- Date de départ réel -->
                            <template v-slot:[`item.real_departure_date`]="{ item }">
                                <p class="ma-0 pa-0" v-if="item.real_departure_date">{{ new
                                    Date(item.real_departure_date).toLocaleDateString('fr') }}</p>
                                <p class="ma-0 pa-0" v-else></p>
                            </template>


                            <!-- Date de départ légal -->
                            <template v-slot:[`item.legal_date`]="{ item }">
                                <p class="ma-0 pa-0" v-if="item.legal_date">{{ new
                                    Date(item.legal_date).toLocaleDateString('fr') }}</p>
                                <p class="ma-0 pa-0" v-else></p>
                            </template>

                            <!-- Status du départ -->
                            <template v-slot:[`item.fk_departure_status_id`]="{ item }">
                                <div class="ma-0 pa-1 rounded-lg d-flex">
                                    <v-tooltip top v-if="item.automatically_validated">
                                        <template v-slot:activator="{ on }">
                                            <h5 v-if="!item.is_to_delete" class="rounded-lg pa-2 depart_saisir--text"
                                                v-on="on"
                                                :class='statusRow(item)?.color + "_medium " + statusRow(item)?.color + "--text"'>
                                                {{ ( (item.being_entered_fmi || item.being_entered_dni || item.being_entered_manager) && statusRow(item)?.color ==
                                                    "depart_saisir") ? "En cours de saisie" : statusRow(item)?.label }}
                                            </h5>
                                            <h5 v-else-if="!item.is_validate_dni"
                                                class="rounded-lg pa-2 depart_saisir--text" v-on="on"
                                                :class='statusRow(item)?.color + "_medium " + statusRow(item)?.color + "--text"'>
                                                A supprimer
                                            </h5>
                                            <h5 v-else class="rounded-lg pa-2 depart_saisir--text" v-on="on"
                                                :class='statusRow(item)?.color + "_medium " + statusRow(item)?.color + "--text"'>
                                                Finalisé
                                            </h5>
                                            <v-badge color="success" icon="mdi-autorenew" overlap v-on="on">
                                            </v-badge>
                                        </template>
                                        <span>Ce départ a été finalisé automatiquement</span>
                                    </v-tooltip>
                                    <h5 v-else-if="!item.is_to_delete || item.fk_departure_status_id === 5"
                                        class="rounded-lg pa-2 depart_saisir--text"
                                        :class='statusRow(item)?.color + "_medium " + statusRow(item)?.color + "--text"'>
                                        {{ ( (item.being_entered_fmi || item.being_entered_dni || item.being_entered_manager) && statusRow(item)?.color ==
                                            "depart_saisir") ? "En cours de saisie" : statusRow(item)?.label }}
                                    </h5>
                                    <h5 v-else-if="!item.is_validate_dni" class="rounded-lg pa-2 depart_saisir--text"
                                        :class='statusRow(item)?.color + "_medium " + statusRow(item)?.color + "--text"'>
                                        À supprimer
                                    </h5>
                                    <h5 v-else class="rounded-lg pa-2 depart_saisir--text"
                                        :class='statusRow(item)?.color + "_medium " + statusRow(item)?.color + "--text"'>
                                        Finalisé
                                    </h5>
                                </div>
                            </template>

                            <!-- Actions -->
                            <template v-slot:[`item.actions`]="{ item }">
                                <template v-if="$store.state.role_selected === 1 && departs_tab === 3">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <div class="ml-1" style="display: flex; align-items: center;">
                                                <v-icon color="depart_saisir" v-on:click.stop="openForm(item)"
                                                    v-on="on">mdi-database-plus</v-icon>
                                            </div>
                                        </template>
                                        <span>Générer</span>
                                    </v-tooltip>
                                </template>
                                <template v-else>
                                    <div style="display: flex; align-items: center;">
                                        <v-tooltip top v-if="statusRow(item)?.color == 'depart_finalise'">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="depart_finalise" v-on:click.stop="openForm(item)"
                                                    v-on="on">mdi-eye</v-icon>
                                            </template>
                                            <span>Visualiser</span>
                                        </v-tooltip>

                                        <v-tooltip top v-else-if="statusRow(item)?.color == 'depart_saisir'">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="depart_saisir" v-on:click.stop="openForm(item)"
                                                    v-on="on">mdi-pencil</v-icon>
                                            </template>
                                            <span>Saisir</span>
                                        </v-tooltip>


                                        <div v-else class="ma-0 pa-0" style="width: fit-content;">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        :style="item.fk_departure_status_id !== 7 ? 'margin-right: 0.5rem;' : ''"
                                                        color="depart_attente" v-on:click.stop="openForm(item)"
                                                        v-on="on">mdi-eye</v-icon>
                                                </template>
                                                <span>Visualiser</span>
                                            </v-tooltip>
                                            <v-tooltip
                                                v-if="$store.state.role_selected == 1 && item.fk_departure_status_id === 1"
                                                top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color="depart_attente"
                                                        v-on:click.stop="confirm_resend_mail = true, item_to_resend = item"
                                                        v-on="on">mdi-email-sync</v-icon>
                                                </template>
                                                <span>Relancer par mail</span>
                                            </v-tooltip>
                                        </div>

                                        <v-tooltip top v-if="$store.state.role_selected == 1">
                                            <template v-slot:activator="{ on }">
                                                <v-icon style="margin-left: 0.5rem;" color="depart_edit"
                                                    v-on:click.stop="openEditForm(item.departure_id, item.real_departure_date, item.fk_departure_status_id, item)"
                                                    v-on="on">mdi-pencil-box-multiple</v-icon>
                                            </template>
                                            <span>Éditer</span>
                                        </v-tooltip>

                                        <v-tooltip top
                                            v-if="$store.state.role_selected == 1 || $store.state.role_selected === 4 || $store.state.role_selected[0] === 4">
                                            <template v-slot:activator="{ on }">
                                                <v-icon style="margin-left: 0.5rem;" color="depart_finalise"
                                                    v-on:click.stop="openConsolideForm(item.departure_id)"
                                                    v-on="on">mdi-text-box-search</v-icon>
                                            </template>
                                            <span>Vue consolidée</span>
                                        </v-tooltip>

                                        <v-tooltip top v-if="$store.state.role_selected == 1">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="depart_pink" class="ml-2"
                                                    v-on:click.stop="confirm_delete = true, item_to_delete = item"
                                                    v-on="on">mdi-trash-can</v-icon>
                                            </template>
                                            <span>Supprimer</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                            </template>
                        </v-data-table>
                        <v-row class="ma-0 pa-0" align="center" justify="end" style="font-size: 14px;">
                            <p class="ma-0 pa-0 mr-3">Nombre de départs par page :</p>
                            <v-col class="ma-0 pa-0 mt-1" cols="1">
                                <v-select class="select_departures_per_page" v-model="departures_per_page" item-text="text"
                                    item-value="value" :items="[{ value: 10, text: 10 },
                                    { value: 20, text: 20 },
                                    { value: 50, text: 50 },
                                    { value: 100, text: 100 },
                                    { value: total_departures, text: 'Tous' }]" @change="refreshDeparts">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </div>

            <!-- Ecran de confirmation de suppression -->
            <v-dialog v-model="confirm_delete" width="500">
                <v-card>
                    <v-card-title class="text-h5 depart_pink white--text">
                        Confirmation
                    </v-card-title>

                    <v-card-text class="black--text mt-5">
                        Souhaitez-vous supprimer ce départ ?
                        Cette action est irreversible et supprimera le départ définitivement.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="confirm_delete = false">
                            Annuler
                        </v-btn>
                        <v-btn color="depart_blue" text v-on:click="deleteDepart(item_to_delete)">
                            Continuer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Ecran de confirmation relance mail -->
            <v-dialog v-model="confirm_resend_mail" width="500">
                <v-card>
                    <v-card-title class="text-h5 depart_pink white--text">
                        Confirmation
                    </v-card-title>

                    <v-card-text class="black--text mt-5">
                        Souhaitez-vous relancer la notification de départ par email ?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="confirm_resend_mail = false">
                            Annuler
                        </v-btn>
                        <v-btn color="depart_blue" text v-on:click="resendMail(item_to_resend)">
                            Confirmer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar v-model="snackbar" color="success">
                {{ text_snackbar }}

                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="snackbar = false">
                        Fermer
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
        <FooterComponent />
    </div>
</template>

<script>
import LoadingPage from './LoadingPage.vue'
import RhForm from './RhForm.vue'
import ManagerForm from './ManagerForm.vue'
import FmiForm from './FmiForm.vue'
import DniForm from './DniForm.vue'
import SeeConsolideForm from './SeeConsolideForm'
import FooterComponent from './FooterComponent.vue'
import EditFormComponent from './EditFormComponent.vue'

import ExportComponent from './ExportComponent.vue'
import FiltersComponent from './FiltersComponent.vue'

import TokenService from '../services/tokenService.js'
const tokenService = new TokenService();

import DepartsService from '../services/departsService.js'
const departsService = new DepartsService();

import StatusService from '../services/statusService.js'
const statusService = new StatusService();

import NotificationService from '../services/notificationService.js'
const notificationService = new NotificationService();

import ReferentielService from '../services/referentielService'
const referentielService = new ReferentielService()

export default {
    name: 'HomePage',
    components: {
        RhForm,
        ManagerForm,
        FmiForm,
        DniForm,
        SeeConsolideForm,
        FiltersComponent,
        LoadingPage,
        FooterComponent,
        EditFormComponent,
        ExportComponent,
    },
    data() {
        return {
            snackbar: false,
            text_snackbar: "",
            departures_loaded: false, //Désigne si les départs ont été chargés ou non
            id_selected: null, //Id du départ sélectionné dans la v-data-table
            real_departure_date_selected: null, //Date réelle de départ du départ sélectionné
            form_component: false, //Affichage ou non du formulaire
            current_component: '', //Composant affiché (formulaire)
            filter_window: false, //Affiche ou non de la fenêtre des filtres
            export_window: false, //Affiche ou non de la fenêtre d'export'
            headers: [], //Catégories de données
            departs_tab: 0, //Onglet pour choisir les départs en cours ou passés
            isRecap: false, //Détermine si le formulaire ouvert est un récapitulatif ou non
            isLoading: false, //Détermine si le tableau est en chargement ou non
            toExport: [],
            uo_list: [], //Liste des UO
            poles_list: [], //Liste des pôles

            //Pagination
            total_departures: 0,
            departures_per_page: 10,
            page: 1,
            pages_content: [],

            //Dialog de confirmation
            //Suppression
            confirm_delete: false,
            item_to_delete: null, //Element à supprimer
            //Relance par mail
            confirm_resend_mail: false,
            item_to_resend: null,

            //Stats
            departs_saisir: 0,
            departs_attente: 0,
            departs_finalise: 0,


            status_id: null, //Id du status de l'utilisateur
            departure_item: null, //Départ sélectionné dans la v-data-table

            //Filtres
            filters: {
                poles_selected: 'all',
                uo_selected: 'all',
                status_selected: 'all',
                types_selected: 'all',
                date_begin: "none",
                date_finished: "none",
                search: "",
            },

            //Liste des status
            status: [],

            //Liste des départs
            departures: [],
            collab_without_depart: [],

            countCollabWithoutDepart: null
        }
    },
    async mounted() {
        this.loadHeaders(); //Charge les données au montage de la page

        if(this.$store.state.role_selected === 1 || this.$store.state.role_selected === 4){
            this.getCollabWithoutDepart();
        }
        this.$root.$on('refreshHomePage', () => {
            this.getCollabWithoutDepart();
            this.loadHeaders();
            this.departures_loaded = false
            this.getStatus()
            this.updatePagination()
            this.departures_loaded = this.getDeparts()
            this.departure_item = null
            this.filters = {
                poles_selected: 'all',
                uo_selected: 'all',
                status_selected: 'all',
                types_selected: 'all',
                date_begin: "none",
                date_finished: "none",
                search: "",
            }
        })
    },
    async created() {
        await this.getStatus(); //Charge les status en fonction du profil de l'utilisateur
        let uo = await this.getUo();
        let pole = await this.getPole();

        //Stockage des données dans le store
        this.$store.commit('set_uo', uo);
        this.$store.commit('set_pole', pole);


        await this.updatePagination();
        this.departures_loaded = await this.getDeparts(); //Charge les départs en fonction du profil de l'utilisateur

    },
    methods: {
        closeForm({ is_close, snackbar_type }) {
            this.form_component = is_close
            this.triggerSnack({snackbar_type})
            this.departure_item = null
            this.getDeparts()
        },

        triggerSnack({ snackbar_type }) {
            if (snackbar_type == "depart_create") {
                this.text_snackbar = "Le départ a bien été enregistré"
                this.snackbar = true;
            }
            else if (snackbar_type == "depart_update") {
                this.text_snackbar = "Le départ a bien été mis à jour"
                this.snackbar = true;
            }
        },

        async getCollabWithoutDepart() {
            let limit = this.departures_per_page;
            let offset = this.departures_per_page * (this.page - 1)
            let result = await departsService.getCollabWithoutDepart(limit, offset, this.filters)

            this.collab_without_depart = result.data
            this.countCollabWithoutDepart = result.totCollab
        },


        async searchCollaborateur() {
            // if (this.role_selected === 1) {
            //     var limit = this.departures_per_page;
            //     var offset = this.departures_per_page * (this.page - 1);
            //     let departures = []
            //     let departure_tab = 0
            //     for (let i = 0; i <= 3; i++) {
            //         if (i === 3) {
            //             departures = (await departsService.getCollabWithoutDepart(limit, offset, this.filters)).data
            //         }
            //         else {
            //             departures = await departsService.getDeparts(limit, offset, this.filters, i)
            //         }
            //         if (departures.length > 0) {
            //             departure_tab = i
            //             break
            //         }
            //     }

            //     if (departures.length === 0) {
            //         this.departures = []
            //         return
            //     } else {
            //         this.departures = departures
            //         this.departs_tab = departure_tab

            //         if (this.departs_tab === 3) {
            //             this.loadHeaders()
            //             await this.getCollabWithoutDepart()
            //             this.departures = this.collab_without_depart
            //         }
            //         else {
            //             this.loadHeaders()
            //             await this.getCountDeparts()
            //         }
            //     }
            // }else{
            //     this.refreshDeparts()
            // }

            this.refreshDeparts()
        },


        async getDeparts() { //Fonction pour récupérer l'ensemble des départs en fonction du profil de l'utilisateur
            var limit = this.departures_per_page;
            var offset = this.departures_per_page * (this.page - 1);
            this.isLoading = true;
            if (this.$store.state.role_selected == 1) {
                this.departures = []
                if (this.departs_tab !== 3) {
                    this.loadHeaders()
                    this.departures = await departsService.getDeparts(limit, offset, this.filters, this.departs_tab);
                } else {
                    await this.getCollabWithoutDepart()
                    this.loadHeaders()
                    this.departures = this.collab_without_depart
                }
            }
            else if (this.$store.state.role_selected == 2)
                this.departures = await departsService.getManagerDeparts(this.$store.state.email, limit, offset, this.filters, this.departs_tab);
            else if (this.$store.state.role_selected == 3)
                this.departures = await departsService.getFmiDeparts(limit, offset, this.filters, this.departs_tab);
            else if (this.$store.state.role_selected == 4)
                this.departures = await departsService.getDniDeparts(limit, offset, this.filters, this.departs_tab);

            this.pages_content[this.page - 1] = this.departures;
            this.isLoading = false;
            return true;
        },
        async getStatus() { //Fonction pour récupérer l'ensemble des status par rapport au profil de l'utilisateur
            this.status = await statusService.getStatus(this.$store.state.role_selected);
        },
        async getUo() {
            //A changer la strucutre quand toutes les pages seront en place
            let tmp = await referentielService.getUo();
            this.uo_list = tmp.map(uo => {
                return {
                    text: uo.label,
                    value: uo.id_uo
                }
            })

            return tmp;
        },

        async getPole() {
            //A changer la strucutre quand toutes les pages seront en place
            let tmp = await referentielService.getPole();
            this.poles_list = tmp.map(pole => {
                return {
                    text: pole.label,
                    value: pole.id_pole
                }
            })

            return tmp;
        },

        openForm: function (item) {
            //Ouvre le formulaire avec les données du départ choisi
            if (item)
                this.id_selected = item.departure_id;
            else
                this.id_selected = null

            if (this.id_selected && this.statusRow(item)?.color != "depart_saisir") {
                this.isRecap = true
            }
            else
                this.isRecap = false;

            if (this.$store.state.role_selected == 1) {
                this.current_component = "RhForm";
                if (this.departs_tab === 3) {
                    this.departure_item = item
                }
            }

            if (this.$store.state.role_selected == 2)
                this.current_component = "ManagerForm";

            if (this.$store.state.role_selected == 3)
                this.current_component = "FmiForm";

            if (this.$store.state.role_selected == 4)
                this.current_component = "DniForm";

            this.form_component = true;

        },
        openConsolideForm: function (id) { //Ouvre le formulaire de vue consolidée avec les données du départ choisi
            this.id_selected = id;
            this.current_component = "SeeConsolideForm";
            this.form_component = true;
        },
        openEditForm: function (id, real_departure_date, status_id, departure_item) {
            this.id_selected = id;
            this.real_departure_date_selected = real_departure_date;
            this.departure_item = departure_item;
            this.status_id = status_id
            this.current_component = "EditFormComponent";
            this.form_component = true;
        },
        loadHeaders: function () { //Charge les données affichées de v-data-table en fonction de l'utilisateur
            this.headers = []
            if (this.$store.state.role_selected === 1 && this.departs_tab === 3) {
                this.headers = [
                    { text: 'Matricule', value: 'matricule' },
                    { text: 'Prénom', value: 'first_name' },
                    { text: 'Nom', value: 'last_name' },
                    { text: 'Poste', value: 'collaborateur_job' },
                    { text: 'Senior Manager', value: 'manager_fullname' },
                    { text: 'Pôle', value: 'pole' },
                    { text: 'UO', value: 'uo' },
                    { text: 'Actions', value: 'actions', sortable: false }
                ]

                return;
            }

            this.headers.push(
                { text: 'Matricule', value: 'matricule', },
                { text: 'Prénom', value: 'firstname', },
                { text: 'Nom', value: 'lastname', },
                { text: 'Poste', value: 'poste_label' },
                { text: 'Senior Manager', value: 'manager_firstname', },
                { text: 'Pôle', value: 'pole' },
                { text: 'UO', value: 'uo' },
            )

            if (this.$store.state.role_selected == 1 || this.$store.state.role_selected == 2) {
                this.headers.push({ text: 'Type de départ', value: 'departure_type_label' })
            }

            if (this.$store.state.role_selected === 4 || this.$store.state.role_selected[0] === 4 || this.$store.state.role_selected === 3 || this.$store.state.role_selected[0] === 3) {
                this.headers.push(
                    { text: "Date de remise anticipée du PC", value: "early_rdv_pc_date" }
                )
            }

            this.headers.push(
                { text: 'Date de départ réelle', value: 'real_departure_date' },
                //Champs cachés mais actifs pour la recherche
                { text: this.$vuetify.breakpoint.xs ? '' : 'Responsable (nom)', value: this.$vuetify.breakpoint.xs ? '' : 'manager_lastname', align: ' d-none' },
            );

            if (this.$store.state.role_selected == 1) {
                this.headers.push(
                    { text: 'Date de départ légale', value: 'legal_date' }
                )
            }

            if (this.$store.state.role_selected == 3) {
                this.headers.push(
                    { text: 'Date de rendez-vous', value: 'rdv_pc_date' },
                    { text: 'Date de restitution du PC', value: 'delivery_pc_date' }
                )
            }

            this.headers.push(
                { text: 'Statut', value: 'fk_departure_status_id' },
                { text: 'Actions', value: 'actions', sortable: false }
            )
        },
        statusRowBackground: function (item) {
            //Attribue la couleur de background de chaque ligne de la data-table
            if (item !== undefined) {
                if (item.is_validate_dni) {
                    return this.status.find(element => element.status_id === 5)?.color + "_background"
                } else if (this.$store.state.role_selected === 1 && this.departs_tab === 3) {
                    return this.status.find(element => element.status_id === 2)?.color + "_background"
                } else if (item.fk_departure_status_id !== undefined) {
                    return this.status.find(element => element.status_id === item.fk_departure_status_id)?.color + "_background"
                }
            }
        },


        statusRow(item) {
            if (item !== undefined) {
                if (item.is_validate_dni) {
                    return this.status.find(element => element.status_id === 5)
                } else if (this.$store.state.role_selected === 1 && this.departs_tab === 3) {
                    return this.status.find(element => element.status_id === 2)
                } else if (item.fk_departure_status_id !== undefined) {
                    return this.status.find(element => element.status_id === item.fk_departure_status_id)
                }
            }
        },

        async deleteDepart(item) {
            await departsService.deleteDepart(item.departure_id)
            await new Promise(resolve => setTimeout(resolve, 750));
            this.confirm_delete = false
            this.getDeparts()
        },
        async resendMail(item) {
            var departure = await departsService.getDepartFromId(item.departure_id)
            switch (item.fk_departure_status_id) {
                case 1:
                    await notificationService.postManagerNotification({ ...departure, departure_id: item.departure_id , who_test: this.$store.state.email });
                    this.text_snackbar = "Mail envoyé au senior manager !";
                    this.snackbar = true;
                    break;
                default:
                    console.log("Statut inconnu.");
            }
            this.confirm_resend_mail = false;
        },
        applyFilters: async function (poles_selected, uo_selected, status_selected, types_selected,
            date_begin, date_finished) {
            this.filter_window = false;
            if (poles_selected != 0) this.filters.poles_selected = poles_selected.join(',')
            else this.filters.poles_selected = "all";

            if (uo_selected != 0) this.filters.uo_selected = uo_selected.join(',')
            else this.filters.uo_selected = "all"

            if (status_selected != 0) {
                this.filters.status_selected = this.$store.state.role_selected[0] === 4 ? status_selected : status_selected.join(',')
            }
            else this.filters.status_selected = "all"

            if (types_selected != 0) this.filters.types_selected = types_selected.join(',')
            else this.filters.types_selected = "all"

            if (date_begin) this.filters.date_begin = date_begin;
            else this.filters.date_begin = "none"

            if (date_finished) this.filters.date_finished = date_finished;
            else this.filters.date_finished = "none"

            await this.updatePagination();
            await this.getDeparts();
            await this.getCollabWithoutDepart();
        },
        async exportDepartures(event) {
            this.toExport = []
            var departures
            let depart_tab = event === null || event.departures_types_selected === "en_cours" ? 0 : 1

            if (this.$store.state.role_selected == 1)
                departures = await departsService.getDeparts(this.total_departures, 0, this.filters, depart_tab);
            else if (this.$store.state.role_selected == 2)
                departures = await departsService.getManagerDeparts(this.$store.state.email, this.total_departures, 0, this.filters, depart_tab);
            else if (this.$store.state.role_selected == 3)
                departures = await departsService.getFmiDeparts(this.total_departures, 0, this.filters, depart_tab);
            else if (this.$store.state.role_selected == 4)
                departures = await departsService.getDniDeparts(this.total_departures, 0, this.filters, depart_tab);


            for (let i = 0; i < departures.length; i++) {
                this.toExport.push({
                    'Matricule': departures[i].matricule,
                    'Prénom': departures[i].firstname,
                    'Nom': departures[i].lastname,
                    'Manager': departures[i].manager_firstname + ' ' + departures[i].manager_lastname,
                    'Pôle': departures[i].pole,
                    'UO': departures[i].uo,
                    'Type de départ': departures[i].departure_type_label,
                    'Date de départ réelle': departures[i].real_departure_date,
                    'Statut': this.status[departures[i].fk_departure_status_id - 1].label
                })
            }
        },
        async changePage() { //Changement page - Chargement des départs
            if (!this.pages_content[this.page - 1]) {
                await this.getDeparts()
            }
            else
                this.departures = this.pages_content[this.page - 1]
        },
        async getCountDeparts() {
            var countDepartures;
            if (this.$store.state.role_selected == 1)
                countDepartures = await departsService.getCountDeparts(this.filters, this.departs_tab);
            if (this.$store.state.role_selected == 2) {
                countDepartures = await departsService.getCountManagerDeparts(this.$store.state.email, this.filters, this.departs_tab);
            }
            if (this.$store.state.role_selected == 3)
                countDepartures = await departsService.getCountFmiDeparts(this.filters, this.departs_tab);
            if (this.$store.state.role_selected == 4)
                countDepartures = await departsService.getCountDniDeparts(this.filters, this.departs_tab);
            this.total_departures = countDepartures.total;
            this.departs_saisir = countDepartures.saisir;
            this.departs_attente = countDepartures.attente;
            this.departs_finalise = countDepartures.finalise;
        },
        async updatePagination() {
            this.page = 1;
            await this.getCountDeparts();
            this.pages_content = new Array(Math.ceil(this.total_departures / this.departures_per_page)).fill(null);
        },
        async refreshDeparts() {
            await this.updatePagination();
            await this.getDeparts();
            await this.getCollabWithoutDepart();
        }
    },



    computed: {
        role_selected() {
            return this.$store.state.role_selected;
        },
    }
}
</script>

<style>
.home {
    margin: 1rem 2rem;
}

.depart_attente_background,
.depart_saisir_background,
.depart_finalise_background,
.depart_annule_background {
    cursor: pointer;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier();
}

.depart_attente_background:hover,
.depart_saisir_background:hover,
.depart_finalise_background:hover,
.depart_annule_background:hover {
    transform: translateX(-5px);
}

.btn_add_departure {
    transition-duration: .4s;
    transition-timing-function: cubic-bezier();
}

.btn_add_departure:hover {
    transform: translateY(-3px);
    filter: grayscale(0.1)
}

.active_tab {
    background-color: rgba(191, 234, 255, 0.3);
}


/* .pink-tab {
    background-color: rgba(214, 0, 94, 1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white !important;
} */


.bdg-top {
    top: -10px;
}


.select_departures_per_page {
    font-size: 14px;
}

@media screen and (max-width: 1450px) {
    th {
        font-size: .6rem !important;
        white-space: nowrap;
    }

    td {
        font-size: .6rem !important;
        white-space: nowrap;
    }
}

::-webkit-scrollbar {
    width: 10px;
    background: #fcfcfc;
}

::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #d6d6d6;
}
</style>