import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"

export const baseUrl = process.env.VUE_APP_URL_API + "/personnes";

export default class PersonnesService {

  createPerson(personne, managerId) {
    return new Promise((resolve, reject) => {
      apiClient.post(`${baseUrl}`,
        {
          id_manager: managerId, 
          matricule: personne.matricule,
          firstname: personne.firstName, 
          lastname: personne.lastName, 
          id_pole: personne.id_pole,
          id_uo: personne.id_uo, 
          email: personne.email, 
          birth_date: personne.birthDate,
          entry_date: personne.entryDate, 
          poste_label: personne.job,
          type_pop: personne.typePop, 
          id_school: personne.id_school
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token, } },)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        });
    })
  }

  updatePersonPm(pm_matricule, person_id) {
    return new Promise((resolve, reject) => {
      apiClient.patch(`${baseUrl}/${person_id}`,
        {
          "pm_matricule": pm_matricule,
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getPersonne(id) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/${id}`, { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }
}