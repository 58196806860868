import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"

export const baseUrl = process.env.VUE_APP_URL_API + "/notifications";

export default class NotificationService {

  //Fonction envoie mail
  postNotification(title, mailto, mailfrom, cc, message) {
    return new Promise((resolve, reject) => {
      apiClient.post(`${baseUrl}`,
        { title: title, mailto: mailto, mailfrom: mailfrom, cc: cc, html: message },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        });
    })
  }

  //Après Formulaire Manager -> Mail envoyé à ap_paie
  postRhNotification(departure, departure_type_label, email_to_send) {
    var title = "[TEST - Ariane Offboarding] - Validation d'un départ par " + departure.manager_firstname + " " + departure.manager_lastname;
    let mailto = email_to_send;
    // var mailto = "ap_paie@mc2i.fr" 
    var mailfrom = "noreply@mc2i.fr"

    var first_part = `
        <html >
          <body style="background-color: #eeeeee; text-align: center;">
            <div style="width: 60%%; padding: 10px; border-radius: 10px; background-color: white; margin: auto auto;">
                <img alt="mc2i" src="https://www.solutions-ressources-humaines.com/logo/69edf14f50153a9logo-mc2i.png" width="180">
                <h1>Bonjour,</h1>
                <h2>` + departure.manager_firstname + ' ' + departure.manager_lastname + ` a validé le départ de ` + departure.firstname + ' ' + departure.lastname + ` au ` + new Date(departure.real_departure_date).toLocaleDateString('fr') + `.<br></h2>`

    var is_priornotice_modification = (departure.is_priornotice_reduction && departure_type_label == "Démission") ?
      '<p> Une demande de réduction de préavis a été exprimée pour la date du ' + new Date(departure.wanted_departure_date).toLocaleDateString('fr') + '</p>' :
      (departure.is_priornotice_elongation && departure_type_label == "Démission") ? '<p> Une demande de prolongation de préavis a été exprimée pouFr la date du ' + new Date(departure.wanted_departure_date).toLocaleDateString('fr') + '</p>' : '';
    var second_part = `
                <div style="text-align: center; padding: 2rem 0;">
                  <a href="https://gestion-depart-int.mc2i.fr/consolide/` + departure.departure_id + `"
                  style="padding: .5rem 1rem; background-color: #dd0061; border-radius: 5px; color: #ffffff;text-decoration: none;font-weight:bold;">
                      Accéder au départ
                  </a>
                </div>
                <h4 style="text-align: center;">
                    Vous pouvez consulter le départ sur https://gestion-depart-int.mc2i.fr/consolide/` + departure.departure_id + `<br>
                    ou via le dashboard de l'application.
                </h4>

                <p style="text-align: start">Bonne journée,<br>
                L'équipe RH<br></p>
              </div>
            </body>
          </html>
        `
    var message = first_part + second_part;
    this.postNotification(title, mailto, mailfrom, null, message)
  }

  //Après formulaire Rh -> Mail envoyé au manager
  postManagerNotification(departure) {
    return new Promise((resolve, reject) => {
      apiClient.post(`${baseUrl}`,
        {
          departure_id: departure.departure_id,
          first_name: departure.firstname,
          last_name: departure.lastname,
          mail_collab: departure.email,
          mail_manager: departure.manager_email,
          uo_label: departure.uo,
          pole_label: departure.pole,
          departure_type_label: departure.departure_type_label,
          poste_label: departure.poste_label,
          fk_departure_type_id: departure.fk_departure_type_id,
          reception_date: departure.reception_date,
          delivery_receipt_date: departure.delivery_receipt_date,
          wanted_departure_date: departure.wanted_departure_date,
          legal_date: departure.legal_date,
          is_priornotice_reduction: departure.is_priornotice_reduction,
          is_priornotice_elongation: departure.is_priornotice_elongation,
          is_quitting_forever: departure.is_quitting_forever,
          end_delay_prevenance: departure.end_delay_prevenance,
          date_information_consultant: departure.date_information_consultant, demand_date_approval: departure.demand_date_approval,
          end_date_refractation: departure.end_date_refractation,
          sign_date_convention: departure.sign_date_convention,
          preavis_reception_date: departure.preavis_reception_date,
          end_demand_date_approval: departure.end_demand_date_approval,
          return_date_collab: departure.return_date_collab,
          is_wanted_early_departure: departure.is_wanted_early_departure,
          who_test: departure.who_test
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        });
    })
  }

  //Après formulaire Manager -> Mail envoyé à FMI
  postFmiNotification(departure, email_to_send) {
    var title = "[TEST - Ariane Offboarding] - Vous avez un formulaire à compléter"
    // var mailto = "molka.benouaghrem@mc2i.fr"
    let mailto = email_to_send;
    var mailfrom = "noreply-ariane-offboarging@mc2i.fr"
    // var cc = "thierry.guillou@mc2i.fr"
    var cc = ""

    var first_part = `
        <html >
          <body style="background-color: #eeeeee; text-align: center;">
            <div style="width: 60%%; padding: 10px; border-radius: 10px; background-color: white; margin: auto auto;">
                <img alt="mc2i" src="https://www.solutions-ressources-humaines.com/logo/69edf14f50153a9logo-mc2i.png" width="180">
                <h1>Bonjour FMI,</h1>
                <h2>Nous vous informons du départ de : ` + departure.firstname + ' ' + departure.lastname + ', ' + departure.poste_label + `.<br></h2>
                <p>Sa date de départ est fixée au ` + new Date(departure.real_departure_date).toLocaleDateString('fr') + `.</p>`

    var second_part = `
              <p>Nous vous demandons de bien vouloir compléter le formulaire de départ dans <a href="https://gestion-depart-int.mc2i.fr/login">l'Outil de Ariane Offboarding</a> afin de pouvoir les suivre et adapter notre stratégie.</p>
              <div style="text-align: center; padding: 2rem 0;">
                <a href="https://gestion-depart-int.mc2i.fr/fmi/` + departure.departure_id + `"
                style="padding: .5rem 1rem; background-color: #dd0061; border-radius: 5px; color: #ffffff;text-decoration: none;font-weight:bold;">
                    Accéder au formulaire
                </a>
              </div>
              
              <p style="text-align: start">Bonne journée,<br>
              L'équipe RH<br>
              </p>
              
              </div>
            </body>
          </html>
        `
    var message = first_part + second_part;
    this.postNotification(title, mailto, mailfrom, cc, message)
  }

  //Après formulaire FMI -> Mail envoyé à DNI
  postDniNotification(departure, email_to_send) {
    var title = "[TEST - Ariane Offboarding] - Vous avez un formulaire à compléter"
    // var mailto = "molka.benouaghrem@mc2i.fr"
    let mailto = email_to_send;
    var mailfrom = "noreply@mc2i.fr"
    // var cc = "thierry.guillou@mc2i.fr"
    var cc = ""

    var first_part = `
        <html >
          <body style="background-color: #eeeeee; text-align: center;">
            <div style="width: 60%%; padding: 10px; border-radius: 10px; background-color: white; margin: auto auto;">
                <img alt="mc2i" src="https://www.solutions-ressources-humaines.com/logo/69edf14f50153a9logo-mc2i.png" width="180">
                <h1>Bonjour DNI,</h1>
                <h2>Nous vous informons du départ de : ` + departure.firstname + ' ' + departure.lastname + ', ' + departure.poste_label + `.<br></h2>
                <p>Sa date de départ est fixée au ` + new Date(departure.real_departure_date).toLocaleDateString('fr') + `.</p>`

    var second_part = `
              <p>Nous vous demandons de bien vouloir compléter le formulaire de départ dans <a href="https://gestion-depart-int.mc2i.fr/login">l'Outil Ariane Offboarding</a> afin de pouvoir les suivre et adapter notre stratégie.</p>
              <div style="text-align: center; padding: 2rem 0;">
                <a href="https://gestion-depart-int.mc2i.fr/dni/` + departure.departure_id + `"
                style="padding: .5rem 1rem; background-color: #dd0061; border-radius: 5px; color: #ffffff;text-decoration: none;font-weight:bold;">
                    Accéder au formulaire
                </a>
              </div>
              
              <p style="text-align: start">Bonne journée,<br>
              L'équipe RH<br>
              </p>
              
              </div>
            </body>
          </html>
        `
    var message = first_part + second_part;
    this.postNotification(title, mailto, mailfrom, cc, message)
  }

  //Après formulaire Rh -> Mail envoyé au collaborateur
  postCollabNotification(departure, departure_type_label, email_to_send) {
    var title = "[TEST - Ariane Offboarding] - Départ enregistré"
    // var mailto = "molka.benouaghrem@mc2i.fr"
    let mailto = email_to_send;
    var mailfrom = "noreply@mc2i.fr"
    // var cc = "thierry.guillou@mc2i.fr"
    var cc = ""

    var first_part = `
        <html >
          <body style="background-color: #eeeeee; text-align: center;">
            <div style="width: 60%%; padding: 10px; border-radius: 10px; background-color: white; margin: auto auto;">
                <img alt="mc2i" src="https://www.solutions-ressources-humaines.com/logo/69edf14f50153a9logo-mc2i.png" width="180">
                <h1>Bonjour ` + departure.firstname + `,</h1>`

    var is_demission = departure_type_label == "Démission" ?
      '<p>Nous accusons bonne réception de ton courrier de démission en date du ' + new Date(departure.reception_date).toLocaleDateString('fr') + ', point de départ de ton préavis.<br></p>' :
      '<p>Afin de préparer au mieux ton départ de la société prévu en date du ' + new Date(departure.legal_date).toLocaleDateString('fr') + ','

    /*var is_priornotice_modification = (departure.is_priornotice_reduction) ?
      '<p>Ta demande de réduction de préavis est transmise à ton management pour étude de faisabilité.</p>' :
      (departure.is_priornotice_elongation) ?
        '<p>Ta demande de prolongation de préavis est transmise à ton management pour étude de faisabilité.</p>' : ''*/

    var second_part = departure_type_label == "Démission" ? `
                <p>Un courrier de confirmation de la date de rupture de ton contrat de travail te sera prochainement adressé sur ton adresse mail personnelle via la solution AR24.</p>
                <p>L'utilisation de cette solution nécessite ton accord que tu peux signifier en cliquant <a href="https://app.ar24.fr/fr/consent/register/45ac13b314ad6bb489c2">ici</a>.</p>
                <p><strong>Attention</strong> merci d'indiquer ton adresse mail personnelle et non celle de MC2I.</p><br>
                <p style="text-align: start">Bonne journée,<br>
                L'équipe RH<br></p>
            </div>
          </body>
        </html> ` : `un courrier de confirmation de la date de rupture de ton contrat de travail te sera prochainement adressé sur ton adresse mail personnelle via la solution AR24.</p>
        <p>L'utilisation de cette solution nécessite ton accord que tu peux signifier en cliquant <a href="https://app.ar24.fr/fr/consent/register/45ac13b314ad6bb489c2">ici</a>.</p>
        <p><strong>Attention</strong> merci d'indiquer ton adresse mail personnelle et non celle de MC2I.</p><br>
        <p style="text-align: start">Bonne journée,<br>
        L'équipe RH<br></p>
    </div>
  </body>
</html>
`
    var message = first_part + is_demission + second_part;
    this.postNotification(title, mailto, mailfrom, cc, message)
  }

  //Après formulaire manager -> Mail envoyé à Paie
  //A supprimer
  /*postPaieNotification(departure, departure_type_label) {
    var title = "[TEST - Ariane Offboarding] - Information de départ"
    var mailto = "paul.gautier@mc2i.fr" //ap_paie@mc2i.fr
    var mailfrom = "noreply@mc2i.fr"

    var first_part = `
      <html >
        <body style="background-color: #eeeeee; text-align: center;">
          <div style="width: 60%%; padding: 10px; border-radius: 10px; background-color: white; margin: auto auto;">
              <img alt="mc2i" src="https://www.solutions-ressources-humaines.com/logo/69edf14f50153a9logo-mc2i.png" width="180">
              <h1>Bonjour,</h1>
              <h2>Nous vous informons du départ de : ` + departure.firstname + ' ' + departure.lastname + ', ' + departure.poste_label + `.<br></h2>
              <h3>Il s'agit d'un(e): ` + departure.departure_type_label + `.</h3>`
              
      var is_demission = departure_type_label == "Démission" ?
      '<p>Nous avons reçu sa lettre le ' + new Date(departure.reception_date).toLocaleDateString('fr') + '.</p>' +
      '<p>Légalement, sa date de départ est fixée au ' + new Date(departure.real_departure_date).toLocaleDateString('fr') + '.</p>' :
      '<p>Sa date de départ est fixée au ' + new Date(departure.real_departure_date).toLocaleDateString('fr') + '.</p>'
 
      var second_part = `
            <p>Sauf avis contraire de votre part, nous vous informons qu'un courrier sera adressé à ` + departure.firstname + ' ' + departure.lastname + ` un mois avant son départ, la/le libérant de l’interdiction de concurrence stipulée dans son contrat de travail.<br>
            ` + departure.manager_firstname + ' ' + departure.manager_lastname + ` réalisera l'entretien de départ.</p>
            <p style="text-align: start">Bonne journée,<br>
            L'équipe RH<br></p>
            
            </div>
          </body>
        </html>
      `
      var message = first_part + is_demission + second_part;
      this.postNotification(title, mailto, mailfrom, null, message)
  }*/
}