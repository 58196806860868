<template>
  <v-app>
    <NavBar v-if="this.$store.state.role_selected"/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components:{
    NavBar
  },

  data: () => ({

  }),
  created() {
  window.addEventListener('beforeunload', () => {
    sessionStorage.setItem('store', JSON.stringify(this.$store.state))
  })
}
};
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
}
</style>
