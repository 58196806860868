<template>
    <!-- Formulaire de départ -->
    <div class="ma-0 pa-0 fmi_form">
        <!-- Header -->
        <v-container v-if="!isEditor && !isConsolide" fluid align="center" justify="center"
            class="ma-0 pa-0 pt-3 pb-3 white--text d-flex justify-space-between depart_pink">
            <h1 v-if="this.departure.fk_departure_status_id == 3" class="ml-5" style="width: fit-content;">Formulaire de
                départ</h1>
            <h1 v-else class="ml-5" style="width: fit-content;">Récapitulatif du départ</h1>
            <v-col cols="3" class="ma-0 pa-0 d-flex justify-end">
                <v-tooltip top v-if="!isEditor && !isRecap">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon id="save_form" large color="white" class="mr-3" v-on:click="saveForm(true, false)"
                            v-bind="attrs" v-on="on">mdi-content-save</v-icon>
                    </template>
                    <span>Sauvegarder</span>
                </v-tooltip>
                <v-icon id="close_form" large color="white" class="mr-7"
                    v-on:click="JSON.stringify(departure) === JSON.stringify(departure_template) ? closeForm(false, '') : confirm_close = true">mdi-close-circle-outline</v-icon>
            </v-col>
        </v-container>

        <div v-if="is_loading">
            <v-progress-linear indeterminate color="depart_pink" height="10px"></v-progress-linear>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
        </div>

        <!-- Formulaire -->
        <v-form v-else ref="form_departure" class="ma-5" lazy-validation>
            <v-stepper non-linear class="ma-0 pa-0" elevation="0" v-model="form_step">
                <v-stepper-header class="elevation-0">
                    <v-stepper-step editable step="1" color="depart_blue">
                        <small>Collaborateur</small>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step editable step="2" color="depart_blue">
                        <small>Départ</small>
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1" class="ma-0 pa-0">
                        <v-form ref="step_one">
                            <!-- Consultant concerné -->
                            <div>
                                <h2 class="ma-0 pa-0 mb-5">Collaborateur concerné</h2>
                                <!-- Matricule -->
                                <v-card class="rounded-lg mb-5" width="95%"
                                    style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); margin: auto auto;">
                                    <v-card-title class="mb-2">
                                        <v-col>
                                            <v-row>
                                                <h2 style="color: #B4B4B4;">{{ departure.matricule }}&nbsp;</h2>
                                                <h2>{{ departure.firstname }} {{ departure.lastname }}</h2>
                                            </v-row>
                                            <v-row class="mt-0">
                                                <em class="subtitle-2">{{
                                                    departure.poste_label
                                                }}</em>
                                            </v-row>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p>
                                                <strong>Responsable :</strong> {{ departure.manager_firstname }}
                                                {{ departure.manager_lastname }}<br>
                                                <strong>UO :</strong> {{ departure.uo }}<br>
                                                <strong>Date de départ réelle :</strong> {{ departure.real_departure_date ?
                                                    new Date(departure.real_departure_date).toLocaleDateString('fr') : "" }}<br>
                                            </p>
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </div>
                        </v-form>
                        <v-row class="ma-0 pa-0 mt-10 justify-end">
                            <!-- Bouton continuer -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="form_step++"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Continuer
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="ma-0 pa-0">
                        <v-form ref="step_two">
                            <!-- Départ -->
                            <div class="mb-15">
                                <h2 style="margin-bottom: 1rem;">Départ</h2>


                                <!-- Date remise PC-->
                                <v-row>
                                    <v-col cols="6">
                                        <v-menu v-model="rdv_pc_date_picker" :close-on-content-click="false"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field outlined v-model="formatedDisplayDate.rdv_pc_date"
                                                    label="Date de rendez-vous*" readonly color="depart_pink"
                                                    :rules="required" append-icon="mdi-calendar" v-bind="attrs"
                                                    v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker color="depart_blue" scrollable :readonly="isRecap || isConsolide"
                                                v-model="departure.rdv_pc_date"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-menu v-model="delivery_pc_date_picker" :close-on-content-click="false"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field outlined v-model="formatedDisplayDate.delivery_pc_date"
                                                    label="Date de restitution du PC*" readonly color="depart_pink"
                                                    append-icon="mdi-calendar" :rules="required" v-bind="attrs"
                                                    v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker color="depart_blue" scrollable :readonly="isRecap || isConsolide"
                                                v-model="departure.delivery_pc_date"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>

                                <v-row class="mb-8">
                                    <v-col cols="12">
                                        <v-text-field v-model="departure.pc_number" outlined label="Numéro du PC remis*"
                                            hide-details="auto" :readonly="isRecap || isConsolide" :clearable="!isRecap"
                                            type="text" :rules="number_rules"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <!-- Commentaire -->
                                    <v-col cols="12">
                                        <v-textarea v-model="departure.fmi_commentary" label="Commentaire" outlined
                                            auto-grow :readonly="isRecap || isConsolide" :clearable="!isRecap"></v-textarea>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-form>
                        <!-- Bouton "valider" départ -->
                        <v-row class="ma-0 pa-0 justify-end">
                            <!-- Bouton "retour" -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn color="depart_pink" class="depart_pink--text font-weight-bold mb-10 mr-10"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step--" outlined
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px; border: thin solid red;">
                                    Retour
                                </v-btn>
                            </div>

                            <div v-if="!isEditor && !isRecap" class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="validateForm"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Valider le départ
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-form>

        <v-snackbar v-model="snackbar" color="success">
            <span class="white--text">Départ enregistré avec succès !</span>
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false">
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <!-- Ecran de confirmation fermeture -->
        <v-dialog v-model="confirm_close" width="500">
            <v-card>
                <v-card-title class="text-h5 depart_pink white--text">
                    Confirmation
                </v-card-title>

                <v-card-text class="black--text mt-5">
                    Des informations ont été saisies. Souhaitez-vous les conserver ?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text v-on:click="closeForm(false, '')">
                        Ignorer
                    </v-btn>
                    <v-btn color="depart_blue" text v-on:click="updateDepart(true, true)">
                        Sauvegarder
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Ecran confirmation -->
        <v-dialog v-model="confirm_form" width="500">
            <v-card>
                <v-card-title class="text-h5 depart_pink white--text">
                    Confirmation
                </v-card-title>

                <v-card-text v-if="!isValidate" class="black--text mt-5">
                    Souhaitez-vous finaliser le départ ?
                </v-card-text>

                <v-card-text v-else class="black--text mt-5">
                    Veuillez patienter, le formulaire est en cours de finalisation...
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isValidate" color="red" text @click="confirm_form = false">
                        Annuler
                    </v-btn>
                    <v-btn v-if="!isValidate" color="depart_blue" text v-on:click="updateDepart(false, true)">
                        Continuer
                    </v-btn>
                    <v-progress-circular v-else color="primary" indeterminate></v-progress-circular>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DepartsService from '../services/departsService'
const departsService = new DepartsService()

import NotificationService from '../services/notificationService'
const notificationService = new NotificationService()

import ReferentielService from '../services/referentielService'
const referentielService = new ReferentielService()

export default {
    name: 'FmiForm',
    props: ['id_selected', 'isRecapProp', 'isEditor', 'isConsolide'],
    data() {
        return {
            is_loading: true,
            uo_list: null,

            confirm_form: false,
            confirm_close: false,
            isRecap: false, //Défini si c'est un récapitulatif

            delivery_pc_date_picker: false,
            rdv_pc_date_picker: false,

            form_step: 1, //Etape du formulaire

            isValidate: false, //Défini si le formulaire est en cours de sauvegarde

            formatedDisplayDate: {
                delivery_pc_date: null,
                rdv_pc_date: null
            },

            snackbar: false,

            //Données
            departure: {
                "fmi_commentary": "",
                "delivery_pc_date": null,
                "delivery_receipt_date": null,
                "departure_id": null,
                "departure_reason_label": "",
                "departure_type_label": "",
                "email": "",
                "firstname": "",
                "fk_departure_reason_id": null,
                "fk_departure_status_id": null,
                "fk_departure_type_id": null,
                "fk_manager_id": null,
                "fk_person_id": null,
                "fk_pm_id": null,
                "lastname": "",
                "legal_date": null,
                "manager_email": "",
                "manager_firstname": "",
                "manager_id": null,
                "manager_lastname": "",
                "manager_matricule": null,
                "manager_pole": "",
                "manager_uo": "",
                "matricule": null,
                "pc_number": null,
                "person_id": null,
                "pole": "",
                "poste_label": null,
                "real_departure_date": null,
                "reception_date": null,
                "rdv_pc_date": null,
                "who_test": null,
                "is_validate_sm" : false,
                "is_validate_dni" : false,
                "is_validate_fmi" : false,
            },
            departure_template: {},

            //Règles
            number_rules: [
                value => /^[0-9]+$/.test(value) || 'Ce champ ne peut être composé que de chiffres !'
            ],
            required: [
                value => !!value || 'Champs requis.'
            ],
        }
    },
    async mounted() {
        await this.getDepart(); //Récupération des données du départ sélectionné
        this.uo_list = await referentielService.getUo()
        if ( this.departure.fk_departure_status_id !== 7 && this.departure.fk_departure_status_id !== 9 ){
            this.isRecap = true;
        }
        else {
            this.isRecap = this.isRecapProp;
        }


        this.is_loading = false;
    },
    methods: {
        async getDepart() { //Récupérer le départ sélectionné
            if (this.id_selected) {
                this.departure = await departsService.getDepartFromId(this.id_selected);
                this.departure_template = JSON.parse(JSON.stringify(this.departure));
            }
        },
        closeForm(isSaving, snackbar_type) { //Fermer le formulaire
            if (window.location.pathname.split('/')[1] === "fmi"){
                if(isSaving){
                    this.snackbar = true;
                }else{
                    return window.location.href = "/home"
                }
            }
            this.$emit("closeForm", { is_close: isSaving, snackbar_type: snackbar_type });
            this.isValidate = false;
        },
        validateForm() { //Valider le formulaire
            if (this.$refs.form_departure.validate() && this.$refs.step_one.validate() && this.$refs.step_two.validate()) {
                this.confirm_form = true;
            }
            else
                this.$refs.form_departure.$el.scrollIntoView({ behavior: 'smooth' })
        },
        async updateDepart(isSaving, isClosing) {
            if (isSaving)
                await departsService.updateDepartFmi({...this.departure , who_test: this.$store.state.email}, "saving");
            else {
                this.isValidate = true;
                await departsService.updateDepartFmi({...this.departure , who_test: this.$store.state.email}, "validation");
                //await notificationService.postDniNotification(this.departure, this.$store.state.email);
            }
            this.confirm_form = false;
            this.closeForm(!isClosing, "depart_update");
        },
        saveForm() {
            this.updateDepart(true, false)
            this.departure_template = JSON.parse(JSON.stringify(this.departure));
        }
    },


    watch: {
        "departure.delivery_pc_date": function (val) {
            if (val) {
                this.formatedDisplayDate.delivery_pc_date = new Date(val).toLocaleDateString('fr-FR');
            }
        },


        "departure.rdv_pc_date": function (val) {
            if (val) {
                this.formatedDisplayDate.rdv_pc_date = new Date(val).toLocaleDateString('fr-FR');
            }
        },
    }
}
</script>

<style scoped>
.fmi_form {
    background-color: white;
    height: fit-content;
    min-height: 25rem;
}

#close_form {
    transition-duration: .4s;
    transition-timing-function: cubic-bezier();
}

#close_form:hover {
    transform: scale(1.2) rotate(90deg);
}
</style>