<template>
    <!-- Formulaire de départ -->
    <div class=" dni_form">
        <!-- Header -->
        <v-container v-if="!isEditor && !isConsolide" fluid align="center" justify="center"
            class="ma-0 pa-0 pt-3 pb-3 white--text d-flex justify-space-between depart_pink">
            <h1 class="ml-5" style="width: fit-content;">Formulaire de départ</h1>
            <v-col cols="3" class="ma-0 pa-0 d-flex justify-end">
                <v-tooltip top v-if="!isEditor && !isRecap">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon id="save_form" large color="white" class="mr-3" v-on:click="saveForm()" v-bind="attrs"
                            v-on="on">mdi-content-save</v-icon>
                    </template>
                    <span>Sauvegarder</span>
                </v-tooltip>
                <v-icon id="close_form" large color="white" class="mr-7"
                    v-on:click="JSON.stringify(departure) === JSON.stringify(departure_template) ? closeForm(false, '') : confirm_close = true">mdi-close-circle-outline</v-icon>
            </v-col>
        </v-container>

        <div v-if="is_loading">
            <v-progress-linear indeterminate color="depart_pink" height="10px"></v-progress-linear>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
        </div>

        <!-- Formulaire -->
        <v-form v-else ref="form_departure" class="ma-5" lazy-validation>
            <v-stepper non-linear elevation="0" v-model="form_step">
                <v-stepper-header class="elevation-0">
                    <v-stepper-step editable step="1" color="depart_blue" :rules="[value => step_one]">
                        <small>Collaborateur</small>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step editable step="2" color="depart_blue" :rules="[value => step_two]">
                        <small>Départ</small>
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-form ref="step_one">
                            <!-- Consultant concerné -->
                            <div>
                                <h2 class="ma-0 pa-0 mb-5">Collaborateur concerné</h2>
                                <!-- Matricule -->
                                <v-card class="rounded-lg mb-5" width="95%"
                                    style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); margin: auto auto;">
                                    <v-card-title class="mb-2">
                                        <v-col>
                                            <v-row>
                                                <h2 style="color: #B4B4B4;">{{ departure.matricule }}&nbsp;</h2>
                                                <h2>{{ departure.firstname }} {{ departure.lastname }}</h2>
                                            </v-row>
                                            <v-row class="mt-0">
                                                <em class="subtitle-2">{{
                                                    departure.poste_label
                                                }}</em>
                                            </v-row>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p>
                                                <strong>Responsable :</strong> {{ departure.manager_firstname }}
                                                {{ departure.manager_lastname }}<br>
                                                <strong>UO :</strong> {{ departure.uo }}<br>
                                                <strong>Date de départ réelle :</strong> {{ departure.real_departure_date ?
                                                    new Date(departure.real_departure_date).toLocaleDateString('fr') : "" }}<br>
                                            </p>
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </div>
                        </v-form>
                        <v-row class="ma-0 pa-0 mt-10 justify-end">
                            <!-- Bouton continuer -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="form_step++"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Continuer
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-form ref="step_two" class="pl-5">
                            <!-- Départ -->
                            <div class="mb-25">
                                <h2 style="margin-bottom: 1rem;">Départ</h2>
                                <!-- LDAP -->
                                <v-row>
                                    <v-checkbox v-model="departure.ldap" color="depart_pink"
                                        label="AD/LDAP : désactivation compte, suppression des attributs, retrait des groupes…"
                                        :readonly="isRecap || isConsolide"></v-checkbox>
                                </v-row>

                                <!-- Microsoft -->
                                <v-row>
                                    <v-checkbox v-model="departure.microsoft" color="depart_pink"
                                        label="Microsoft : Retrait des licences"
                                        :readonly="isRecap || isConsolide"></v-checkbox>
                                </v-row>

                                <!-- 3CX -->
                                <v-row>
                                    <v-checkbox v-model="departure.cx" color="depart_pink"
                                        label="3CX : Suppression du compte" :readonly="isRecap || isConsolide"></v-checkbox>
                                </v-row>

                                <!-- Google -->
                                <v-row>
                                    <v-checkbox v-model="departure.google" color="depart_pink"
                                        label="Google : Suspention du compte, retrait des groupes"
                                        :readonly="isRecap || isConsolide"></v-checkbox>
                                </v-row>

                                <!-- Whoz -->
                                <v-row>
                                    <v-checkbox v-model="departure.whoz" color="depart_pink"
                                        label="Whoz : suppression des accès"
                                        :readonly="isRecap || isConsolide"></v-checkbox>
                                </v-row>




                                <!-- Date suspention Google -->
                                <v-row align="center">
                                    <v-col cols="6">
                                        <v-menu v-model="supenstion_google_date_picker" :close-on-content-click="false"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDisplayDate.suspention_google_date" outlined
                                                    label="Date de suspension du compte Google*" readonly
                                                    color="depart_pink" append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                                    :rules="required"></v-text-field>
                                            </template>
                                            <v-date-picker color="depart_blue" scrollable :readonly="isRecap || isConsolide"
                                                :clearable="!isRecap"
                                                v-model="departure.suspention_google_date"></v-date-picker>
                                        </v-menu>
                                    </v-col>


                                    <!-- Date Google-->
                                    <v-col cols="6">
                                        <v-menu v-model="deletion_google_date_picker" :close-on-content-click="false"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="formatedDisplayDate.deletion_google_date" outlined
                                                    label="Date de suppression du compte Google*" readonly
                                                    color="depart_pink" append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                                    :rules="required"></v-text-field>
                                            </template>
                                            <v-date-picker color="depart_blue" scrollable :readonly="isRecap || isConsolide"
                                                :clearable="!isRecap"
                                                v-model="departure.deletion_google_date"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                </v-row>

                            </div>
                        </v-form>
                        <!-- Bouton "valider" départ -->
                        <v-row class="ma-0 pa-0 justify-end">
                            <!-- Bouton "retour" -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn color="depart_pink" class="depart_pink--text font-weight-bold mb-10 mr-10"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step--" outlined
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px; border: thin solid red;">
                                    Retour
                                </v-btn>
                            </div>
                            <div v-if="!isEditor && !isRecap" class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="validateForm"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Valider le départ
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-form>

        <v-snackbar v-model="snackbar" color="success">
            <span class="white--text">Départ enregistré avec succès !</span>
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false">
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <!-- Ecran de confirmation fermeture -->
        <v-dialog v-model="confirm_close" width="500">
            <v-card>
                <v-card-title class="text-h5 depart_pink white--text">
                    Confirmation
                </v-card-title>

                <v-card-text class="black--text mt-5">
                    Des informations ont été saisies. Souhaitez-vous les conserver ?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text v-on:click="closeForm(false, '')">
                        Ignorer
                    </v-btn>
                    <v-btn color="depart_blue" text v-on:click="updateDepart(true, true)">
                        Sauvegarder
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Confirmation -->
        <v-dialog v-model="confirm_form" width="500">
            <v-card>
                <v-card-title class="text-h5 depart_pink white--text">
                    Confirmation
                </v-card-title>

                <v-card-text v-if="!isValidate" class="black--text mt-5">
                    Souhaitez-vous finaliser le départ ?
                </v-card-text>


                <v-card-text v-else class="black--text mt-5">
                    Veuillez patienter, le formulaire est en cours de finalisation...
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isValidate" color="red" text @click="confirm_form = false">
                        Annuler
                    </v-btn>
                    <v-btn v-if="!isValidate" color="depart_blue" text v-on:click="updateDepart(false, true)">
                        Continuer
                    </v-btn>
                    <v-progress-circular v-else color="primary" indeterminate></v-progress-circular>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DepartsService from '../services/departsService'
const departsService = new DepartsService()

import UoService from '../services/referentielService'
const uoService = new UoService()

import moment from 'moment'

export default {
    name: 'DniForm',
    props: ['id_selected', 'isRecapProp', 'isEditor', 'isConsolide'],
    data() {
        return {
            is_loading: true,
            uo_list: null,
            confirm_form: false,
            confirm_close: false,
            isRecap: false, //Défini si c'est un récapitulatif

            deletion_google_date_picker: false,
            supenstion_google_date_picker: false,

            formatedDisplayDate: {
                deletion_google_date: null,
                suspention_google_date: null
            },

            form_step: 1, //Etape du formulaire
            creation_loading: false, //Ecran de chargement pendant création du départ

            step_one: true, //Indicateur de champs erronés partie 1
            step_two: true, //Indicateur de champs erronés partie 2
            isValidate: false, //Indicateur de validation du formulaire
            snackbar: false, //Indicateur de snackbar

            //Données
            departure: {
                "account": "",
                "announcement_date": null,
                "birth_date": null,
                "commentary": "",
                "deletion_google_date": null,
                "delivery_pc_date": null,
                "delivery_receipt_date": null,
                "departure_id": null,
                "departure_reason_label": "",
                "departure_type_label": "",
                "email": "",
                "firstname": "",
                "fk_departure_reason_id": null,
                "fk_departure_status_id": null,
                "fk_departure_type_id": null,
                "fk_manager_id": null,
                "fk_person_id": null,
                "fk_pm_id": null,
                "glpi": false,
                "google": false,
                "lastname": "",
                "ldap": false,
                "legal_date": null,
                "manager_email": "",
                "manager_firstname": "",
                "manager_id": null,
                "manager_lastname": "",
                "manager_matricule": null,
                "manager_pole": "",
                "manager_uo": "",
                "matricule": null,
                "meeting_manager_consultant_date": null,
                "microsoft": false,
                "official_uo": "",
                "uo_label": "",
                "pc_number": null,
                "person_id": null,
                "pole": "",
                "poste_label": null,
                "real_departure_date": null,
                "reception_date": null,
                "whoz": false,
                "suspention_google_date": null,
                "ownership_transfer": null,
                "who_test": this.$store.state.email
            },
            departure_template: {},

            //Règles des champs du formulaire
            required: [
                value => !!value || 'Champs requis.'
            ],
        }
    },
    async mounted() {
        await this.getDepart(); //Récupération des données du départ sélectionné
        this.uo_list = await uoService.getUo()
        this.isRecap = this.isRecapProp;
        this.is_loading = false;
    },
    methods: {
        async getDepart() { //Récupérer le départ sélectionné
            if (this.id_selected) {
                this.departure = await departsService.getDepartFromId(this.id_selected);
                this.departure_template = JSON.parse(JSON.stringify(this.departure));

                if (this.departure.suspention_google_date === null && this.departure.real_departure_date !== null) {
                    this.departure.suspention_google_date = moment(this.departure.real_departure_date).add(1, 'days').format('YYYY-MM-DD');
                }

                if (this.departure.deletion_google_date === null && this.departure.real_departure_date !== null) {
                    this.departure.deletion_google_date = moment(this.departure.real_departure_date).add(30, 'days').format('YYYY-MM-DD');
                }
            }
        },
        closeForm(isSaving, snackbar_type) { //Fermer le formulaire
            if (window.location.pathname.split('/')[1] === "dni") {
                if(isSaving){
                    this.snackbar = true;
                }else{
                    return window.location.href = "/home"
                }
            }
            this.$emit("closeForm", { is_close: isSaving, snackbar_type: snackbar_type });
            this.isValidate = false;
        },
        validateForm() { //Valider le formulaire
            this.step_one = this.$refs.step_one.validate()
            this.step_two = this.$refs.step_two.validate()
            if (this.$refs.form_departure.validate() && this.step_one && this.step_two) {
                this.confirm_form = true;
            }
            else
                this.$refs.form_departure.$el.scrollIntoView({ behavior: 'smooth' })
        },
        async updateDepart(isSaving, isClosing) {

            if (isSaving) {
                let is_to_delete = false;
                //check if the departure is not null with this attribute "whoz , google , microsoft , glpi , ldap"
                if (this.departure.whoz !== 0 && this.departure.google !== 0 && this.departure.microsoft !== 0 && this.departure.ldap !== 0) {
                    is_to_delete = true;
                }
                await departsService.updateDepartDni(this.departure, "saving", is_to_delete);
            }
            else {
                this.isValidate = true;
                await departsService.updateDepartDni(this.departure, "validation", true);
            }
            this.confirm_form = false;
            this.closeForm(!isClosing, "depart_update");
        },
        saveForm() {
            this.updateDepart(true, false)
            this.departure_template = JSON.parse(JSON.stringify(this.departure));
        }
    },



    watch: {
        "departure.suspention_google_date": function (val) {
            if (val) {
                this.formatedDisplayDate.suspention_google_date = moment(val).format('DD/MM/YYYY');
            }
        },

        "departure.deletion_google_date": function (val) {
            if (val) {
                this.formatedDisplayDate.deletion_google_date = moment(val).format('DD/MM/YYYY');
            }
        },
    }
}
</script>

<style scoped>
.dni_form {
    background-color: white;
    height: fit-content;
    min-height: 25rem;
}

#close_form {
    transition-duration: .4s;
    transition-timing-function: cubic-bezier();
}

#close_form:hover {
    transform: scale(1.2) rotate(90deg);
}
</style>