<template>
    <!-- Navigation bar -->
    <div class="navbar">
        <v-row class="ma-0 pa-0 ml-15" align="center">
            <!-- Logo -->
            <img class="ma-0 pa-0 logo_mc2i" src="../assets/logo_mc2i.png" @click="goToHome()">

            <v-tab style="color: gainsboro;" v-show="$store.state.roles !== null">
                {{ getVueName() }}
            </v-tab>

        </v-row>

        <v-row v-if="!$vuetify.breakpoint.xs" class="ma-0 pa-0 white--text" align="center" justify="space-around">
            <v-tabs v-model="page_tab" @change="changeRole(role)" background-color="depart_blue" centered dark
                icons-and-text>
                <v-tabs-slider></v-tabs-slider>

                <v-tab>
                    Départs
                    <v-icon>mdi-package-variant</v-icon>
                </v-tab>



            </v-tabs>
        </v-row>
        <v-row cols="6" v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" class="ma-0 pa-0 mr-15 justify-end"
            align="center">
            <!-- Affichage de l'utilsateur connecté -->
            <div class="ma-0 pa-0 mr-3 navbar_profil">
                <img class="ma-0 pa-0 mx-3 dot_navbar" :src="this.$store.state.picture" />
                <div class="ma-0 pa-0 pr-5">
                    <p class="ma-0 pa-0 navbar_profil_name">{{ this.$store.state.name }}</p>
                    <p class="ma-0 pa-0 navbar_profil_email">{{ afficherRole() }}</p>
                    <!-- <p class="ma-0 pa-0 navbar_profil_email">{{rolesById[role].text}}</p> -->
                </div>
            </div>
            <div class="ma-0 pa-0 options">
                <!-- Menu déroulant -->
                <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon id="navbar_profil_menu" class="ma-0 pa-0 white--text" v-bind="attrs"
                            v-on="on">mdi-dots-horizontal</v-icon>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <!-- Choix vue  -->
                        <template v-if="$store.state.roles.includes('arianeoffboarding_admin')">
                            <v-list-item link v-for="role in [1, 2, 3, 4]" :key="role" @click="changeRole(role)">
                                <v-list-item-title style="display: flex; align-items: center;">
                                    <p class="ma-0 pa-0">
                                        Vue
                                        <span v-if="role == 1">RH</span>
                                        <span v-if="role == 2">Senior Manager</span>
                                        <span v-if="role == 3">FMI</span>
                                        <span v-if="role == 4">DNI</span>
                                    </p>
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-else>
                            <v-list-item link v-for="(role, index) in $store.state.roles" :key="index" @click="changeRole(rolesById[role].order)">
                                <v-list-item-title style="display: flex; align-items: center;">

                                    <p class="ma-0 pa-0">
                                        Vue
                                        <span>{{ rolesById[role].text }}</span>
                                    </p>
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <!-- Déconnexion -->
                        <v-list-item link>
                            <v-list-item-title v-on:click="logout">
                                Se déconnecter<v-icon class="ma-0 pa-0 ml-2 depart_pink--text">mdi-logout-variant</v-icon>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-row>
        <v-row cols="6" v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" class="ma-0 pa-0 mr-15 justify-end"
            align="center">
            <!-- Affichage de l'utilsateur connecté -->
            <div class="ma-0 pa-0">
                <!-- Menu déroulant -->
                <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <span class="ma-0 pa-0 mr-3 profil_navbar_mobile">
                            <v-icon id="account_icon_menu" class="ma-0 pa-0 white--text" v-on="on" v-bind="attrs">
                                mdi-account
                            </v-icon>
                        </span>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-card elevation="0" class="pt-2 pb-2" align="center">
                            <p class="ma-0 pa-0" style="font-size: 15px"><strong>{{ this.$store.state.first_name }}
                                    {{ this.$store.state.last_name }}</strong></p>
                            <p class="ma-0 pa-0" style="font-size: 10px; color: #dd0061;">{{ this.$store.state.email }}</p>
                        </v-card>


                        <!-- Déconnexion -->
                        <v-list-item link>
                            <v-list-item-title v-on:click="logout">
                                Se déconnecter<v-icon class="ma-0 pa-0 ml-2 depart_pink--text">mdi-logout-variant</v-icon>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    data: () => ({
        items: ['Se déconnecter'],
        page_tab: 0,
        rolesById: {
            arianeoffboarding_rh: { order: 1, text: 'RH' },
            arianeoffboarding_manager: { order: 2, text: 'Senior Manager' },
            arianeoffboarding_fmi: { order: 3, text: 'FMI' },
            arianeoffboarding_dni: { order: 4, text: "DNI" },
        }
    }),
    methods: {
        logout() { //Fonction de déconnexion
            this.$store.dispatch('signOutAction')
            this.$store.replaceState({
                first_name: null,
                last_name: null,
                email: null,
                picture: null,
                role_selected: null,
                roles: [],
                token: null,
                is_authenticated: null,
            })
            this.$router.push({ name: 'login' }).catch(() => { });
        },
        goToHome() { //Redirige vers la HomePage
            this.page_tab = 0;
            this.$router.push({ name: 'home' }).catch(() => { });
        },

        changeRole(role) {
            this.$store.dispatch('set_role_selected', role)
            this.$root.$emit('refreshHomePage')
            if (this.page_tab == 0)
                this.$router.push({ name: 'home' }).catch(() => { });
            if (this.page_tab == 1)
                this.$router.push({ name: 'stats' }).catch(() => { });

        },

        getVueName() {
            const roleSelected = this.$store.state.role_selected
            const pathname = window.location.pathname
            const redirect = localStorage.redirect

            if (pathname.includes('rh') || roleSelected == 1 || redirect?.includes('rh')) {
                return 'Vue RH'
            } else if (pathname.includes('manager') || roleSelected == 2 || redirect?.includes('manager')) {
                return 'Vue SM'
            } else if (pathname.includes('fmi') || roleSelected == 3 || redirect?.includes('fmi')) {
                return 'Vue FMI'
            } else if (pathname.includes('dni') || roleSelected == 4 || redirect?.includes('dni')) {
                return 'Vue DNI'
            }
            return ''
        },

        afficherRole() {
            const roles = this.$store.state.roles;

            if (roles.includes('ArianeOffBoarding_Admin_test')) {
                return 'Rôle Admin';
            } else if (roles.includes('ArianeOffBoarding_RH')) {
                return 'Rôle RH';
            } else if (roles.includes('ArianeOffBoarding_MANAGER')) {
                return 'Rôle Manager';
            } else if (roles.includes('ArianeOffBoarding_FMI')) {
                return 'Rôle FMI';
            } else if (roles.includes('ArianeOffBoarding_DNI')) {
                return 'Rôle DNI';
            }
        }

    }
}

</script>

<style scoped>
.navbar {
    background-color: #006a9e;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: flex-end;
}

.logo_mc2i {
    height: 5rem;
    filter: brightness(0) invert(1);
    cursor: pointer;
}

.navbar_profil {
    height: 3rem;
    background-color: #d6005e;
    width: fit-content;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navbar_profil_name {
    color: white;
    font-weight: bold;
    font-size: 15px;
}

.navbar_profil_email {
    font-size: 12px;
    color: #ffc6df;
}

.dot_navbar {
    background-color: white;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.options {
    height: 3rem;
    background-color: #d6005e;
    width: 2rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center
}

.profil_navbar_mobile {
    background-color: #dd0061;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar_profil_menu {
    transition-duration: .4s;
    transition-timing-function: cubic-bezier();
}

#navbar_profil_menu:hover {
    transform: scale(1.2) rotate(90deg);
}
</style>
