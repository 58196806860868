import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../components/HomePage'
import LoginPage from '../components/LoginPage'
import RhForm from '../components/RhForm'
import ManagerForm from '../components/ManagerForm'
import FmiForm from '../components/FmiForm'
import DniForm from '../components/DniForm'
import SeeConsolideForm from '../components/SeeConsolideForm'


import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/rh/:id_selected',
    name: 'rh_form',
    props: (route) => ({
      id_selected: route.params.id_selected,
      isEditor: true,
    }),
    component: RhForm,
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    }
  },
  {
    path: '/manager/:id_selected',
    name: 'manager_form',
    props: (route) => ({
      id_selected: route.params.id_selected,
      isRedirect: true,
    }),
    component: ManagerForm,
    meta: {
      requiresAuth: true,
      allowedRoles: [2]
    }
  },
  {
    path: '/fmi/:id_selected',
    name: 'fmi_form',
    props: true,
    component: FmiForm,
    meta: {
      requiresAuth: true,
      allowedRoles: [3]
    }
  },
  {
    path: '/dni/:id_selected',
    name: 'dni_form',
    props: true,
    component: DniForm,
    meta: {
      requiresAuth: true,
      allowedRoles: [4]
    }
  },
  {
    path: '/consolide/:id_selected',
    name: 'consolide_form',
    props: true,
    component: SeeConsolideForm,
    meta: {
      requiresAuth: true
    }
  },

]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Vérifier si l'utilisateur est authentifié


  if(to.path == '/login' && to.query.code){
    store.dispatch('getCognitoInfo', to.query.code)
  }else if (to.path === '/login' && to.query.error){
    store.dispatch('set_authenticated', false)
    next({ path: '/login' });
  } 

  if (!store.state.is_authenticated && to.meta.requiresAuth) {
    next({ path: '/login' }); // rediriger vers la page de connexion
  } else {
    const allowedRoles = to.meta.allowedRoles
    const userRole = store.state.roles

    if (store.state.roles.includes('ArianeOffBoarding_Admin_test')) {
      next(); // autoriser l'accès à toutes les vues
    } else if (userRole.includes('ArianeOffBoarding_RH') && to.path.startsWith('/rh/')) {
      next();
    } else if (userRole.includes('ArianeOffBoarding_MANAGER') && to.path.startsWith('/manager/')) {
      next();
    } else if (userRole.includes('ArianeOffBoarding_FMI') && to.path.startsWith('/fmi/')) {
      next();
    } else if (userRole.includes('ArianeOffBoarding_DNI') && to.path.startsWith('/dni/')) {
      next();
    } else if (!allowedRoles || allowedRoles.some(role => userRole.includes(role))) {
      next();
    } else {
      next({ path: '/home' }); // rediriger vers la page d'accueil de l'utilisateur
    }
  }
})

export default router
