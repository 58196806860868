import crypto from "crypto-js";

function generateCodeVerifier() {
  const codeVerifier = crypto.lib.WordArray.random(32); // 256 bits (32 bytes)
  return codeVerifier
    .toString(crypto.enc.Base64)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

// Calculer le code de défi (code challenge) à partir du code vérifieur
function calculateCodeChallenge(codeVerifier) {
  const codeChallenge = crypto
    .SHA256(codeVerifier)
    .toString(crypto.enc.Base64)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return codeChallenge;
}

export default function generatePKCETokens() {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = calculateCodeChallenge(codeVerifier);

  return {
    code_challenge: codeChallenge,
    code_verifier: codeVerifier,
  };
}
