<template>
    <div>
        <p class="text">@{{ (new Date()).getFullYear() }} - mc2i - Developed by DNI v{{ vesrion }}</p>
    </div>
</template>

<script>
import pkg from '../../package.json'




export default {
    name: 'FooterComponent',
    data: () => ({
        vesrion: pkg.version
    }),
}

</script>

<style scoped>
    .text{
        color: #B7B7B7;
        font-weight: 500;
        width: fit-content;
        margin: 1rem auto;
    }
</style>