import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"

export const baseUrl = process.env.VUE_APP_URL_API;

export default class TypesService {

    getUo() {
      return new Promise( (resolve, reject) => {
          apiClient.get(`${baseUrl}/uo`,
          {headers: {'Authorization': 'Bearer ' + store.state.token}})
          .then(function(response){
            resolve(response.data);
          })
          .catch(function(error){
            reject(error);
          });
      })
    }



    getPole() {
      return new Promise( (resolve, reject) => {
          apiClient.get(`${baseUrl}/pole`,
          {headers: {'Authorization': 'Bearer ' + store.state.token}})
          .then(function(response){
            resolve(response.data);
          })
          .catch(function(error){
            reject(error);
          });
      })
    }

    getSchool() {
      return new Promise( (resolve, reject) => {
          apiClient.get(`${baseUrl}/school`,
          {headers: {'Authorization': 'Bearer ' + store.state.token}})
          .then(function(response){
            resolve(response.data);
          })
          .catch(function(error){
            reject(error);
          });
      })
    }



    getNatureBonus(){
      return new Promise( (resolve, reject) => {
          apiClient.get(`${baseUrl}/nature`,
          {headers: {'Authorization': 'Bearer ' + store.state.token}})
          .then(function(response){
            resolve(response.data);
          })
          .catch(function(error){
            reject(error);
          });
      })
    }

}