import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"

export const baseUrl = process.env.VUE_APP_URL_API+"/collaborateurs";

export default class CollaborateursService {

    getCollab(searchCollab = null) {
      let query = searchCollab ? `search_collab=${searchCollab}` : '';
      return new Promise( (resolve, reject) => {
          apiClient.get(`${baseUrl}?${query}`,
          {headers: {'Authorization': 'Bearer ' + store.state.token}})
          .then(function(response){
            resolve(response.data);
          })
          .catch(function(error){
            reject(error);
          });
      })
    }

    getCollabFromIdSX(id_sx) {
        return new Promise( (resolve, reject) => {
            apiClient.get(`${baseUrl}/${id_sx}`,
            {headers: {'Authorization': 'Bearer ' + store.state.token}})
            .then(function(response){
              resolve(response.data);
            })
            .catch(function(error){
              reject(error);
            });
        })
      }
}