<!-- Page de Login -->
<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
            <!-- Partie Gauche de Page de Login -->
            <v-col align="center" justify="center" cols="6" v-if="!$vuetify.breakpoint.xs" class="login_left">
                <div style="position: absolute; width: 100%; z-index:0;">
                    <div class="circles">
                        <div class="circle1"></div>
                    </div>
                    <div class="circles">
                        <div class="circle2"></div>
                    </div>
                    <div class="circles">
                        <div class="circle3"></div>
                    </div>
                    <div class="circles">
                        <div class="circle4"></div>
                    </div>
                </div>
                <div class="description_text">
                    <h2>Ariane Offboarding</h2>
                    <p>L'outil de gestion des départs des collaborateurs</p>
                </div>
            </v-col>

            <!-- Partie Droite de Page de Login -->
            <v-col class="ma-0 pa-0 white login_right" :style="$vuetify.breakpoint.xs ? 'height: 100vh' : ''">
                <v-col class="ma-0 pa-0">
                    <div style="text-align: center; margin-bottom: 10vh;">
                        <img id="logo" src="../assets/logo_mc2i.png">
                        <p style="color: #B7B7B7; font-weight: 500;">Bienvenue !</p>
                    </div>

                    <div style="width: fit-content; max-width: 80%; margin: auto auto;">
                        <h1 style="margin-bottom: 0.5rem; font-size: 1.5rem;">Connexion</h1>
                        <button class="btn_connect" v-on:click="login">
                            <img style="margin: auto 0.5rem; width: 1.5rem;" id="google" src="../assets/google.png">
                            <div class="text-h6"><strong>Connectez-vous avec Google</strong></div>
                            <v-icon id="connect_arrow" color="black" name="arrow-forward-circle-outline"
                                style="margin: auto 0.5rem;">mdi-arrow-right</v-icon>
                        </button>
                    </div>

                    <div style="margin-top: 15vh;">
                        <p style="color: #B7B7B7; font-weight: 500; width: fit-content; margin: auto auto;">@{{ (new Date()).getFullYear() }} - mc2i
                            - Developed by DNI</p>
                    </div>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'LoginPage',
    created(){
        if(this.$store.state.is_authenticated !== null && !this.$store.state.is_authenticated){
            alert("Vous n'avez pas les droits pour accéder à cette application.")
            this.$store.dispatch('set_authenticated', null)
        }
    },  
    methods: {
        async login() {
            this.$store.dispatch('googleSignInAction')
        },
        logout(){ //Fonction de déconnexion
            this.$store.dispatch('signOutAction')
            this.$store.replaceState({
                first_name: null,
                last_name: null,
                email: null,
                picture: null,
                role_selected: null,
                roles: [],
                token: null,
                is_authenticated: null,
            })
            this.$router.push({ name: 'login' }).catch(()=>{});
        },
    },
}
</script>

<style scoped>
.login_left {
    position: relative;
    width: 50%;
    height: 100vh;
    color: white;
    background-color: #006a9e;
    overflow: hidden;
}

.login_right {
    z-index: 1;
    box-shadow: -8px 4px 39px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    background-color: white;
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 0.5rem;
    border-radius: 50%;

}

.particle_text {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: 3.25rem;
}

.particle_text h3 {
    font-size: 1rem;
}

.description_text {
    position: relative;
    background: rgba(0, 74, 109, 0.5);
    border-radius: 20px;
    width: fit-content;
    margin: auto auto;
    margin-top: 4rem;
    z-index: 1;
    backdrop-filter: blur(3px);
}

.description_text h2 {
    padding: 0.7rem 1.3rem 0 1.3rem;
    font-size: 1.3rem;
    font-weight: 700;
}

.description_text p {
    padding: 0rem 1.3rem 0.7rem 1.3rem;
    color: #C3E1FF;
    font-size: 0.7rem;
    font-weight: 500;
}

#app_preview {
    overflow: hidden;
    margin-left: 11.5rem;
    margin-top: 3rem;
    filter: drop-shadow(-10px 0px 15px rgba(0, 0, 0, 0.2));
}

.connection_menu {
    background-color: aqua;
    width: fit-content;
    margin: auto auto;
    justify-content: center;
    align-content: center;
    align-items: center;
}

#logo {
    width: 15rem;
    height: auto;
    margin-bottom: 1rem;
}

.btn_connect {
    border: 2px solid #006a9e;
    box-sizing: border-box;
    border-radius: 26px;
    padding: 1rem 2rem;
    display: flex;
    transition-property: transform;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier();
}

#connect_arrow {
    transition-property: transform;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier();
}

.btn_connect:hover #connect_arrow {
    transform: translateX(10px);
}

.btn_connect:hover {
    transform: translateY(-3px);
    background-color: #f4f4f4;
}

.circles {
    position: absolute;
    width: 100%;
    background-color: transparent;
}

.circle1 {
    width: 800px;
    height: 800px;
    border: 15px solid #004a6d;
    border-radius: 50%;
    box-sizing: border-box;
    margin: -45% 0 0 80%;
}

.circle2 {
    width: 1000px;
    height: 1000px;
    border: 15px solid #005b82;
    border-radius: 50%;
    box-sizing: border-box;
    margin: -60% 0 0 67%;
}

.circle3 {
    width: 1200px;
    height: 1200px;
    border: 15px solid #004a6d;
    border-radius: 50%;
    box-sizing: border-box;
    margin: -75% 0 0 54%;
}

.circle4 {
    width: 1400px;
    height: 1400px;
    border: 15px solid #005b82;
    border-radius: 50%;
    box-sizing: border-box;
    margin: -90% 0 0 41%;
}
</style>
