<template>
    <v-card class="ma-0 pt-7 px-10" :width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '100%' : '25rem'">
        <!-- Titre -->
        <v-row>
            <h1 class="ma-0 pa-0 mb-5" style="font-size: 1.5rem;">Exporter</h1>
        </v-row>

        <!-- Type de fichier -->
        <v-row align="center">
            <v-col col="6">
                <h4 class="ma-0 pa-0 mb-5">Type de fichier</h4>
            </v-col>
            <v-col cols="6">
                <v-select v-model="file_selected" :items="file_types" filled dense></v-select>
            </v-col>
        </v-row>

        <!-- Type de départs -->
        <v-row align="center" class="mb-5">
            <v-col col="6">
                <h4 class="ma-0 pa-0 mb-5">Type de départs</h4>
            </v-col>
            <v-col cols="6">
                <v-select @change="exportDepartures" v-model="departures_type_selected" :items="departures_types" filled
                    dense></v-select>
            </v-col>
        </v-row>

        <vue-json-to-csv v-if="file_selected == 'CSV'" :json-data="toExport" :csv-title="`gestionDeparts_export_${departures_type_selected}_${new Date().toLocaleDateString('fr')}`">
            <!-- Btn apply -->
            <v-col class="ma-0 pa-0" align="center">
                <v-btn class="btn-apply ma-0 pa-0 mb-5 depart_blue white--text" elevation="0" width="75%"
                    height="2.5rem">Exporter</v-btn>
            </v-col>
        </vue-json-to-csv>

        <export-excel v-if="file_selected == 'Excel'" :data="toExport"
            :name="`gestionDeparts_export_${departures_type_selected}_${new Date().toLocaleDateString('fr')}`">
            <!-- Btn apply -->
            <v-col class="ma-0 pa-0" align="center">
                <v-btn class="btn-apply ma-0 pa-0 mb-5 depart_blue white--text" elevation="0" width="75%"
                    height="2.5rem">Exporter</v-btn>
            </v-col>
        </export-excel>
    </v-card>
</template>

<script>
export default {
    name: 'FiltersComponent',
    props: ['tab_selected', 'toExport' , 'isOpen'],

    data: () => ({
        //Choix
        file_types: ['CSV', 'Excel'],
        departures_types: [{
            text: "En cours",
            value: "en_cours"
        },
        {
            text: "Finalisés",
            value: "finalises"
        }
        ],

        //Sélections
        file_selected: 'CSV',
        export_view_selected: 'Globale',
        departures_type_selected: 'en_cours',

    }),
    methods: {
        exportDepartures() {
            this.$emit("exportDepartures", {
                departures_type_selected: this.departures_type_selected,
            })
        }
    },

    watch: {
        isOpen(value){
            if(value){
                this.departures_type_selected = 'en_cours'
                this.file_selected = 'CSV'
            }
        }
    }
}
</script>

<style scoped>
.btn-apply {
    text-transform: none !important;
}

h1 {
    font-size: 24px;
}

p {
    font-size: 12px;
}

#checkboxLabel {
    font-size: 12px;
    color: black;
}

@media screen and (max-width: 1450px) {
    h1 {
        font-size: 16px;
    }

    h2 {
        font-size: 12px;
    }

    .v-btn label {
        font-size: 14px;
    }

    p {
        font-size: 12px;
    }
}
</style>