import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"


const baseUrl = process.env.VUE_APP_URL_API + "/nature";


export default class NatureBonusService {


    createNatureBonus(departure_id , nature) {
        return new Promise((resolve, reject) => {
        apiClient.post(`${baseUrl}/${departure_id}`,
            {
                id_nature_bonus: nature.id,
                amount: nature.amount,
            },
            { headers: { 'Authorization': 'Bearer ' + store.state.token } })
            .then(function (response) {
            resolve(response.data)
            })
            .catch(function (error) {
            reject(error)
            });
        })
    }

    getNatureBonusWithID(departure_id) {
        return new Promise((resolve, reject) => {
        apiClient.get(`${baseUrl}/${departure_id}`,
            { headers: { 'Authorization': 'Bearer ' + store.state.token } })
            .then(function (response) {
            resolve(response.data)
            })
            .catch(function (error) {
            reject(error)
            });
        })
    }


    updateNatureBonusWithID(departure_id , natures) {
        return new Promise((resolve, reject) => {
        apiClient.patch(`${baseUrl}/${departure_id}`,
            {
                amount1: natures.nature_bonus_1.amount,
                amount2: natures.nature_bonus_2.amount,
                amount3: natures.nature_bonus_3.amount,
            },
            { headers: { 'Authorization': 'Bearer ' + store.state.token } })
            .then(function (response) {
            resolve(response.data)
            })
            .catch(function (error) {
            reject(error)
            });
        })


    }
    
    deleteNatureBonusWithID(departure_id , id_nature_bonus) {
        return new Promise((resolve, reject) => {
        apiClient.delete(`${baseUrl}/${departure_id}?id_nature_bonus=${id_nature_bonus}`,
            { headers: { 'Authorization': 'Bearer ' + store.state.token } })
            .then(function (response) {
            resolve(response.data)
            })
            .catch(function (error) {
            reject(error)
            });
        })
    }

}

