import axios from 'axios'
import store from '../store/index'
import router from '../router';


const instance = axios.create()


instance.interceptors.response.use(
    response => response,
    async error => {
        if (error.response.status === 401) {
            const refresh_token = store.state.refresh_token
            const config = error.config;
            if (refresh_token) {
                try {
                    const resp = await axios.post(`https://${process.env.VUE_APP_DOMAIN}/oauth2/token`, {
                        grant_type: "refresh_token",
                        client_id: process.env.VUE_APP_CLIENT_ID,
                        refresh_token: refresh_token,
                    },
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        })

                    store.commit('set_token', resp.data.access_token)
                    config.headers.Authorization = `Bearer ${resp.data.access_token}`;
                    return instance.request(error.config)
                } catch {
                    alert("Votre session a expiré. Veuillez vous reconnecter.")
                    store.dispatch('logout')
                    router.push({ name: 'login' })
                }
            } else if (error.response.status === 400) {
                alert('Votre session a expiré. Veuillez vous reconnecter.')
                store.dispatch('logout')
                router.push({ name: 'login' })
            }
        }
        return Promise.reject(error)
    }
)



export default instance