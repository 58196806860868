import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"

export const baseUrl = process.env.VUE_APP_URL_API + "/managers";

export default class ManagersService {

  createManager(manager) {
    return new Promise((resolve, reject) => {
      apiClient.post(`${baseUrl}`,
        {
          matricule: manager.matricule, firstname: manager.firstName, lastname: manager.lastName,
          pole: manager.pole, uo: manager.uo, email: manager.email
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        });
    })
  }
}