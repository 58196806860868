import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
        theme: {
            themes:{
                light:{
                    depart_blue: '#006a9e',
                    depart_pink: "#dd0061",
                    depart_grey: '#F7F7F7',
                    depart_saisir: '#ff9000',
                    depart_saisir_medium: '#ffeec6',
                    depart_saisir_background: '#FFF9E2',
                    depart_attente: '#0053FF',
                    depart_attente_medium: '#cce0ff',
                    depart_attente_background: '#E3F1FF',
                    depart_finalise: '#10bc16',
                    depart_finalise_medium: '#baf2ba',
                    depart_finalise_background: '#D8F8D9',
                    depart_annule: '#ff0000',
                    depart_annule_medium: '#ff9b9b',
                    depart_annule_background: '#ffafaf',
                    depart_edit: '#8c00ff'
                }
            }    
        },
        lang: {
            locales: { fr },
            current: 'fr',
        },
});
