import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import axios from 'axios'

import jwtDecode from "jwt-decode";
import generatePKCETokens from "../plugins/pkce";
import router from '../router';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: '',
    email: '',
    picture: '',
    roles: [],
    role_selected: null,
    token: null,
    refresh_token: null,
    is_authenticated: null,
    pole: [],
    uo: [],
    redirectUrl: '',
  },
  getters: {
    uo(state) {
      return state.uo
    },
    pole(state) {
      return state.pole
    },
    all(state) {
      return state
    }
  },
  mutations: {
    logout(state) {
      state.name = null
      state.email = null
      state.picture = null
      state.roles = []
      state.role_selected = null
      state.token = null
      state.refresh_token = null
      state.is_authenticated = null
      localStorage.clear();
      sessionStorage.clear();
    },
    set_authenticated(state, value) {
      state.is_authenticated = value
    },
    set_token(state, token) {
      state.token = token
    },
    set_refresh_token(state, refresh_token) {
      state.refresh_token = refresh_token
    },
    set_user(state, user) {
      state.is_authenticated = true;
      state.name = user.name;
      state.email = user.email;
      state.picture = user.picture;
    },
    SET_REDIRECT_URL(state, url) {
      state.redirectUrl = url

    },
    set_roles(state, roles) {

      state.roles = roles;

      if (!state.roles) {
        state.role_selected = ''
      }
      else if (state.roles.includes('arianeoffboarding_rh')) {
        state.role_selected = [1]
      }
      else if (state.roles.includes('arianeoffboarding_manager')) {
        state.role_selected = [2]
      }
      else if (state.roles.includes('arianeoffboarding_fmi')) {
        state.role_selected = [3]
      }
      else if (state.roles.includes('arianeoffboarding_dni')) [
        state.role_selected = [4]
      ]
      else if (state.roles.includes('arianeoffboarding_admin')) {
        state.role_selected = [4]
      }
      else {
        state.role_selected = [0]
      }
    },
    set_role_selected(state, role) {
      state.role_selected = role;
    },

    set_pole(state, pole) {
      state.pole = pole;
    },

    set_uo(state, uo) {
      state.uo = uo;
    }

  },
  actions: {
    set_token({ commit }, token) {
      commit('set_token', token)
      //localStorage.setItem('auth_token', token)
    },
    set_refresh_token({ commit }, refresh_token) {
      commit('set_refresh_token', refresh_token)
    },
    set_user({ commit }, user) {
      commit('set_user', user)
    },
    logout({ commit }) {
      commit('logout')
    },
    set_roles({ commit }, roles) {
      commit('set_roles', roles)
    },
    set_role_selected({ commit }, role) {
      commit('set_role_selected', role)
    },

    set_authenticated({ commit }, value) {
      commit('set_authenticated', value)
    },

    async getCognitoInfo({ commit, dispatch, state }, code) {
      const resp = await axios.post(
        `https://${process.env.VUE_APP_DOMAIN}/oauth2/token`,
        {
          "grant_type": "authorization_code",
          "client_id": process.env.VUE_APP_CLIENT_ID,
          "code_verifier": localStorage.getItem("code_verifier"),
          "code": code,
          "redirect_uri": `${window.location.origin}/login`,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );


      const decoded = jwtDecode(resp.data.id_token);
      const { name, email, picture } = decoded;
      const roles = decoded['cognito:groups'];
      const token = resp.data.access_token;

      commit('set_token', token)
      commit('set_refresh_token', resp.data.refresh_token)
      commit('set_user', { name, email, picture })
      commit('set_roles', roles)

      if (roles.length > 0) {
        router.push('/home')
      } else {
        router.push('/login')
      }

    },


    googleSignInAction({ commit, dispatch, state }) {
      // console.log('GOOGLE SIGN IN ACTION')
      const { code_verifier, code_challenge } = generatePKCETokens();
      const redirectUri = `${window.location.origin}/login`;
      const url = `https://${process.env.VUE_APP_DOMAIN}/authorize?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&scope=openid&redirect_uri=${redirectUri}&code_challenge_method=S256&code_challenge=${code_challenge}`;


      localStorage.setItem('code_verifier', code_verifier);
      window.location.href = url;
    },

    async signOutAction({ commit, state }) {
      await axios.post(`https://${process.env.VUE_APP_DOMAIN}/oauth2/revoke`, {
        client_id: process.env.VUE_APP_CLIENT_ID,
        token: state.refresh_token,
      },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
      commit('logout')
    },

  },
  modules: {
  },

})
