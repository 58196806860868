<template>
    <!-- Formulaire de départ -->
    <div class="ma-0 pa-0 rh_form">
        <!-- Header -->
        <v-container v-if="(!isEditor && !isConsolide) || is_rh_path" fluid align="center" justify="center"
            class="ma-0 pa-0 pt-3 pb-3 white--text d-flex justify-space-between depart_pink">
            <h1 v-if="!isRecap" class="ml-7" style="width: fit-content;">Formulaire de départ</h1>
            <h1 v-else class="ml-5" style="width: fit-content;">Récapitulatif du départ</h1>
            <v-col cols="3" class="ma-0 pa-0 d-flex justify-end">
                <v-icon id="close_form" large color="white" class="mr-7"
                    v-on:click="closeForm">mdi-close-circle-outline</v-icon>
            </v-col>
        </v-container>

        <div v-if="is_loading">
            <v-progress-linear indeterminate color="depart_pink" height="10px"></v-progress-linear>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
        </div>

        <!-- Formulaire -->
        <v-form v-else ref="form_departure" class="ma-5" lazy-validation>
            <v-stepper non-linear class="ma-0 pa-0" elevation="0" v-model="form_step">
                <!-- STEPPER HEADER -->
                <v-stepper-header class="elevation-0">

                    <v-stepper-step v-if="!isEditor && !isRecapProp && !departure_to_generate" editable step="1"
                        color="depart_blue">
                        <small>Selection collaborateur</small>
                    </v-stepper-step>

                    <v-divider v-if="!isEditor && !isRecapProp && !departure_to_generate"></v-divider>

                    <v-stepper-step
                        :editable="isEditor || isRecapProp || collab_selected !== null || departure_to_generate !== null"
                        :step="isEditor || isRecapProp || departure_to_generate ? 1 : 2" color="depart_blue"
                        :rules="[value => step_one]">
                        <small>{{ isEditor || isRecapProp ? "Collaborateur" : "Récapitulatif collaborateur" }}</small>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :editable="isEditor || isRecapProp || collab_selected !== null || departure_to_generate !== null"
                        :step="isEditor || isRecapProp || departure_to_generate ? 2 : 3" color="depart_blue"
                        :rules="[value => step_two]">
                        <small>Senior manager</small>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :editable="isEditor || isRecapProp || collab_selected !== null || departure_to_generate !== null"
                        :step="isEditor || isRecapProp || departure_to_generate ? 3 : 4" color="depart_blue"
                        :rules="[value => step_three]">
                        <small>Départ</small>
                    </v-stepper-step>
                </v-stepper-header>

                <!-- STEPPER ITEMS -->
                <v-stepper-items>
                    <v-stepper-content v-if="!isEditor && !isRecapProp && !departure_to_generate" step="1">
                        <v-autocomplete class="mt-5" v-model="collab_selected"
                            no-data-text="Veuillez saisir les premières lettres du nom du collaborateur"
                            label="Selectionner le collaborateur" :search-input.sync="searchCollab"
                            :error-messages="error_collab" :items="items_consultant" outlined clearable></v-autocomplete>


                        <v-row class="ma-0 pa-0 mt-10">
                            <!-- Bouton continuer -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    :disabled="collab_selected === null || (!isForced && error_collab.length > 0)"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="form_step++"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Continuer
                                </v-btn>
                            </div>
                            <v-checkbox v-if="error_collab.length > 0" class="ma-3 pa-0" v-model="isForced"
                                :label="`Forcer la création`"></v-checkbox>
                        </v-row>
                    </v-stepper-content>

                    <!-- NUMERO 1 -->
                    <v-stepper-content :step="isEditor || isRecapProp || departure_to_generate ? 1 : 2" class="ma-0 pa-0">
                        <!-- Informations du consultant -->
                        <v-form ref="step_one">
                            <v-row class="mt-4 pa-3" v-if="isEditor || isRecapProp || departure_to_generate !== null">
                                <v-col>
                                    <v-row>
                                        <h2 style="color: #B4B4B4;">{{ departure.matricule }}&nbsp;</h2>
                                        <h2>{{ departure.firstname }} {{ departure.lastname }}</h2>
                                    </v-row>
                                    <v-row class="mt-0">
                                        <em class="subtitle-2">{{
                                            departure.poste_label
                                        }}</em>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row class="justify-end">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip v-on="on" v-bind="attrs">
                                                    <v-icon>mdi-calendar-month</v-icon>&nbsp;
                                                    {{ changeFormatDate(departure.entry_date) }}
                                                </v-chip>
                                            </template>
                                            <span>Date d'entrée</span>
                                        </v-tooltip>

                                    </v-row>
                                </v-col>
                            </v-row>

                            <!-- Peut être simplifier !!!  -->
                            <template v-else-if="collab_selected !== null">


                                <v-row class="mt-4 pa-3">
                                    <v-col>
                                        <v-row>
                                            <h2 style="color: #B4B4B4;">{{ collab_selected.matricule }}&nbsp;</h2>
                                            <h2>{{ collab_selected.firstName }} {{ collab_selected.lastName }}</h2>
                                        </v-row>
                                        <v-row class="mt-0">
                                            <em class="subtitle-2">{{
                                                collab_selected.job
                                            }}</em>
                                        </v-row>
                                    </v-col>

                                    <v-col>
                                        <v-row class="justify-end">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip v-on="on" v-bind="attrs">
                                                        <v-icon>mdi-calendar-month</v-icon>&nbsp;
                                                        {{ changeFormatDate(collab_selected.entryDate) }}
                                                    </v-chip>
                                                </template>
                                                <span>Date d'entrée</span>
                                            </v-tooltip>

                                        </v-row>
                                    </v-col>
                                </v-row>
                            </template>

                            <v-row class="mt-5">
                                <!-- Pôle -->
                                <v-col cols="12" sm="6">
                                    <v-select v-if="isEditor || isRecapProp || departure_to_generate !== null" outlined
                                        v-model="departure.id_pole" :items="poles_list" label="Pôle*"
                                        :readonly="isRecapProp || isConsolide"
                                        :clearable="!isRecapProp" type="text"
                                        :rules="required"></v-select>
                                    <v-select v-else-if="collab_selected" outlined v-model="collab_selected.id_pole" :items="poles_list"
                                        label="Pôle" type="text" :rules="required"></v-select>
                                </v-col>
                                <!-- UO -->
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-if="isEditor || isRecapProp || departure_to_generate || departure_to_generate !== null"
                                        outlined v-model="departure.id_uo" :items="uo_list" label="UO*"
                                        :readonly="isRecapProp || isConsolide || departure_to_generate !== null"
                                        
                                        :clearable="!isRecapProp "
                                        :rules="required"></v-select>
                                    <v-select v-else-if="collab_selected" v-model="collab_selected.id_uo" outlined :items="uo_list" label="UO*"
                                        :rules="required"></v-select>
                                </v-col>
                            </v-row>

                            <v-row class="ma-0 pa-0">
                                <!-- École -->
                                <v-autocomplete
                                    v-if="isEditor || isRecapProp || departure_to_generate || departure_to_generate !== null"
                                    outlined :items="list_school" v-model="departure.id_school" label="École"
                                    :readonly="isRecapProp || isConsolide "
                                    :clearable="!isRecapProp "
                                     type="text"></v-autocomplete>


                                <v-autocomplete v-else-if="collab_selected" :items="list_school" outlined v-model="collab_selected.id_school"
                                    label="École" type="text"></v-autocomplete>

                            </v-row>

                        </v-form>

                        <v-row class="ma-0 pa-0 mt-10 justify-end">
                            <!-- Bouton continuer -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="form_step++"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Continuer
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>

                    <!-- NUMERO 2 -->
                    <v-stepper-content :step="isEditor || isRecapProp || departure_to_generate ? 2 : 3" class="ma-0 pa-0">

                        <v-autocomplete class="mt-5" v-model="manager_selected"
                            no-data-text="Veuillez saisir les premières lettres du nom du senior manager"
                            label="Senior manager" :search-input.sync="searchManager" :items="items_manager"
                            :readonly="isRecapProp " outlined
                            :clearable="!isRecapProp "
                            ></v-autocomplete>

                        <v-row class="ma-0 pa-0 mt-5 justify-end">
                            <!-- Bouton "retour" -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn color="depart_pink" class="depart_pink--text font-weight-bold mb-10 mr-10"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step--" outlined
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px; border: thin solid red;">
                                    Retour
                                </v-btn>
                            </div>

                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="form_step++"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Continuer
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>

                    <!-- NUMERO 3 -->
                    <v-stepper-content :step="isEditor || isRecapProp || departure_to_generate ? 3 : 4" class="ma-0 pa-0">
                        <!-- Traitement du départ -->
                        <v-form ref="step_three" v-if="departure.fk_departure_status_id != 2 || isEditor" class="mb-15">
                            <h2>Traitement du départ</h2>
                            <v-row class="mt-5">
                                <!-- Type de départ -->
                                <v-col cols="12" sm="12">
                                    <v-select v-model="departure.fk_departure_type_id" outlined :items="departure_types"
                                        item-value="departure_type_id" item-text="departure_type_label"
                                        label="Type de départ*"
                                        :readonly="isRecapProp || isConsolide || [2,3].includes(departure.fk_departure_type_id)"
                                        :disabled="[2,3].includes(departure.fk_departure_type_id)"
                                         :rules="required"
                                        @change="checkTypeDeparture"></v-select>
                                </v-col>


                                <!-- Date de réception du courrier -->
                                <v-col v-if="isReciveMail" cols="12" sm="6">
                                    <v-menu v-model="reception_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field placeholder="jj/mm/yyyy" outlined
                                                v-model="formatedDisplayDate.reception_date"
                                                label="Date de réception du courrier*" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" :rules="required"
                                                readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp || isConsolide"
                                            v-model="departure.reception_date" :max="maxDay()"
                                            v-on:change="calcDate()"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col v-if="isInformationDate" cols="12" sm="6">
                                    <v-menu v-model="information_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field placeholder="jj/mm/yyyy" outlined
                                                v-model="formatedDisplayDate.date_information_consultant"
                                                label="Date d'information du collaborateur*" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" :rules="required"
                                                readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp || isConsolide"
                                            v-model="departure.date_information_consultant"
                                            v-on:change="calcDate()"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col v-if="isRupture" cols="12" sm="6">
                                    <v-menu v-model="convention_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field placeholder="jj/mm/yyyy" outlined
                                                v-model="formatedDisplayDate.sign_date_convention"
                                                label="Date de signature de la convention*" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" :rules="required"
                                                readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp || isConsolide"
                                            v-model="departure.sign_date_convention"
                                            v-on:change="calcDate()"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <!-- Date de départ légale-->
                                <v-col v-if="isInformationDate" cols="12" sm="6">
                                    <v-menu v-model="prevenance_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field placeholder="jj/mm/yyyy" outlined
                                                v-model="formatedDisplayDate.end_delay_prevenance"
                                                label="Date de fin du délai de prévenance*" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" :rules="required"
                                                readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp || isConsolide"
                                            v-model="departure.end_delay_prevenance"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col v-if="isOutDate && !isInformationDate" cols="12" sm="6"
                                    :class="$vuetify.breakpoint.xs ? 'mt-5' : ''">
                                    <v-menu v-model="legal_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto" class="ma-0 pa-0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.legal_date" color="depart_pink"
                                                outlined background-color="depart_disabled_grey" append-icon="mdi-calendar"
                                                label="Date de départ légale*" class="ma-0 pa-0" v-bind="attrs" v-on="on"
                                                :rules="required" readonly>
                                                <v-tooltip v-if="isDemission" class="ma-0 pa-0" slot="append" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon class="ma-0 pa-0">
                                                            mdi-calendar
                                                        </v-icon>
                                                        <v-icon v-on="on" class="ma-0 pa-0" color="depart_pink">
                                                            mdi-alert-circle-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>La date de départ légale correspond à la date de réception + 3
                                                        mois<br>
                                                        <span v-if="isEditor">Attention, ce champ ne modifie pas la date de départ réelle. Si vous souhaitez modifier la date de départ réelle, merci de passer par la section "Date de départ réelle"</span>
                                                    </span>
                                                </v-tooltip>
                                                <v-tooltip v-else-if="isEditor" class="ma-0 pa-0" slot="append" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon class="ma-0 pa-0">
                                                            mdi-calendar
                                                        </v-icon>
                                                        <v-icon v-on="on" class="ma-0 pa-0" color="depart_pink">
                                                            mdi-alert-circle-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Attention, ce champ ne modifie pas la date de départ réelle. Si vous souhaitez modifier la date de départ réelle, merci de passer par la section "Date de départ réelle"</span>
                                                </v-tooltip>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="calcDate(false)" color="depart_blue" scrollable
                                            :readonly="isRecapProp || isConsolide"
                                            v-model="departure.legal_date"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <!-- Date envoi accusé réception courrier -->
                                <v-col v-if="isReciveMail && departure.fk_departure_type_id !== 9" cols="12" sm="6"
                                    :class="$vuetify.breakpoint.xs ? 'mt-5' : ''">
                                    <v-menu v-model="receipt_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.delivery_receipt_date" outlined
                                                label="Date d'envoi de l'accusé de réception" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.delivery_receipt_date"></v-date-picker>
                                    </v-menu>
                                </v-col>


                                <!-- Préavis (priornotice) -->

                                <v-col v-if="isDemission" cols="12">
                                    <div style="display: flex;">
                                        <v-switch label="Le collaborateur demande t'il une réduction de
                                                        préavis ?" v-model="departure.is_priornotice_reduction"
                                            v-on:change="changePriornotice(0)" class="ma-0 pa-0 ms-3" color="depart_pink"
                                            :readonly="isRecapProp" inset></v-switch>
                                    </div>
                                </v-col>


                                <v-col
                                    :cols="departure.is_priornotice_reduction && isDemission && departure.is_wanted_early_departure && isRecapProp ? 3 : 10"
                                    v-if="departure.is_priornotice_reduction && isDemission">
                                    <v-radio-group :rules="requiredCheck" :readonly="isRecapProp" class="ma-0 pa-0 ms-3"
                                        v-model="departure.is_wanted_early_departure">
                                        <v-radio label="Au plus tôt" :value="1"></v-radio>
                                        <v-radio label="À une date précise" :value="0"></v-radio>
                                    </v-radio-group>
                                </v-col>

                                <v-col cols="6"
                                    v-if="departure.is_priornotice_reduction && isDemission && departure.is_wanted_early_departure && isRecapProp">
                                    <v-chip>{{ departure.real_departure_date ? `Date saisie par le senior manager: ${new
                                        Date(departure.real_departure_date).toLocaleDateString('fr')}` : "Le senior manager n'a pas encore saisie de date !" }}</v-chip>
                                </v-col>

                                <!-- Date de départ souhaitée ou prévue-->

                                <v-col cols="6"
                                    v-if="departure.is_priornotice_reduction && isDemission && departure.is_wanted_early_departure !== null && !departure.is_wanted_early_departure">
                                    <v-menu v-model="wanted_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="" v-model="formatedDisplayDate.wanted_departure_date"
                                                outlined label="Date de départ souhaitée*" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" :rules="required" v-on="on"
                                                readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :rules="required"
                                            :readonly="isRecapProp"
                                            v-model="departure.wanted_departure_date"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6"
                                    v-if="departure.is_priornotice_reduction && isDemission && departure.is_wanted_early_departure !== null && !departure.is_wanted_early_departure">
                                    <v-menu v-model="preavis_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.preavis_reception_date" outlined
                                                label="Date d'envoi de l'accusé de réception" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.preavis_reception_date"></v-date-picker>
                                    </v-menu>
                                </v-col>




                                <v-col v-if="isRupture" cols="6">
                                    <v-menu v-model="refractation_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="" v-model="formatedDisplayDate.end_date_refractation"
                                                outlined label="Date de fin du délai de rétractation*"
                                                append-icon="mdi-calendar" color="depart_pink" v-bind="attrs" v-on="on"
                                                :rules="required" readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.end_date_refractation"></v-date-picker>
                                    </v-menu>
                                </v-col>



                                <v-col v-if="isDemission" :class="$vuetify.breakpoint.xs ? 'mt-5' : ''" cols="12">
                                    <div style="display: flex;">
                                        <v-switch label="Le collaborateur demande t'il une prolongation de
                                                    préavis ?" v-model="departure.is_priornotice_elongation"
                                            v-on:change="changePriornotice(1)" class="ma-0 pa-0 ms-3"
                                            :readonly="isRecapProp || isConsolide" color="depart_pink" inset></v-switch>
                                    </div>
                                </v-col>

                                <!-- Date de départ souhaitée ou prévue-->
                                <v-col v-if="departure.is_priornotice_elongation && isDemission" cols="12" sm="6">
                                    <v-menu v-model="wanted_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.wanted_departure_date" outlined
                                                label="Date de départ souhaitée*" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" :rules="required"
                                                readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :rules="required"
                                            :readonly="isRecapProp"
                                            v-model="departure.wanted_departure_date"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col v-if="departure.is_priornotice_elongation && isDemission" cols="12" sm="6">
                                    <v-menu v-model="preavis_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.preavis_reception_date" outlined
                                                label="Date d'envoi de l'accusé de réception" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" readonly>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.preavis_reception_date"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <!-- Date de départ souhaitée ou prévue-->
                                <v-col
                                    v-if="!isRupture && !isDemission && departure.fk_departure_type_id !== null && ![2, 3, 4, 5, 6, 8, 9, 10, 12].includes(departure.fk_departure_type_id)"
                                    cols="12" sm="6">
                                    <v-menu v-model="wanted_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.wanted_departure_date" outlined
                                                label="Date de départ souhaitée ou prévue*" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on" :rules="required"
                                                readonly></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.wanted_departure_date"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col v-if="isRupture" cols="12" sm="6" :class="$vuetify.breakpoint.xs ? 'mt-5' : ''">
                                    <v-menu v-model="homologation_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.demand_date_approval" outlined
                                                label="Date d'envoi de la demande d'homologation" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.demand_date_approval">
                                        </v-date-picker>
                                    </v-menu>
                                    <p class="text-caption">Aide pour la demande d'homologation : <a target="_blank"
                                            href="https://www.telerc.travail.gouv.fr/simulateur/calendrier">https://www.telerc.travail.gouv.fr/simulateur/calendrier</a>
                                    </p>
                                </v-col>
                            </v-row>


                            <v-row v-if="isRupture">
                                <v-col cols="12" sm="6" :class="$vuetify.breakpoint.xs ? 'mt-5' : ''">
                                    <v-menu v-model="end_homologation_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.end_demand_date_approval" outlined
                                                label="Date de fin du délai d'homologation" append-icon="mdi-calendar"
                                                color="depart_pink" v-bind="attrs" v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.end_demand_date_approval">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <v-row v-if="departure.fk_departure_type_id !== null">
                                <v-col cols="12" sm="6" :class="$vuetify.breakpoint.xs ? 'mt-5' : ''">
                                    <v-menu v-model="early_rdv_pc_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedDisplayDate.early_rdv_pc_date" outlined
                                                label="Date de remise anticipée du PC" color="depart_pink" v-bind="attrs"
                                                v-on="on">
                                                <template v-slot:append>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on" v-bind="attrs" color="depart_pink">
                                                                mdi-alert-circle-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Correspond à la date de départ physique du collaborateur en
                                                            cas de dispense de présence <br> sur la durée du préavis afin
                                                            d'informer la DNI/FMI</span>
                                                    </v-tooltip>
                                                    <v-icon>mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                            v-model="departure.early_rdv_pc_date">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-form>

                        <!-- Bouton "valider" départ -->
                        <v-row class="ma-0 pa-0 justify-end">
                            <!-- Bouton "retour" -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn color="depart_pink" class="depart_pink--text font-weight-bold mb-10 mr-10"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step--" outlined
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px; border: thin solid red;">
                                    Retour
                                </v-btn>
                            </div>

                            <div v-if="!isEditor && !isRecapProp" class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="validateForm"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Valider le départ
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-form>


        <v-dialog v-model="confirm_form" width="500">
            <v-card>
                <v-card-title class="text-h5 depart_pink white--text">
                    Confirmation
                </v-card-title>

                <v-card-text v-if="isCreating" class="black--text mt-5">
                    Création du départ en cours...
                </v-card-text>
                <v-card-text v-else-if="!departure.fk_departure_status_id || date_souhaite_refuse" class="black--text mt-5">
                    Souhaitez-vous confirmer le formulaire ?
                    {{ departure.is_quitting_forever ? "Un mail sera envoyé au senior manager du collaborateur afin de l’inviter à continuer la procédure de départ." : "" }}
                </v-card-text>
                <v-card-text v-else class="black--text mt-5">
                    Souhaitez-vous confirmer le formulaire ?
                    Un mail sera envoyé à FMI afin de l’inviter à continuer
                    la procédure de départ.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isCreating" color="red" text @click="confirm_form = false">
                        Annuler
                    </v-btn>
                    <v-btn v-if="!departure.fk_departure_status_id && !isCreating" color="depart_blue" text
                        v-on:click="createDepart()">
                        Confirmer
                    </v-btn>
                    <v-progress-circular v-else-if="!departure.fk_departure_status_id && isCreating" color="primary"
                        indeterminate></v-progress-circular>
                    <v-btn v-else color="depart_blue" text v-on:click="updateDepart(false)">
                        Continuer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import moment from 'moment'

import DepartsService from '../services/departsService'
const departsService = new DepartsService()

import CollaborateursService from '../services/collaborateursService'
const collaborateursService = new CollaborateursService()

import TypesService from '../services/typesService'
const typesService = new TypesService()

import ManagersService from '../services/managersService'
const managersService = new ManagersService()

import PersonnesService from '../services/personnesService'
const personnesService = new PersonnesService()

import NotificationService from '../services/notificationService'
const notificationService = new NotificationService()

import ReferentielService from '../services/referentielService'
const referentielService = new ReferentielService()


export default {
    name: 'RhForm',
    props: ['id_selected', 'isRecapProp', 'isEditor', 'isConsolide', "departure_to_generate"],
    data() {
        return {
            is_loading: this.isEditor || this.isRecapProp,
            confirm_form: false,
            date_souhaite_refuse: false,
            new_date_proposed: null,
            isRecap: false, //Défini si c'est un récapitulatif
            list_school: [], //Liste des écoles

            form_step: 1, //Etape du formulaire

            birth_date_picker: false, //Affichage date-picker date de naissance
            entry_date_picker: false, //Affichage date-picker date d'entrée
            reception_picker: false, //Affichage date-picker date réception
            receipt_picker: false, //Affichage date-picker accusé réception
            wanted_picker: false, //Affichage date-picker date souhaitée
            legal_picker: false, //Affichage date-picker date légal
            convention_picker: false, //Affichage date-picker date convention
            refractation_picker: false, //Affichage date-picker date de réfractation
            homologation_picker: false, //Affichage date-picker date d'homologation
            end_homologation_picker: false, //Affichage date-picker date de fin d'homologation
            early_rdv_pc_picker: false, //Affichage date-picker date de remise anticipée du PC

            step_one: true, //Indicateur de champs erronés partie 1
            step_two: true, //Indicateur de champs erronés partie 2
            step_three: true, //Indicateur de champs erronés partie 3

            collab_selected: null, //Collaborateur sélectionné
            manager_selected: null,
            collaborateurs: [], //Liste des collaborateurs pour l'autocomplete avec le matricule

            departure_types: [],
            items_consultant: [],
            items_manager: [],
            searchCollab: "",
            searchManager: "",
            isReciveMail: false,
            isOutDate: false,
            error_collab: [],
            isForced: false,
            isInformationDate: false,
            isRupture: false,
            isDemission: false,
            preavis_picker: false,
            information_picker: false,
            prevenance_picker: false,
            return_date_picker: false,

            isCreating: false,
            is_rh_path: false,
            uo_list: [],
            poles_list: [],

            //Données départ
            departure: {
                "cx": null,
                "account": "",
                "announcement_date": null,
                "birth_date": null,
                "commentary": "",
                "deletion_google_date": null,
                "delivery_pc_date": null,
                "delivery_phone_date": null,
                "delivery_receipt_date": null,
                "departure_id": null,
                "departure_reason_label": "",
                "departure_type_label": "",
                "email": "",
                "entry_date": null,
                "firstname": "",
                "fk_departure_reason_id": null,
                "fk_departure_status_id": null,
                "fk_departure_type_id": null,
                "fk_manager_id": null,
                "fk_person_id": null,
                "fk_pm_id": null,
                "glpi": null,
                "google": null,
                "has_pc": null,
                "has_phone": null,
                "internal_investment_assessment": "",
                "is_future_client": null,
                "is_ok_contacted_alumni": null,
                "is_priornotice_reduction": false,
                "is_priornotice_elongation": false,
                "lastname": "",
                "ldap": null,
                "legal_date": null,
                "loyalty_process": null,
                "manager_email": "",
                "manager_firstname": "",
                "manager_id": null,
                "manager_lastname": "",
                "manager_matricule": null,
                "manager_pole": "",
                "manager_uo": "",
                "matricule": null,
                "meeting_manager_consultant_date": null,
                "microsoft": null,
                "official_uo": "",
                "pc_number": null,
                "person_id": null,
                "id_pole": null,
                "poste_label": null,
                "potential_assessment": "",
                "quality_assessment": "",
                "real_departure_date": null,
                "reception_date": null,
                "id_school": null,
                "special_role": null,
                "stc_date": null,
                "type_pop": null,
                "uo_label": "",
                "id_uo": null,
                "wanted_departure_date": null,
                "whoz": null,
                "date_information_consultant": null,
                "end_delay_prevenance": null,
                "demand_date_approval": null,
                "end_date_approval": null,
                "sign_date_convention": null,
                "preavis_reception_date": null,
                "end_date_refractation": null,
                "end_demand_date_approval": null,
                "return_date_collab": null,
                "is_wanted_early_departure": null,
                "early_rdv_pc_date": null,
                "id_pe": null,
                "who_test": this.$store.state.email,
                "is_validate_sm" : false,
                "is_validate_dni" : false,
                "is_validate_fmi" : false,
            },

            //Règles des champs du formulaire
            required: [
                value => !!value || 'Champs requis.'
            ],
            matricule_rules: [
                value => !!value || 'Champs requis.',
                value => /^[0-9]+$/.test(value) || 'Le matricule ne peut être composé que de chiffres !'
            ],
            letters_required: [
                value => !!value || 'Champs requis.',
                value => /^[a-zA-Z\s]+$/.test(value) || 'Le champ ne peut être composé que de lettres !'
            ],

            requiredCheck: [
                value => value !== null || 'Champs requis.'
            ],

            formatedDisplayDate: {
                reception_date: "",
                legal_date: "",
                wanted_departure_date: "",
                delivery_receipt_date: "",
                end_delay_prevenance: "",
                date_information_consultant: "",
                demand_date_approval: "",
                end_date_approval: "",
                sign_date_convention: "",
                preavis_reception_date: "",
                end_demand_date_approval: "",
                return_date_collab: "",
                early_rdv_pc_date: ""
            }
        }
    },
    async mounted() { //Récupération des données du départ sélectionné
        await this.getSchools(); //Récupération des écoles
        await this.getUO();
        await this.getPole();

        this.is_rh_path = this.$route.path.includes('rh');

        if (this.departure_to_generate) {
            this.is_loading = true;
            this.isOutDate = true;
            this.departure = {
                ...this.departure,
                matricule: this.departure_to_generate.matricule,
                firstname: this.departure_to_generate.first_name,
                lastname: this.departure_to_generate.last_name,
                email: this.departure_to_generate.email,
                id_pole: this.departure_to_generate.id_pole,
                id_uo: this.departure_to_generate.id_uo,
                id_school: this.departure_to_generate.id_school,
                entry_date: this.departure_to_generate.entry_date,
                poste_label: this.departure_to_generate.collaborateur_job,
                legal_date: this.newCalcLegalDate(this.departure_to_generate.pe_announcement_date, this.departure_to_generate.prior_notice),
                fk_departure_type_id: [4, 5, 6 , 12 , 13 , 14 , 15].includes(this.departure_to_generate.id_ko_subject) ? 2 : 3,
                id_pe: this.departure_to_generate.id_pe,
            }

            this.manager_selected = {
                lastname: this.departure_to_generate.manager_lastname,
                firstname: this.departure_to_generate.manager_firstname,
                matricule: this.departure_to_generate.manager_matricule,
                email: this.departure_to_generate.manager_email,
            }

            this.items_manager = [{
                text: `${this.departure_to_generate.manager_matricule}. ${this.departure_to_generate.manager_last_name}  ${this.departure_to_generate.manager_first_name}`,
                value: {
                    lastname: this.departure_to_generate.manager_lastname,
                    firstname: this.departure_to_generate.manager_firstname,
                    matricule: this.departure_to_generate.manager_matricule,
                    email: this.departure_to_generate.manager_email,
                }
            }]
        }

        if (this.isEditor || this.isRecapProp) {
            await this.getDepart()
            this.checkTypeDeparture(this.departure.fk_departure_type_id);
            this.manager_selected = {
                id: this.departure.manager_id,
                lastname: this.departure.manager_lastname,
                firstname: this.departure.manager_firstname,
                matricule: this.departure.manager_matricule,
                email: this.departure.manager_email,
                pole: this.departure.manager_pole,
                uo: this.departure.manager_uo
            }
            this.items_manager = [{
                text: `${this.departure.manager_matricule}. ${this.departure.manager_lastname}  ${this.departure.manager_firstname}`,
                value: {
                    id: this.departure.manager_id,
                    lastname: this.departure.manager_lastname,
                    firstname: this.departure.manager_firstname,
                    matricule: this.departure.manager_matricule,
                    email: this.departure.manager_email,
                    pole: this.departure.manager_pole,
                    uo: this.departure.manager_uo
                }
            }]


        }

        await this.getTypes();
        this.is_loading = false;
    },
    methods: {

        maxDay() {
            return moment().format('YYYY-MM-DD')
        },

        async getDepart() { //Récupérer le départ sélectionné
            if (this.id_selected) {
                this.departure = await departsService.getDepartFromId(this.id_selected);
                if (!this.departure) {
                    this.$router.push('/')
                }
            }
            if (!this.departure.reception_date) {
                this.departure.reception_date = new Date().toISOString().slice(0, 10)
            }
        },
        async getCollabs() { //Récupère la liste des collaborateurs
            if (!this.id_selected)
                this.collaborateurs = await collaborateursService.getCollab();
        },
        async getManagerFromCollab(managerId) {
            this.manager_selected = await collaborateursService.getCollabFromIdSX(managerId);
            this.autoCompleteManager(this.manager_selected);
        },
        async getTypes() {
            this.departure_types = await typesService.getTypes(this.departure_to_generate || [2,3].includes(this.departure.fk_departure_type_id) ? "all" : 1);
        },

        async getSchools() {
            this.list_school = (await referentielService.getSchool())?.map(school => {
                return {
                    text: school.nom_ecole,
                    value: school.id_ecole
                }
            })
        },


        async getUO() {
            this.uo_list = (await referentielService.getUo())?.map(uo => {
                console.log(uo)
                return {
                    text: uo.label,
                    value: uo.id_uo
                }
            })
        },

        async getPole() {
            this.poles_list = (await referentielService.getPole())?.map(pole => {
                return {
                    text: pole.label,
                    value: pole.id_pole
                }
            })
        },

        closeForm(type_depart) { //Fermer le formulaire
            if (window.location.pathname.split('/')[1] === "rh") {
                return window.location.href = "/home"
            }
            this.$emit("closeForm", { is_close: false, snackbar_type: type_depart });
        },
        validateForm() { //Valider le formulaire
            this.step_one = this.$refs.step_one.validate()
            //this.step_two = this.$refs.step_two.validate()
            this.step_three = this.$refs.step_three.validate()
            if (this.$refs.form_departure.validate() && this.step_one
                && this.step_two && this.step_three) {
                this.confirm_form = true;
            }
            else
                this.$refs.form_departure.$el.scrollIntoView({ behavior: 'smooth' })
        },


        newCalcLegalDate(pe_announcement_date, prior_notice) {
            switch (prior_notice) {
                case "48h":
                    return moment(pe_announcement_date).add(1, "days").format("YYYY-MM-DD");
                case "2 semaines":
                    return moment(pe_announcement_date).add(13, "days").format("YYYY-MM-DD");
                case "1 mois":
                    return moment(pe_announcement_date).add(1, "months").subtract(1, "days").format("YYYY-MM-DD");
                default:
                    return null;
            }
        },

        calcDate(trigger_legal_date = true) { //Calcul date légal
            // //Revoir quand je vais remettre dans l'ordre les types de départ; faire un find sur le type de départ
            let subtract_in_days = 0;

            switch (this.departure.fk_departure_type_id) {
                case 1:
                    this.departure.legal_date = !trigger_legal_date ? this.departure.legal_date : moment(this.departure.reception_date).add(3, "months").subtract(1, "days").format("YYYY-MM-DD");
                    break;
                // case 2:
                //     subtract_in_days = moment(this.departure.reception_date).diff(moment(this.collab_selected.entryDate), "days");
                //     if (subtract_in_days <= 8) {
                //         this.departure.legal_date = this.departure.reception_date;
                //     } else if (subtract_in_days > 8) {
                //         this.departure.legal_date = moment(this.departure.reception_date).add(1, "days").format("YYYY-MM-DD");
                //     } else {
                //         this.departure.legal_date = null;
                //     }
                //     this.departure.wanted_departure_date = this.departure.wanted_departure_date == null ? this.departure.legal_date : this.departure.wanted_departure_date;
                //     break
                // case 3:
                //     subtract_in_days = moment(this.departure.date_information_consultant).diff(moment(this.collab_selected.entryDate), "days");
                //     if (subtract_in_days <= 8) {
                //         this.departure.end_delay_prevenance = this.departure.date_information_consultant;
                //     } else if (subtract_in_days > 8 && subtract_in_days < 31) {
                //         this.departure.end_delay_prevenance = moment(this.departure.date_information_consultant).add(1, "days").format("YYYY-MM-DD");
                //     } else if (subtract_in_days >= 31 && subtract_in_days < 91) {
                //         this.departure.end_delay_prevenance = moment(this.departure.date_information_consultant).add(13, "days").format("YYYY-MM-DD");
                //     } else if (subtract_in_days >= 91) {
                //         this.departure.end_delay_prevenance = moment(this.departure.date_information_consultant).add(29, "days").format("YYYY-MM-DD");
                //     } else {
                //         this.departure.end_delay_prevenance = null;
                //     }
                //     this.departure.wanted_departure_date = this.departure.wanted_departure_date == null ? this.departure.end_delay_prevenance : this.departure.wanted_departure_date;
                //     break
                case 11:
                    this.departure.end_date_refractation = moment(this.departure.sign_date_convention).add(15, "days").format("YYYY-MM-DD");
                    break
                default:
                    this.departure.wanted_departure_date = this.departure.wanted_departure_date == null ? this.departure.legal_date : this.departure.wanted_departure_date;
                    break
            }

        },
        autoComplete(item) {
            this.departure.matricule = item.matricule;
            this.departure.firstname = item.firstName;
            this.departure.lastname = item.lastName;
            this.departure.birth_date = moment(item.birthDate).format("YYYY-MM-DD");
            this.departure.email = item.email;
            this.departure.pole = item.pole;
            this.departure.fk_uo_id = this.uo_list?.find(el => el.uo_label == item.uo).uo_id;
            this.departure.entry_date = moment(item.entryDate).format("YYYY-MM-DD");
            this.departure.poste_label = item.jobTS;
            this.departure.type_pop = item.typePop;
            this.getManagerFromCollab(item.managerId);
        },
        autoCompleteManager(manager) {
            this.manager_selected = manager;
            this.departure.manager_matricule = manager.matricule;
            this.departure.manager_firstname = manager.firstName;
            this.departure.manager_lastname = manager.lastName;
            this.departure.manager_email = manager.email;
            this.departure.manager_pole = manager.pole;
            this.departure.manager_uo = manager.uo;
        },
        async createDepart() {
            if (!this.departure_to_generate) {
                let personId = await personnesService.createPerson(this.collab_selected, this.manager_selected.matricule);

                this.isCreating = true;
                let departureId = await departsService.createDepart({
                    ...this.departure,
                    matricule: this.collab_selected.matricule,
                    firstname: this.collab_selected.firstName,
                    lastname: this.collab_selected.lastName,
                    poste_label: this.collab_selected.job,
                    email: this.collab_selected.email,
                    manager_email: this.manager_selected.email,
                    is_wanted_early_departure: this.departure.is_wanted_early_departure,
                    pole_label: this.poles_list?.find(el => el.value == this.collab_selected?.id_pole).text,
                    uo_label: this.uo_list?.find(el => el.value == this.collab_selected?.id_uo).text,
                    departure_type_label: this.departure_types?.find(el => el.departure_type_id == this.departure.fk_departure_type_id).departure_type_label
                }, personId.data);
            } else {
                let personId = await personnesService.createPerson({
                    ...this.departure,
                    firstName: this.departure.firstname,
                    lastName: this.departure.lastname,
                    entryDate: this.departure.entry_date,
                    job: this.departure.poste_label,

                }, this.manager_selected.matricule);
                this.isCreating = true;
                let departureId = await departsService.createDepart({
                    ...this.departure,
                    manager_email: this.manager_selected.email,
                    is_wanted_early_departure: this.departure.is_wanted_early_departure,
                    pole_label: this.poles_list?.find(el => el.value === this.departure?.id_pole).text,
                    uo_label: this.uo_list?.find(el => el.value === this.departure?.id_uo).text,
                    departure_type_label: this.departure_types?.find(el => el.departure_type_id == this.departure.fk_departure_type_id).departure_type_label,
                }, personId.data);
            }


            //Do sleep to wait for the creation of the departure
            await new Promise(r => setTimeout(r, 1000));


            this.isCreating = false;
            this.confirm_form = false;
            this.closeForm("depart_create");
        },
        async updateDepart(isSaving) {
            if (isSaving) {
                await departsService.updateDepart({
                    ...this.departure,
                    id_manager: this.manager_selected.matricule
                }, "saving", this.date_souhaite_refuse, this.new_date_proposed);
            }
            else {
                await departsService.updateDepart(this.departure, "validation", this.date_souhaite_refuse, this.new_date_proposed);
                //await notificationService.postFmiNotification(this.departure, this.$store.state.email);
            }
            this.confirm_form = false;
            this.closeForm("depart_update");
        },
        receptionRule() { //Calcul règle date réception
            if (this.departure.delivery_receipt_date) {
                var recept_date_rule = new Date(this.departure.delivery_receipt_date);
                recept_date_rule.setDate(recept_date_rule.getDate() - 1);
                return moment(recept_date_rule).format("YYYY-MM-DD");
            }
            else return null
        },
        receiptRule() { //Calcul règle accusé reception
            var min, max, range;
            range = [];
            if (this.departure.reception_date) {
                min = new Date(this.departure.reception_date);
                min.setDate(min.getDate());
                range.push(moment(min).format("YYYY-MM-DD"));
            }

            if ((this.departure.legal_date && this.departure.wanted_departure_date) ||
                (!this.departure.legal_date && this.departure.wanted_departure_date))
                max = new Date(this.departure.wanted_departure_date);
            else if (this.departure.legal_date && !this.departure.wanted_departure_date)
                max = new Date(this.departure.legal_date);
            else return range

            max.setDate(max.getDate() - 1);
            range.push(moment(max).format("YYYY-MM-DD"))
            return range
        },
        wantedRule() { //Calcul règle date souhaitée
            var min, max, range;
            range = [null, null];

            if ((this.departure.reception_date && this.departure.delivery_receipt_date) ||
                (!this.departure.reception_date && this.departure.delivery_receipt_date))
                min = new Date(this.departure.delivery_receipt_date);
            else if (this.departure.reception_date && !this.departure.delivery_receipt_date)
                min = new Date(this.departure.reception_date);
            else min = null

            if (this.departure.is_priornotice_reduction)
                max = new Date(this.departure.legal_date)
            if (this.departure.is_priornotice_elongation)
                min = new Date(this.departure.legal_date)

            if (min) {
                min.setDate(min.getDate() + 1);
                range[0] = (moment(min).format("YYYY-MM-DD"))
            }
            if (max) {
                range[1] = (moment(max).format("YYYY-MM-DD"))
            }
            return range
        },
        legalRule() { //Calcul règle date légal
            if (this.departure.reception_date) {
                var min = new Date(this.departure.reception_date);
                min.setDate(min.getDate() + 1);
                return moment(min).format("YYYY-MM-DD");
            }
            else return null
        },
        changePriornotice(prior) { //Règles opposition entre prolongement et réduction de préavis
            if (prior == 0) {
                this.departure.is_priornotice_elongation = null;
            }

            if (prior == 1) {
                this.departure.is_priornotice_reduction = null;
                this.departure.is_wanted_early_departure = 0
            }
            this.departure.wanted_departure_date = null
            this.departure.preavis_reception_date = null
            this.formatedDisplayDate.preavis_reception_date = null
            this.formatedDisplayDate.wanted_departure_date = null
        },


        changeFormatDate(date) { //Changement de format de date
            return moment(date).format("DD/MM/YYYY")
        },


        //Refaire la fonction pas opti
        checkTypeDeparture(value) {

            if (!this.isEditor && !this.isRecapProp) {
                let keysDeparture = Object.keys(this.departure)
                for (let i = 0; i < keysDeparture.length; i++) {
                    if (keysDeparture[i].includes("date")) {
                        this.departure[keysDeparture[i]] = null
                    }
                }

                this.formatedDisplayDate = {
                    reception_date: "",
                    legal_date: "",
                    wanted_departure_date: "",
                    delivery_receipt_date: "",
                    end_delay_prevenance: "",
                    date_information_consultant: "",
                    demand_date_approval: "",
                    end_date_approval: "",
                    sign_date_convention: "",
                    preavis_reception_date: "",
                    end_demand_date_approval: "",
                    return_date_collab: "",
                }
            }


            let check_tab_isReciveMail = [2, 3, 5, 6, 7, 4, 3, 8, 11, 10, 9, 12]
            if (check_tab_isReciveMail.includes(value)) {
                this.isReciveMail = false
                this.isOutDate = true
            } else {
                this.isReciveMail = true
                this.isOutDate = true
            }
            if (value === 11) {
                this.isRupture = true
            } else {
                this.isRupture = false
            }

            if (value === 1) {
                this.isDemission = true
            } else {
                this.isDemission = false
            }
        }
    },

    watch: {
        async searchCollab(newValue) {
            if (newValue !== "" && newValue !== null && this.collab_selected === null) {
                this.items_consultant = (await collaborateursService.getCollab(newValue))?.map((collab) => {
                    let uo = this.uo_list?.find((uo) => uo.text === collab.uo)
                    let pole = collab.pole === "Fonctions centrales" ? this.poles_list?.find((pole) => pole.text === "FCT") : this.poles_list?.find((pole) => pole.text === collab.pole)
                    return {
                        text: `${collab.matricule}. ${collab.firstName} ${collab.lastName}`,
                        value: {
                            ...collab,
                            id_uo: uo ? uo.value : null,
                            id_pole: pole ? pole.value : null,
                            id_school: null
                        }
                    }
                })
            }
        },


        async searchManager(newValue) {
            if (newValue !== "" && newValue !== null && this.manager_selected === null) {
                this.items_manager = (await collaborateursService.getCollab(newValue))?.map((collab) => {
                    let uo = this.uo_list?.find((uo) => uo.text === collab.uo)
                    let pole = collab.pole === "Fonctions centrales" ? this.poles_list?.find((pole) => pole.text === "FCT") : this.poles_list?.find((pole) => pole.text === collab.pole)
                    return {
                        text: `${collab.matricule}. ${collab.firstName} ${collab.lastName}`,
                        value: {
                            ...collab,
                            id_uo: uo ? uo.value : null,
                            id_pole: pole ? pole.value : null,
                            id_school: null
                        }
                    }
                })
            }
        },


        async collab_selected(newValue) {
            if (newValue !== null && newValue.manager !== null && !this.departure_to_generate) {
                if ((await personnesService.getPersonne(newValue.matricule)).length > 0) {
                    this.error_collab = ['Le collaborateur est déjà présent dans le suivi des départs.']
                } else {
                    this.error_collab = []
                }
                this.manager_selected = await collaborateursService.getCollabFromIdSX(newValue.manager_matricule);
                this.items_manager = [{
                    text: `${this.manager_selected.matricule}. ${this.manager_selected.firstName} ${this.manager_selected.lastName}`,
                    value: this.manager_selected
                }]
            }
        },



        'departure.reception_date'(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.reception_date = moment(newValue).format("DD/MM/YYYY")
            }
        },

        'departure.legal_date'(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.legal_date = moment(newValue).format("DD/MM/YYYY")
            }
        },

        'departure.wanted_departure_date'(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.wanted_departure_date = moment(newValue).format("DD/MM/YYYY")
            }
        },
        'departure.delivery_receipt_date'(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.delivery_receipt_date = moment(newValue).format("DD/MM/YYYY")
            }
        },


        'departure.date_information_consultant'(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.date_information_consultant = moment(newValue).format("DD/MM/YYYY")
            }
        },

        'departure.end_delay_prevenance'(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.end_delay_prevenance = moment(newValue).format("DD/MM/YYYY")
            }
        },

        "departure.demand_date_approval"(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.demand_date_approval = moment(newValue).format("DD/MM/YYYY")
            }
        },

        "departure.end_date_refractation"(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.end_date_refractation = moment(newValue).format("DD/MM/YYYY")
            }
        },

        "departure.sign_date_convention"(newValue) {
            if (newValue !== null) {
                this.formatedDisplayDate.sign_date_convention = moment(newValue).format("DD/MM/YYYY")
            }
        },


        "departure.preavis_reception_date"() {
            if (this.departure.preavis_reception_date !== null) {
                this.formatedDisplayDate.preavis_reception_date = moment(this.departure.preavis_reception_date).format("DD/MM/YYYY")
            }
        },


        "departure.end_demand_date_approval"() {
            if (this.departure.end_demand_date_approval !== null) {
                this.formatedDisplayDate.end_demand_date_approval = moment(this.departure.end_demand_date_approval).format("DD/MM/YYYY")
            }
        },


        "departure.return_date_collab"() {
            if (this.departure.return_date_collab !== null) {
                this.formatedDisplayDate.return_date_collab = moment(this.departure.return_date_collab).format("DD/MM/YYYY")
            }
        },

        "departure.early_rdv_pc_date"() {
            if (this.departure.early_rdv_pc_date !== null) {
                this.formatedDisplayDate.early_rdv_pc_date = moment(this.departure.early_rdv_pc_date).format("DD/MM/YYYY")
            }
        },
    }
}
</script>

<style>
@media screen and (max-width: 1450px) {
    .v-input {
        transform: scale(0.8);
        transform-origin: left;
    }
}

.rh_form {
    background-color: white;
    height: fit-content;
    min-height: 25rem;
}

.v-alert {
    font-size: .8rem;
}

h2 {
    font-size: 1.5rem;
}

#close_form,
#save_form {
    transition-duration: .4s;
    transition-timing-function: cubic-bezier();
}

#close_form:hover {
    transform: scale(1.2) rotate(90deg);
}

#save_form:hover {
    transform: scale(1.2);
}


.my-date-picker {

    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}</style>