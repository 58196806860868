<template>
<!-- Page de chargement -->
  <div class="loading_page ma-0 pa-0">
      <div class="content">
          <h1>Chargement des départs...</h1>
            <v-progress-linear
                    indeterminate
                    color="depart_pink"
                    rounded
                    height="6"
            ></v-progress-linear>
          <p>En cas de chargement trop long, essayez de rafraichir la page.</p>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'LoadingPage',
    data: () => ({
        
            
    }),
    methods: {

    },
  }
</script>

<style>
    .loading_page{
        position: absolute;
        display: flex;
        width: 100%;
        height: 90vh;
        background-color: white;
        color: #006a9e;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .content{
        display: block;
        width: 35rem;
        height: fit-content;
        margin: auto auto;
        text-align: center;
    }

</style>
