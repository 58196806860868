<template>
    <v-card :width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '100%' : '20rem'">
        <v-row align="center" class="ma-0 pa-2">
            <v-col cols="7">
                <h1 class="ma-0 pa-0">Filtres</h1>
            </v-col>
            <v-col align="end" cols="5">
                <p style="cursor: pointer" class="ma-0 pa-0 depart_blue--text" v-on:click="resetFilters()">Effacer les
                    filtres</p>
            </v-col>
        </v-row>
        <v-expansion-panels class="mb-3" flat style="max-width: 100%">
            <!-- Pôle -->
            <v-expansion-panel class="ma-0 py-0 elevation-0">
                <v-expansion-panel-header class="ma-0 py-0">
                    Pôle
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5 depart_grey">
                    <v-row class="ma-0 pa-0">
                        <v-autocomplete label="Veuillez sélectionner un ou plusieurs pôles" outlined
                            v-model="poles_selected" :items="poles_list" item-value="id_pole" item-text="label" chips
                            clearable multiple></v-autocomplete>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- UO -->
            <v-expansion-panel class="ma-0 py-0">
                <v-expansion-panel-header class="ma-0 py-0">
                    UO
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5 depart_grey" style="font-size: 12px;">
                    <v-row class="ma-0 pa-0">
                        <v-autocomplete label="Veuillez sélectionner une ou plusieurs UO" outlined v-model="uo_selected"
                            :items="uo_list" item-value="id_uo" item-text="label" chips clearable multiple></v-autocomplete>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="isToShow" class="ma-0 py-0">
                <v-expansion-panel-header class="ma-0 py-0">
                    Statut
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5 depart_grey" style="font-size: 12px;">
                    <v-row class="ma-0 pa-0">
                        <v-autocomplete label="Veuillez sélectionner un ou plusieurs statut" outlined
                            v-model="status_selected" :items="status_labels" item-value="value" item-text="label" chips
                            clearable :multiple="role_selected[0] !== 4"></v-autocomplete>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Type de départ -->
            <v-expansion-panel v-if="$store.state.role_selected == 1 || $store.state.role_selected == 2" class="ma-0 py-0">
                <v-expansion-panel-header class="ma-0 py-0">
                    Type de départ
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5 depart_grey" style="font-size: 12px;">
                    <v-row class="ma-0 pa-0">
                        <v-autocomplete label="Veuillez sélectionner un ou plusieurs type de départ" outlined
                            v-model="types_selected" :items="types_labels" chips clearable multiple></v-autocomplete>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- Date -->
            <v-expansion-panel class="ma-0 py-0 elevation-0">
                <v-expansion-panel-header class="ma-0 py-0 elevation-0">
                    Date de départ réelle
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5 depart_grey">
                    <v-menu v-model="date_range_picker" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="date_range_text" outlined label="Date de départ réelle"
                                hint="Date de départ réelle" append-icon="mdi-calendar" color="depart_pink" v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker range color="depart_blue" scrollable v-model="date_range"></v-date-picker>
                    </v-menu>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-col align="center">
            <v-btn class="btn-apply ma-0 pa-0 mb-5 depart_blue white--text" elevation="0" width="75%" height="2.5rem"
                v-on:click="applyFilters()">Appliquer les filtres</v-btn>
        </v-col>
    </v-card>
</template>

<script>
import moment from 'moment'

import TypesService from '../services/typesService'
const typesService = new TypesService()

import ReferentielService from '../services/referentielService'
const referentielService = new ReferentielService()

export default {
    name: 'FiltersComponent',
    props: ["role_selected"],

    data: () => ({
        //Filtres labels
        poles_labels: ['Fonctions centrales', 'TRIN', 'RHSPM', 'BFA', 'ENCO', 'N/A'],
        status_labels: [],
        types_labels: [],
        date_range: [],
        date_range_picker: false,
        date_range_text: '',

        //Filtres sélectionnés
        poles_selected: [],
        uo_selected: [],
        status_selected: [],
        types_selected: [],
        date_begin: null,
        date_finished: null,
        isToShow: false,
    }),
    async mounted() {
        await this.getTypes();
        switch (this.role_selected[0] | this.role_selected) {
            case 1:
                this.status_labels = [
                    { label: "Attente senior manager", value: "Attente senior manager", color: "depart_attente" },
                    { label: "Attente remise matériel", value: "Attente remise matériel", color: "depart_attente" },
                    { label: "Attente SM/Remise materiel", value: "Attente SM/Remise materiel", color: "depart_attente"}
                ]
                this.isToShow = true;
                break;
            case 2:
                this.status_labels = [
                    { label: "À saisir senior manager / En cours de saisie", value: "À saisir senior manager", color: "depart_saisir" },
                    { label: "Attente remise matériel", value: "Attente remise matériel", color: "depart_attente" },
                ]
                this.isToShow = true;
                break;
            default:
                this.isToShow = false;
        }
    },


    methods: {
        resetFilters() {
            this.poles_selected = [];
            this.uo_selected = [];
            this.status_selected = [];
            this.types_selected = [];
            this.date_begin = null;
            this.date_finished = null;
            this.date_range = [];
            this.date_range_text = '';

            this.$emit("applyFilters", this.poles_selected, this.uo_selected, this.status_selected,
                this.types_selected, this.date_begin, this.date_finished);
        },
        applyFilters() {
            this.$emit("applyFilters", this.poles_selected, this.uo_selected, this.status_selected,
                this.types_selected, this.date_begin, this.date_finished);
        },
        async getTypes() {
            var types = await typesService.getTypes();
            for (let i = 0; i < types.length; i++) {
                this.types_labels.push(types[i].departure_type_label)
            }
        },

    },


    computed: {
        uo_list() {
            return this.$store.getters.uo
        },

        poles_list() {
            return this.$store.getters.pole
        }
    },



    watch: {
        date_range(newVal) {
            if (newVal.length == 2) {
                this.date_range_text = `Du ${moment(newVal[0]).format('DD/MM/YYYY')} au ${moment(newVal[1]).format('DD/MM/YYYY')}`
                this.date_begin = moment(newVal[0]).format('YYYY-MM-DD')
                this.date_finished = moment(newVal[1]).format('YYYY-MM-DD')
            }
        },

        role_selected(newVal) {
            switch (newVal) {
                case 1:
                    this.status_labels = [
                        { label: "Attente senior manager", value: "Attente senior manager", color: "depart_attente" },
                        { label: "Attente remise matériel", value: "Attente remise matériel", color: "depart_attente" },
                        { label: "Attente SM/Remise materiel", value: "Attente SM/Remise materiel", color: "depart_attente"}
                    ]
                    this.isToShow = true;
                    break;
                case 2:
                    this.status_labels = [
                        { label: "À saisir senior manager / En cours de saisie", value: "À saisir senior manager", color: "depart_saisir" },
                        { label: "Attente remise matériel", value: "Attente remise matériel", color: "depart_attente" },
                    ]
                    this.isToShow = true;
                    break;
                default:
                    this.isToShow = false;
            }
            this.status_selected = [];
        }
    }

}
</script>

<style scoped>
.btn-apply {
    text-transform: none !important;
}

h1 {
    font-size: 24px;
}

p {
    font-size: 12px;
}

#checkboxLabel {
    font-size: 12px;
    color: black;
}

@media screen and (max-width: 1450px) {
    h1 {
        font-size: 16px;
    }

    h2 {
        font-size: 12px;
    }

    .v-btn label {
        font-size: 14px;
    }

    p {
        font-size: 12px;
    }
}</style>