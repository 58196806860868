<template>
<!-- Formulaire de départ -->
    <div class="ma-0 pa-0 white" style="height: fit-content;">
        <!-- Header -->
        <v-container fluid align="center" justify="center"
            class="ma-0 pa-0 pt-3 pb-3 depart_pink white--text d-flex justify-space-between"
        >
            <h1 class="ml-5" style="width: fit-content;">Vue consolidée</h1>
            <v-icon id="close_form" large color="white" v-on:click="closeForm()" class="mr-7">mdi-close-circle-outline</v-icon>
        </v-container>

        <v-expansion-panels class="ma-0 pa-0">
            <!-- RH -->
            <v-expansion-panel v-if="$store.state.role_selected[0] !== 4 && $store.state.role_selected !== 4" class="ma-0 pa-0">
                <v-expansion-panel-header>
                    <strong>RH</strong>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="depart_grey">
                    <RhForm :isRecapProp="true" :isConsolide="true" :isEditor="false" :uo_list="uo_list" :poles_list="poles_list" class="ma-0 pa-0" :id_selected="id_selected" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Manager -->
            <v-expansion-panel v-if="$store.state.role_selected[0] !== 4 && $store.state.role_selected !== 4" class="ma-0 pa-0">
                <v-expansion-panel-header>
                    <strong>Senior Manager</strong>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="depart_grey">
                    <ManagerForm :isRecapProp="true" :isConsolide="true" :isEditor="false" :uo_list="uo_list" :poles_list="poles_list" class="ma-0 pa-0" :id_selected="id_selected" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- FMI -->
            <v-expansion-panel class="ma-0 pa-0">
                <v-expansion-panel-header>
                    <strong>FMI</strong>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="depart_grey">
                    <FmiForm :isRecapProp="true" :isConsolide="true" :isEditor="false" :uo_list="uo_list" :poles_list="poles_list" class="ma-0 pa-0" :id_selected="id_selected" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- DNI -->
            <v-expansion-panel class="ma-0 pa-0">
                <v-expansion-panel-header>
                    <strong>DNI</strong>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="depart_grey">
                    <DniForm :isRecapProp="true" :isConsolide="true" :isEditor="false" :uo_list="uo_list" :poles_list="poles_list" class="ma-0 pa-0" :id_selected="id_selected" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import RhForm from './RhForm.vue'
import ManagerForm from './ManagerForm.vue'
import FmiForm from './FmiForm.vue'
import DniForm from './DniForm.vue'

export default {
    name: 'SeeConsolideForm',
    props: ['id_selected', 'uo_list' , "poles_list"],
    components:{
        RhForm,
        ManagerForm,
        FmiForm,
        DniForm,
    },
    data () {
      return {

      }
    },
    methods:{
        closeForm() { //Fermer le formulaire
            this.$emit("closeForm", false);
        },
    },
}
</script>

<style scoped>
    .see_consolide_form{
        background-color: white;
        height: fit-content;
        min-height: 25rem;
    }

    @media screen and (max-width: 1450px) {
        .v-input{
            transform: scale(0.8);
            transform-origin: left;
        }
    }

    h2{
        font-size: 1.5rem;
    }

    #close_form{
        transition-duration: .4s;
        transition-timing-function: cubic-bezier();
    }

    #close_form:hover{
        transform: scale(1.2) rotate(90deg);
    }

    .v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }
</style>