import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"

export const baseUrl = process.env.VUE_APP_URL_API+"/status";

export default class StatusService {

  getStatus(role) {
    return new Promise( (resolve, reject) => {
        apiClient.get(`${baseUrl}/${role}`,
        {headers: {'Authorization': 'Bearer ' + store.state.token,}})
        .then(function(response){
          resolve(response.data);
        })
        .catch(function(error){
          reject(error);
        });
    })
  }
}