import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]

import excel from 'vue-excel-export'
import VueJsonToCsv from 'vue-json-to-csv'

Vue.use(excel)
Vue.component('vue-json-to-csv', VueJsonToCsv)

const savedState = JSON.parse(sessionStorage.getItem('store') || localStorage.getItem('store'))
if (savedState) {
  store.replaceState(savedState)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
