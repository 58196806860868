<template>
    <!-- Formulaire de départ -->
    <div class="ma-0 pa-0 white" style="height: fit-content;">
        <!-- Header -->
        <v-container fluid align="center" justify="center"
            class="ma-0 pa-0 pt-3 pb-3 depart_pink white--text d-flex justify-space-between">
            <h1 class="ml-5" style="width: fit-content;">Éditeur de départ</h1>
            <v-icon id="close_form" large color="white" v-on:click="closeForm"
                class="mr-7">mdi-close-circle-outline</v-icon>
        </v-container>
        <v-expansion-panels class="ma-0 pa-0">
            <!-- RH -->
            <v-expansion-panel class="ma-0 pa-0">
                <v-expansion-panel-header>
                    <strong>RH</strong>
                    <!-- Bouton "Sauvegarder" -->
                    <v-col class="d-flex flex-row-reverse">
                        <v-btn color="depart_pink" class="depart_pink--text font-weight-bold" cols="6" height="25"
                            elevation="0" outlined v-on:click.stop="saveRhForm()"
                            style="text-transform: none !important; border-radius: 5px; font-size: 12px; border: thin solid red;">
                            <span v-if="!is_saving">Sauvegarder</span>
                            <v-progress-circular v-else indeterminate color="depart_pink" size="10"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="depart_grey" eager>
                    <RhForm ref="rhForm" :isEditor="true" class="ma-0 pa-0" :id_selected="id_selected" :uo_list="uo_list"
                        :poles_list="poles_list" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Manager -->
            <v-expansion-panel class="ma-0 pa-0">
                <v-expansion-panel-header>
                    <strong>Senior Manager</strong>
                    <!-- Bouton "Sauvegarder" -->
                    <v-col class="d-flex flex-row-reverse">
                        <v-btn color="depart_pink" class="depart_pink--text font-weight-bold" cols="6" height="25"
                            elevation="0" outlined v-on:click.stop="saveManagerForm()"
                            style="text-transform: none !important; border-radius: 5px; font-size: 12px; border: thin solid red;">
                            <span v-if="!is_saving">Sauvegarder</span>
                            <v-progress-circular v-else indeterminate color="depart_pink" size="10"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="depart_grey" eager>
                    <ManagerForm ref="managerForm" :isEditor="true" class="ma-0 pa-0" :id_selected="id_selected"
                        :uo_list="uo_list" />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="ma-0 pa-0">
                <v-expansion-panel-header>
                    <strong class="my-4">Date de départ réelle</strong>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content>
                    <v-card class="ma-0 pa-0 my-3 d-flex justify-space-around" align="center" justify="center"
                        elevation="0">
                        <v-col cols="5" class="ma-0 pa-0">
                            <p>Date de départ réelle</p>
                            <v-chip v-if="real_date">{{ new Date(real_date).toLocaleDateString('fr') }}</v-chip>
                            <v-chip v-else>Aucune</v-chip>
                        </v-col>
                        <v-col cols="5" class="ma-0 pa-0">
                            <v-row cols="5" justify="center" class="ma-0 pa-0">
                                <v-menu v-model="real_date_picker" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mt-6" v-model="real_date_formatted"
                                            label="Nouvelle date de départ réelle" append-icon="mdi-calendar"
                                            color="depart_pink" v-bind="attrs" v-on="on" readonly></v-text-field>
                                    </template>
                                    <v-date-picker color="depart_blue" scrollable v-model="new_real_date"></v-date-picker>
                                </v-menu>
                                <v-tooltip top v-if="new_real_date && !isSavingRealDate">
                                    <template v-slot:activator="{ on }">
                                        <v-icon class="ma-0 pa-0 mt-3 depart_pink--text" v-on:click="updateRealDate()"
                                            v-on="on">mdi-content-save</v-icon>
                                    </template>
                                    <span>Enregistrer la date</span>
                                </v-tooltip>
                                <v-progress-circular class="mt-6" v-else-if="new_real_date" color="depart_pink"
                                    indeterminate></v-progress-circular>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import RhForm from './RhForm.vue'
import ManagerForm from './ManagerForm.vue'
import FmiForm from './FmiForm.vue'
import DniForm from './DniForm.vue'

import DepartsService from '../services/departsService'
const departsService = new DepartsService()

export default {
    name: 'EditFormComponent',
    props: ['id_selected', 'status_id', 'departure', 'real_departure_date_selected', 'uo_list', 'poles_list'],
    components: {
        RhForm,
        ManagerForm,
        FmiForm,
        DniForm,
    },
    data() {
        return {
            new_real_date: null,
            real_date: null,
            real_date_picker: false,
            real_date_formatted: null,
            isSavingRealDate: false,
            is_saving: false
        }
    },
    created() {
        this.real_date = this.real_departure_date_selected
    },
    methods: {
        closeForm() { //Fermer le formulaire
            this.$emit("closeForm", { is_close: false });
        },
        async saveRhForm() {
            this.is_saving = true
            await this.$refs.rhForm.updateDepart(true)
            this.$emit("savingSnack", { snackbar_type: "depart_update" })
            this.is_saving = false
        },
        saveManagerForm() {
            this.is_saving = true
            this.$refs.managerForm.updateDepart(true, false)
            this.$emit("savingSnack", { snackbar_type: "depart_update" })
            this.is_saving = false
        },

        async updateRealDate() {
            this.real_date = this.new_real_date
            this.isSavingRealDate = true
            await departsService.updateRealDepartureDate(this.status_id, this.id_selected, this.new_real_date, this.departure)
            this.new_real_date = null
            this.isSavingRealDate = false
        }
    },


    watch: {
        new_real_date(newValue) {
            if (newValue !== null) {
                this.real_date_formatted = new Date(newValue).toLocaleDateString('fr')
            }
        }
    }
}
</script>

<style scoped>
.editor_form {
    background-color: white;
    height: fit-content;
    min-height: 25rem;
}

@media screen and (max-width: 1450px) {
    .v-input {
        transform: scale(0.8);
        transform-origin: left;
    }
}

h2 {
    font-size: 1.5rem;
}

#close_form {
    transition-duration: .4s;
    transition-timing-function: cubic-bezier();
}

#close_form:hover {
    transform: scale(1.2) rotate(90deg);
}

.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>