import apiClient from '../plugins/api_client.js'
import qs from 'qs'

let baseUrl = process.env.VUE_APP_URL_COGNITO;
const requestBody = {
    client_id: process.env.VUE_APP_CLIENT_ID,
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
}

export default class TokenService {

    getToken() {
        return new Promise( (resolve, reject) => {
            apiClient.post(`${baseUrl}`,
            qs.stringify(requestBody),
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            })
            .then(function(response){
                resolve(response.data.access_token);
            })
            .catch(function(error){
                reject(error);
            });
        });
    }
  }