<template>
    <!-- Formulaire de départ -->
    <div class="manager_form">
        <!-- Header -->
        <v-container v-if="!isEditor && !isConsolide" fluid align="center" justify="center"
            class="ma-0 pa-0 pt-3 pb-3 white--text d-flex justify-space-between depart_pink">
            <h1 v-if="this.departure.fk_departure_status_id == 1" class="ml-7" style="width: fit-content;">Formulaire de
                départ</h1>
            <h1 v-else class="ml-5" style="width: fit-content;">Récapitulatif du départ</h1>
            <v-col cols="3" class="ma-0 pa-0 d-flex justify-end">
                <v-tooltip top v-if="!isRecapProp">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon id="save_form" large color="white" class="mr-3" v-on:click="saveForm()" v-bind="attrs"
                            v-on="on">mdi-content-save</v-icon>
                    </template>
                    <span>Sauvegarder</span>
                </v-tooltip>
                <v-icon id="close_form" large color="white" class="mr-7"
                    v-on:click="JSON.stringify(departure) === JSON.stringify(departure_template) ? closeForm(false, '') : confirm_close = true">mdi-close-circle-outline</v-icon>
            </v-col>
        </v-container>


        <div v-if="is_loading">
            <v-progress-linear indeterminate color="depart_pink" height="10px"></v-progress-linear>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
            <v-skeleton-loader type="list-item-three-line" class="mt-5" :loading="is_loading" height="100px">
            </v-skeleton-loader>
        </div>

        <!-- Formulaire -->
        <v-form v-else ref="form_departure" lazy-validation class="pa-4">
            <v-stepper non-linear class="ma-0 pa-0 mb-5" elevation="0" v-model="form_step">
                <v-stepper-header class="elevation-0">
                    <v-stepper-step editable step="1" color="depart_blue" :rules="[value => step_one]">
                        <small>Collaborateur</small>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step editable step="2" color="depart_blue" :rules="[value => step_two]">
                        <small>Départ</small>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3" editable color="depart_blue" :rules="[value => step_three]">
                        <small>Caractéristiques</small>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="4" editable color="depart_blue" :rules="[value => step_four]">
                        <small>Alumni/Boomerang</small>
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1" class="ma-0 pa-0">
                        <v-form ref="step_one">
                            <!-- Consultant concerné -->
                            <div>
                                <h2 class="ml-5 pa-0 mb-5">Collaborateur concerné</h2>
                                <!-- Matricule -->
                                <v-card class="rounded-lg mb-5" width="95%"
                                    style="box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); margin: auto auto;">
                                    <v-card-title class="mb-2">
                                        <v-col>
                                            <v-row>
                                                <h2 style="color: #B4B4B4;">{{ departure.matricule }}&nbsp;</h2>
                                                <h2>{{ departure.firstname }} {{ departure.lastname }}</h2>
                                            </v-row>
                                            <v-row class="mt-0">
                                                <em class="subtitle-2">{{
                                                    departure.poste_label
                                                }}</em>
                                            </v-row>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p>
                                                <strong> Date d'arrivée : </strong>{{
                                                    new Date(departure.entry_date).toLocaleDateString('fr')
                                                }}<br>

                                                <strong>Responsable :</strong> {{ departure.manager_firstname }}
                                                {{ departure.manager_lastname }}<br>
                                                <strong>UO :</strong> {{
                                                    departure.uo
                                                }}<br>
                                            </p>
                                            <p> <strong>Type de départ :</strong> {{ departure.departure_type_label }}<br>
                                                <span
                                                    v-if="departure.fk_departure_type_id !== 8">
                                                    <strong>Date de départ légale :</strong> {{
                                                        new
                                                            Date(departure.legal_date).toLocaleDateString('fr')
                                                    }}<br>
                                                </span>
                                                <span v-else-if="departure.fk_departure_type_id == 8">
                                                    <strong>Date de départ souhaitée :</strong> {{
                                                        new
                                                            Date(departure.wanted_departure_date).toLocaleDateString('fr')
                                                    }}<br>
                                                </span>
                                            </p>
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </div>

                            <v-row class="ma-0 pa-0 mt-10 justify-end">
                                <!-- Bouton continuer -->
                                <div class="btn_add_departure ma-0 pa-0">
                                    <v-btn id="validate_btn" color="depart_pink"
                                        class=" white--text font-weight-bold mb-0 mr-5" width="12rem" height="2.5rem"
                                        elevation="0" v-on:click="form_step++"
                                        style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                        Continuer
                                    </v-btn>
                                </div>
                            </v-row>
                        </v-form>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <!-- Saisie du départ -->
                        <v-form ref="step_two">
                            <h2 class="mb-5">Départ</h2>
                            <!-- Uo de départ -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-row v-on="on">
                                        <v-col cols="12" sm="6">
                                            <v-text-field class="ma-0 pa-0" v-model="departure.pole" label="Pôle de départ"
                                                disabled outlined inset></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field class="ma-0 pa-0" v-model="departure.uo" label="UO de départ"
                                                disabled outlined inset></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                                <span>Pour tous changements de pôle/UO, merci de contacter la RH</span>
                            </v-tooltip>


                            <!-- Nature de prime -->
                            <v-row v-if="![2,3,12, 9, 8].includes(departure.fk_departure_type_id)" align="center">
                                <v-col cols="12" sm="5">
                                    <v-switch v-model="isNatureBonus" class="ma-0 pa-0 ms-3" color="depart_pink"
                                        label="Le collaborateur doit-il recevoir un variable / prime ?"
                                        :readonly="isRecap || isConsolide" inset></v-switch>
                                </v-col>
                            </v-row>


                            <v-row v-if="isNatureBonus">
                                <v-col cols="2" sm="5">
                                    <v-select v-model="nature_bonus_selected.nature_bonus_1.id"
                                        label="Nature de la prime/variable à verser" :items="nature_bonus_list" outlined
                                        :rules="required" :disabled="isNatureBonus_2" :readonly="isRecap || isConsolide"
                                        @change="popNatureBonus($event, 2)"></v-select>
                                </v-col>
                                <v-col v-if="nature_bonus_selected.nature_bonus_1.id !== null" cols="2" sm="5">
                                    <v-text-field class="ma-0 pa-0" v-model="nature_bonus_selected.nature_bonus_1.amount"
                                        outlined label="Prime/variable à verser" :readonly="isRecap || isConsolide"
                                        :clearable="!isRecap" type="number" min="0" suffix="€"
                                        :rules="rules_pos"></v-text-field>
                                </v-col>
                                <v-col v-if="nature_bonus_selected.nature_bonus_1.id !== null" cols="2">
                                    <v-icon v-if="!isNatureBonus_2 && !(isRecap || isConsolide)"
                                        @click="() => isNatureBonus_2 = true" color="depart_pink" class="ma-0 pa-0 mt-6">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                </v-col>
                            </v-row>


                            <v-row v-if="isNatureBonus_2 && isNatureBonus">
                                <v-col cols="2" sm="5">
                                    <v-select v-model="nature_bonus_selected.nature_bonus_2.id"
                                        label="Nature de la prime/variable à verser" :items="nature_bonus_list_2" outlined
                                        :rules="required" :disabled="isNatureBonus_3" :readonly="isRecap || isConsolide"
                                        @change="popNatureBonus($event, 3)"></v-select>
                                </v-col>
                                <v-col v-if="nature_bonus_selected.nature_bonus_2.id !== null" cols="2" sm="5">
                                    <v-text-field class="ma-0 pa-0" v-model="nature_bonus_selected.nature_bonus_2.amount"
                                        outlined label="Prime/variable à verser" :readonly="isRecap || isConsolide"
                                        :clearable="!isRecap" type="number" min="0" suffix="€"
                                        :rules="rules_pos"></v-text-field>
                                </v-col>
                                <v-col v-if="nature_bonus_selected.nature_bonus_2.id !== null" cols="2">
                                    <v-icon v-if="!isNatureBonus_3 && !(isRecap || isConsolide)"
                                        @click="() => isNatureBonus_3 = true" color="depart_pink" class="ma-0 pa-0 mt-6">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                    <v-icon v-if="!isNatureBonus_3 && !(isRecap || isConsolide)" @click="[
                                        isNatureBonus_2 = false,
                                        nature_bonus_selected.nature_bonus_2.id = null,
                                        nature_bonus_selected.nature_bonus_2.amount = null
                                    ]" color="depart_pink" class="ma-0 pa-0 mt-6">
                                        mdi-minus-circle-outline
                                    </v-icon>
                                </v-col>
                            </v-row>


                            <v-row v-if="isNatureBonus_3 && isNatureBonus">
                                <v-col cols="2" sm="5">
                                    <v-select v-model="nature_bonus_selected.nature_bonus_3.id"
                                        label="Nature de la prime/variable à verser" :items="nature_bonus_list_3" outlined
                                        :rules="required" :readonly="isRecap || isConsolide"></v-select>
                                </v-col>
                                <v-col v-if="nature_bonus_selected.nature_bonus_3.id !== null" cols="2" sm="5">
                                    <v-text-field class="ma-0 pa-0" v-model="nature_bonus_selected.nature_bonus_3.amount"
                                        outlined label="Prime/variable à verser" :readonly="isRecap || isConsolide"
                                        :clearable="!isRecap" type="number" min="0" suffix="€"
                                        :rules="rules_pos"></v-text-field>
                                </v-col>
                                <v-col v-if="nature_bonus_selected.nature_bonus_3.id !== null && !(isRecap || isConsolide)"
                                    cols="2">
                                    <v-icon @click="[
                                        isNatureBonus_3 = false,
                                        nature_bonus_selected.nature_bonus_3.id = null,
                                        nature_bonus_selected.nature_bonus_3.amount = null
                                    ]" color="depart_pink" class="ma-0 pa-0 mt-6">
                                        mdi-minus-circle-outline
                                    </v-icon>
                                </v-col>
                            </v-row>


                            <v-row align="center">
                                <v-col cols="12" sm="5">
                                    <v-switch v-model="isPM" class="ma-0 pa-0 ms-3" color="depart_pink"
                                        label="Le collaborateur a-t-il un manager de rattachement ?"
                                        :readonly="isRecap || isConsolide" inset></v-switch>
                                </v-col>
                                <v-col v-if="isPM" cols="12" sm="7">
                                    <v-autocomplete class="mt-5" v-model="pm_selected" @change="autoCompletePM($event)"
                                        no-data-text="Veuillez saisir les premières lettres du nom/prénom du manager"
                                        label="Selectionner le manager*" :readonly="isRecap || isConsolide"
                                        :items="items_manager" outlined :search-input.sync="searchManager"
                                        :clearable="!isRecap && !isConsolide" :rules="required"
                                        :error-messages="errorManager" ref="autocomplete_manager"></v-autocomplete>
                                </v-col>
                            </v-row>

                            <!-- PM -->
                            <v-row v-if="![2,3,11, 10].includes(departure.fk_departure_type_id)" align="center">
                                <v-col cols="12" sm="5">
                                    <v-switch v-model="isMeetingManager" class="ma-0 pa-0 ms-3" color="depart_pink"
                                        label="Le collaborateur a t-il eu un rendez-vous avec son senior manager ?"
                                        :readonly="isRecap || isConsolide" inset></v-switch>
                                </v-col>
                                <v-col cols="12" sm="7" v-if="isMeetingManager">
                                    <!-- S'il y a un RDV-->
                                    <v-menu v-model="meeting_manager_consultant_picker" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="departure.meeting_manager_consultant_date" outlined
                                                label="Date du RDV*" hint="Date du RDV Senior manager/collaborateur :"
                                                hide-details="auto" type="date" readonly append-icon="mdi-calendar"
                                                color="depart_pink" :rules="required" v-bind="attrs" v-on="on"
                                                :error-messages="errorDateRDV"
                                                ref="text_field_meeting_manager_consultant_date"></v-text-field>
                                        </template>
                                        <v-date-picker color="depart_blue" scrollable :readonly="isRecap || isConsolide"
                                            v-model="departure.meeting_manager_consultant_date"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <!-- Libération de la clause de non concurrence -->
                            <v-row v-if="departure.fk_departure_type_id === 1" align="center">
                                <v-col cols="8" sm="4">
                                    Libérer le collaborateur de la clause de non-concurrence* :
                                </v-col>
                                <v-col cols="8" sm="4">
                                    <v-radio-group v-model="departure.is_liberate_collab" :rules="requiredCheck" row>
                                        <v-col cols="8" sm="4">
                                            <v-radio :label="'Oui'" :value="true"></v-radio>
                                        </v-col>
                                        <v-col cols="8" sm="4">
                                            <v-radio :label="'Non'" :value="false"></v-radio>
                                        </v-col>
                                    </v-radio-group>
                                </v-col>
                            </v-row>


                            <!-- Compte client v-if="departure.pole_label == 'FCT'" -->
                            <v-row v-if="departure.pole !== 'FCT'" align="center">
                                <v-col cols="12" sm="6">
                                    Le collaborateur est-il en inter mission ?*
                                    <v-radio-group class="pa-2" v-model="departure.is_inter_mission" :rules="requiredCheck" row>
                                        <v-radio :label="'Oui'" :value="true"></v-radio>
                                        <v-radio :label="'Non'" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>


                                <v-col v-if="!departure.is_inter_mission" cols="12" sm="6">
                                    <v-select v-model="departure.id_client" :items="clients_list" label="Compte client*"
                                        outlined :clearable="!isRecap && !isConsolide" :rules="required"
                                        :readonly="isRecap || isConsolide"></v-select>
                                </v-col>
                            </v-row>

                            <v-row justify="center">
                                <!-- Motif départ -->
                                <v-col cols="12" sm="6">
                                    <v-select v-model="departure.fk_departure_reason_id" :items="departure_reasons" outlined
                                        item-value="departure_reason_id" item-text="departure_reason_label"
                                        @change="filteringDepartureReasons($event, 1)" label="Motif du départ*"
                                        :readonly="isRecap || isConsolide"
                                        :disabled="(display_second_reason || display_third_reason) && !isRecap"
                                        :rules="required">

                                        <template v-slot:append>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">mdi-information</v-icon>
                                                </template>
                                                <span>Le motif de départ prioritaire à sélectionner correspond à celui qui
                                                    vous semble déclencheur de la décision.<br /> Vous avez la possibilité
                                                    d'en
                                                    ajouter un ou deux autres liés au départ, si vous le souhaitez.</span>
                                            </v-tooltip>
                                            <v-icon>
                                                mdi-menu-down
                                            </v-icon>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-icon
                                        v-if="departure.fk_departure_reason_id && !display_second_reason && !departure.fk_departure_second_reason_id && !isConsolide && !isRecap"
                                        @click="display_second_reason = true" color="depart_pink" class="ma-0 pa-0 mt-6">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <!-- Second motif -->

                            <v-row
                                v-if="departure.fk_departure_reason_id && (display_second_reason || departure.fk_departure_second_reason_id)"
                                align="center">
                                <!-- Motif départ -->
                                <v-col cols="12" sm="6">
                                    <v-select v-model="departure.fk_departure_second_reason_id" :items="departure_reasons_2"
                                        outlined item-value="departure_reason_id" item-text="departure_reason_label"
                                        label="2nd motif de départ*" :readonly="isRecap || isConsolide"
                                        :disabled="display_third_reason && !isRecap"
                                        @change="filteringDepartureReasons($event, 2)" :rules="required"></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-icon v-if="departure.fk_departure_reason_id && (display_second_reason || departure.fk_departure_second_reason_id)
                                        && !display_third_reason && !isConsolide && !isRecap" color="depart_pink"
                                        class="ma-0 pa-0 ml-1"
                                        @click="display_second_reason = false, departure.fk_departure_second_reason_id = null">
                                        mdi-minus-circle-outline
                                    </v-icon>
                                    <v-icon
                                        v-if="departure.fk_departure_reason_id && (display_second_reason || departure.fk_departure_second_reason_id) && !isConsolide
                                            && departure.fk_departure_second_reason_id && !display_third_reason && !departure.fk_departure_third_reason_id && !isRecap"
                                        @click="display_third_reason = true" color="depart_pink" class="ma-0 pa-0 ml-1">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                </v-col>
                            </v-row>

                            <!-- Troisième motif -->
                            <v-row v-if="departure.fk_departure_reason_id && (display_second_reason || departure.fk_departure_second_reason_id)
                                && (display_third_reason || departure.fk_departure_third_reason_id)" align="center"
                                class="ma-0 pa-0 mb-5">
                                <!-- Motif départ -->
                                <v-col cols="6" class="ma-0 pa-0">
                                    <v-select v-model="departure.fk_departure_third_reason_id" :items="departure_reasons_3"
                                        outlined item-value="departure_reason_id" item-text="departure_reason_label"
                                        label="Motif du départ*" :readonly="isRecap || isConsolide"
                                        :rules="required"></v-select>
                                </v-col>
                                <v-icon
                                    v-if="departure.fk_departure_reason_id && (display_second_reason || departure.fk_departure_second_reason_id)
                                        && (display_third_reason || departure.fk_departure_third_reason_id) && !isConsolide && !isRecap"
                                    color="depart_pink" class="ma-0 pa-0 ml-1"
                                    @click="display_third_reason = false, departure.fk_departure_third_reason_id = null">
                                    mdi-minus-circle-outline
                                </v-icon>
                            </v-row>


                            <!-- Commentaire -->
                            <v-col cols="12" class="ma-0 pa-0">
                                <v-textarea v-model="departure.commentary" label="Commentaire" outlined
                                    :readonly="isRecap || isConsolide" :clearable="!isRecap"></v-textarea>
                            </v-col>

                            <!-- Date départ légal-->
                            <v-row align="center" class="ma-0 pa-0 mb-5"
                                v-if="departure.fk_departure_type_id !== 8">
                                <p class="ma-0 pa-0 mr-2">Date de départ légale :</p>
                                <v-col cols="3">
                                    <v-chip>{{ new Date(departure.legal_date).toLocaleDateString('fr') }}</v-chip>
                                </v-col>
                            </v-row>
                            <p v-if="!departure.wanted_departure_date && !departure.is_wanted_early_departure">Le
                                collaborateur ne sollicite pas de réduction ou
                                de prolongement de préavis.</p>

                            <!-- Date départ souhaitée -->
                            <v-row align="center" class="ma-0 pa-0 mb-5"
                                v-if="![2,3,4,12, 10, 9, 8].includes(departure.fk_departure_type_id) && (departure.wanted_departure_date || departure.is_wanted_early_departure === 1)">
                                <p class="ma-0 pa-0 mr-2">Date de départ souhaitée par le collaborateur :</p>
                                <v-col cols="3">
                                    <v-chip>{{
                                        departure.is_wanted_early_departure !== 1 ? new
                                            Date(departure.wanted_departure_date).toLocaleDateString('fr') : "Au plus tôt"
                                    }}</v-chip>
                                </v-col>
                            </v-row>

                            <!-- Confirmation date départ souhaitée -->
                            <v-row class="ma-0 pa-0"
                                v-if="![2,3,4,12, 10, 9, 8].includes(departure.fk_departure_type_id) && (departure.wanted_departure_date || departure.is_wanted_early_departure === 1)">
                                <v-col class="ma-0 pa-0">
                                    <div align="center" style="width: fit-content;">
                                        <h4 class="ma-0 pa-0 mr-2 mb-2">{{ departure.is_wanted_early_departure ?
                                            "Validez-vous la date de départ au plus tôt souhaitée par le collaborateur ?" :
                                            "Validez-vous la date de départ souhaitée par le collaborateur" }} </h4>
                                        <div class="ml-6 mt-3 mb-5" style="display: flex;">
                                            <v-radio-group v-model="date_souhaite_refuse" :rules="required_radio" row>
                                                <v-radio label="Je valide" :value="true"
                                                    color="depart_pink" :readonly="isRecap || isConsolide"></v-radio>
                                                <v-radio label="Je refuse" :value="false"
                                                    color="depart_pink" :readonly="isRecap || isConsolide"></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>


                            <v-row class="ma-0 pa-0"
                                v-if="!!date_souhaite_refuse && departure.is_wanted_early_departure === 1">
                                <v-menu v-model="wanted_picker" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formatedDisplayDate.real_departure_date" outlined
                                            label="Date de départ au plus tôt*" append-icon="mdi-calendar"
                                            color="depart_pink" v-bind="attrs" v-on="on" :rules="required" readonly>
                                            <template v-slot:append>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on">mdi-information</v-icon>
                                                    </template>
                                                    <span>Attention : une fois que vous aurez soumis le formulaire, la date
                                                        que vous avez renseignée ne pourra plus être modifiée.<br> Veuillez
                                                        vous
                                                        assurer que la date saisie est correcte avant de valider votre
                                                        saisie</span>
                                                </v-tooltip>
                                                <v-icon>
                                                    mdi-calendar
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="depart_blue" scrollable :readonly="isRecapProp"
                                        v-model="departure.real_departure_date"></v-date-picker>
                                </v-menu>
                            </v-row>

                            <v-row class="ma-0 pa-0" v-if="date_souhaite_refuse !== null && date_souhaite_refuse">
                                <v-col cols="10">
                                    <v-alert width="120%" outlined type="warning" prominent border="left">
                                        Si toutefois vous convenez d'une nouvelle date avec le collaborateur, merci de lui
                                        demander de rédiger un nouveau courrier signé indiquant son accord avec cette
                                        nouvelle date. Une copie de ce courrier devra être adressée à ap_paie pour prise en
                                        compte.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-form>

                        <!-- Boutons -->
                        <v-row class="ma-0 pa-0 justify-end">
                            <!-- Bouton "retour" -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn color="depart_pink" class="depart_pink--text font-weight-bold mb-10 mr-10"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step--" outlined
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px; border: thin solid red;">
                                    Retour
                                </v-btn>
                            </div>

                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink" class=" white--text font-weight-bold mb-0 mr-5"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step++"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Continuer
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="ma-0 pa-0">
                        <v-form ref="step_three">
                            <h2 class="mb-5">Caractéristiques du collaborateur</h2>



                            <!-- Evaluation qualité-->
                            <v-row align="center">
                                <v-col cols="6">
                                    <v-select label="Évaluation qualité*" :items="first_grade"
                                        v-model="departure.quality_assessment" outlined color="depart_pink"
                                        :rules="required" :readonly="isRecap || isConsolide"></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select label="Évaluation potentiel*" :items="second_grade"
                                        v-model="departure.potential_assessment" outlined color="depart_pink"
                                        :rules="required" :readonly="isRecap || isConsolide"></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select label="Évaluation de l'investissement en interne*" :items="second_grade"
                                        v-model="departure.internal_investment_assessment" outlined color="depart_pink"
                                        :rules="required" :readonly="isRecap || isConsolide"></v-select>
                                </v-col>
                                <!-- Rôle particulier -->
                                <v-col cols="6">
                                    <v-select v-model="departure.special_role" :items="special_roles"
                                        label="Fonction interne chez mc2i*" outlined :clearable="!isRecap || isConsolide"
                                        :rules="required" :readonly="isRecap || isConsolide"></v-select>
                                </v-col>
                            </v-row>

                            <!-- Process fidélité? -->
                            <v-row class="ma-0 pa-0">
                                <p class="ma-0 pa-0 mr-3">
                                </p>
                                <v-switch v-model="departure.loyalty_process" class="ma-0 pa-0 ms-3" color="depart_pink"
                                    label="Le collaborateur était-il dans un processus de fidélisation ?" inset
                                    :readonly="isRecap || isConsolide"></v-switch>
                            </v-row>
                        </v-form>
                        <v-row class="ma-0 pa-0 mt-5 justify-end">
                            <!-- Bouton "retour" -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn color="depart_pink" class="depart_pink--text font-weight-bold mb-10 mr-10"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step--" outlined
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px; border: thin solid red;">
                                    Retour
                                </v-btn>
                            </div>

                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink" class=" white--text font-weight-bold mb-0 mr-5"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step++"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Continuer
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="4" class="ma-0 pa-0">
                        <v-form ref="step_four">
                            <h2 class="mb-5">Potentiel Alumni</h2>

                            <!-- Futur client? -->
                            <v-row class="ma-0 pa-0">
                                <div class="mb-5" style="display: flex; justify-content: center;">
                                    <v-switch label-position="left"
                                        label="Le collaborateur est-il identifié comme futur client ?"
                                        v-model="departure.is_future_client" class="ma-0 pa-0 ms-3" color="depart_pink"
                                        inset :readonly="isRecap || isConsolide"></v-switch>
                                </div>
                            </v-row>

                            <!-- Cadre Alumni -->
                            <v-row class="ma-0 pa-0">
                                <div class="mb-5" style="display: flex; justify-content: center;">
                                    <v-switch
                                        label="Le collaborateur accepte t-il d'être contacté dans le cadre des
                                                                                                                    Alumnis ?"
                                        v-model="departure.is_ok_contacted_alumni" class="ma-0 pa-0 ms-3"
                                        color="depart_pink" inset :readonly="isRecap || isConsolide"></v-switch>
                                </div>
                            </v-row>

                            <!-- Cadre Boomerang -->
                            <h2 class="mb-5">Boomerang</h2>
                            <v-row class="ma-0 pa-0">
                                <div class="mb-5" style="display: flex; justify-content: center;">
                                    <v-switch label="À contacter 3 / 4 mois après le départ ?"
                                        v-model="departure.is_ok_contacted_boomerang_three_months" class="ma-0 pa-0 ms-3"
                                        color="depart_pink" inset :readonly="isRecap || isConsolide"></v-switch>
                                </div>
                            </v-row>
                            <v-row class="ma-0 pa-0">
                                <div class="mb-5" style="display: flex; justify-content: center;">
                                    <v-switch label="À contacter 1 an après le départ ?"
                                        v-model="departure.is_ok_contacted_boomerang_one_year" class="ma-0 pa-0 ms-3"
                                        color="depart_pink" inset :readonly="isRecap || isConsolide"></v-switch>
                                </div>
                            </v-row>
                        </v-form>
                        <!-- Bouton "valider" départ -->
                        <v-row class="ma-0 pa-0 justify-end">
                            <!-- Bouton "retour" -->
                            <div class="btn_add_departure ma-0 pa-0">
                                <v-btn color="depart_pink" class="depart_pink--text font-weight-bold mb-10 mr-10"
                                    width="12rem" height="2.5rem" elevation="0" v-on:click="form_step--" outlined
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px; border: thin solid red;">
                                    Retour
                                </v-btn>
                            </div>

                            <div v-if="!isEditor && !isRecap" class="btn_add_departure ma-0 pa-0">
                                <v-btn id="validate_btn" color="depart_pink"
                                    class=" white--text font-weight-bold mb-10 mr-5" width="12rem" height="2.5rem"
                                    elevation="0" v-on:click="validateForm"
                                    style="text-transform: none !important; border-radius: 10px; font-size: 16px;">
                                    Valider le départ
                                </v-btn>
                            </div>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-form>


        <v-snackbar v-model="snackbar" color="success">
            <span class="white--text">Départ enregistré avec succès !</span>
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false">
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>


        <v-row class="pa-0 ma-0 warning-message" align="center">
            <v-col cols="1">
                <v-icon color="white" size="40"> mdi-alert-circle
                </v-icon>
            </v-col>
            <v-col cols="11" class="pl-2 pr-7">
                <div>
                    Si vous souhaitez réaliser des modifications suite à la validation du formulaire, merci de contacter le
                    service RH, qui sera en charge de les réaliser. Ces informations seront mises à jour automatiquement et
                    visibles sur vos formulaires.
                </div>
            </v-col>

        </v-row>




        <!-- Ecran de confirmation fermeture -->
        <v-dialog v-model="confirm_close" width="500">
            <v-card>
                <v-card-title class="text-h5 depart_pink white--text">
                    Confirmation
                </v-card-title>

                <v-card-text class="black--text mt-5">
                    Des informations ont été saisies. Souhaitez-vous les conserver ?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text v-on:click="closeForm(false, '')">
                        Ignorer
                    </v-btn>
                    <v-btn color="depart_blue" text v-on:click="updateDepart(true, true)">
                        Sauvegarder
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Ecran de confirmation validation formulaire -->
        <v-dialog v-model="confirm_form" width="500">
            <v-card>
                <v-card-title class="text-h5 depart_pink white--text">
                    Confirmation
                </v-card-title>

                <v-card-text v-if="!isValidate" class="black--text mt-5">
                    Souhaitez-vous confirmer le formulaire ?
                    Les informations seront transmises au service RH pour la gestion du départ (Préavis, STC, ...) ainsi
                    qu'au service informatique en charge de la récupération des matériels.
                </v-card-text>

                <v-card-text v-else class="black--text mt-5">
                    Veuillez patienter, le formulaire est en cours de validation ...
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isValidate" color="red" text @click="confirm_form = false">
                        Annuler
                    </v-btn>
                    <v-btn v-if="!isValidate" color="depart_blue" text v-on:click="updateDepart(false, true)">
                        Continuer
                    </v-btn>
                    <v-progress-circular v-else color="primary" indeterminate></v-progress-circular>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import CollaborateursService from '../services/collaborateursService'
const collaborateursService = new CollaborateursService()

import DepartsService from '../services/departsService'
const departsService = new DepartsService()

import ReasonsService from '../services/reasonsService'
const reasonsService = new ReasonsService()

import ReferentielService from '../services/referentielService'
const referentielService = new ReferentielService()

import PersonnesService from '../services/personnesService'
const personnesService = new PersonnesService()

import ClientsService from '../services/clientsService'
const clientsService = new ClientsService()


import NatureBonusService from '@/services/natureBonusService'
const natureBonusService = new NatureBonusService()


export default {
    name: 'ManagerForm',
    props: ['id_selected', 'isRecapProp', 'isEditor', 'isConsolide', "isRedirect"],
    data() {
        return {
            is_loading: true,
            uo_list: [],
            clients_list: [],
            initial_id_client: null,
            initial_date_mois_client: null,
            date_souhaite_refuse: null,
            confirm_form: false,
            isPM: false, //Variable présence PM
            isMeetingManager: false, //Variable présence rdv Manager / Consultant
            pm_selected: null,
            isRecap: false, //Défini si c'est un récapitulatif
            display_second_reason: false, //Affichage ou non du 2e motif
            display_third_reason: false, //Affichage ou non du 3e motif

            step_one: true, //Indicateur de champs erronés partie 1
            step_two: true, //Indicateur de champs erronés partie 2
            step_three: true, //Indicateur de champs erronés partie 3
            step_four: true, //Indicateur de champs erronés partie 4

            meeting_manager_consultant_picker: false, //Affichage date-picker date rdv manager consultant

            confirm_close: false, //Ecran de confirmation fermeture

            form_step: 1, //Etape du formulaire

            collaborateurs: [],
            departure_reasons: [],
            special_roles: ['Aucun', 'Offer Manager', 'Spécialiste', 'Expert', 'Leader', "Tribe leader", "Recruteur", "Formateur"],


            errorManager: "",
            errorDateRDV: "",
            departure_reasons_3: [],
            departure_reasons_2: [],
            isNatureBonus: false,
            isNatureBonus_2: false,
            isNatureBonus_3: false,
            isValidate: false,



            searchManager: null, //Recherche manager
            items_manager: [], //Liste des managers
            manager_selected: null, //Manager sélectionné
            first_grade: ["A", "B", "C", "D", "Non évalué"],
            second_grade: ["H", "M", "L", "Non évalué"],
            nature_bonus_list: [],
            nature_bonus_list_2: [],
            nature_bonus_list_3: [],



            nature_bonus_selected: {
                nature_bonus_1: {
                    id: null,
                    amount: null,
                },
                nature_bonus_2: {
                    id: null,
                    amount: null,
                },
                nature_bonus_3: {
                    id: null,
                    amount: null,
                },
            },
            nature_bonus_selected_init: {},


            wanted_picker: false, //Affichage date-picker date souhaitée de départ
            wanted_departure_manager_picker: false, //Affichage date-picker date souhaitée de départ manager
            formatedDisplayDate: {
                wanted_departure_manager_date: null,
                real_departure_date: null,
            },

            snackbar: false,


            //Données
            departure: {
                "cx": false,
                "account": null,
                "announcement_date": null,
                "birth_date": null,
                "commentary": null,
                "deletion_google_date": null,
                "delivery_pc_date": null,
                "delivery_phone_date": null,
                "delivery_receipt_date": null,
                "departure_id": null,
                "departure_reason_label": null,
                "departure_type_label": null,
                "email": null,
                "entry_date": null,
                "firstname": null,
                "fk_departure_reason_id": null,
                "fk_departure_second_reason_id": null,
                "fk_departure_third_reason_id": null,
                "fk_departure_status_id": null,
                "fk_departure_type_id": null,
                "fk_manager_id": null,
                "fk_person_id": null,
                "fk_uo_id": null,
                "glpi": false,
                "google": false,
                "has_pc": null,
                "has_phone": null,
                "internal_investment_assessment": null,
                "is_future_client": null,
                "is_ok_contacted_alumni": null,
                "lastname": "",
                "ldap": false,
                "legal_date": null,
                "loyalty_process": null,
                "manager_email": null,
                "manager_firstname": null,
                "manager_id": null,
                "manager_lastname": null,
                "manager_matricule": null,
                "manager_pole": null,
                "manager_uo": null,
                "matricule": null,
                "meeting_manager_consultant_date": null,
                "microsoft": false,
                "official_uo": null,
                "id_uo": null,
                "pc_number": null,
                "person_id": null,
                "pm_matricule": null,
                "pm_firstname": null,
                "pm_lastname": null,
                "pm_email": null,
                "pole": null,
                "poste_label": null,
                "potential_assessment": null,
                "prime": null,
                "quality_assessment": null,
                "real_departure_date": null,
                "reception_date": null,
                "school": null,
                "special_role": null,
                "stc_date": null,
                "uo_label": "",
                "wanted_departure_date": null,
                "whoz": false,
                "is_preavis_accepted": null,
                "id_client": null,
                "date_mois": null,
                "is_ok_contacted_boomerang_one_year": null,
                "is_ok_contacted_boomerang_three_months": null,
                "is_wanted_early_departure": null,
                "is_liberate_collab": null,
                "id_transco_motif_pe": null,
                "transco_motif_pe_label": null,
                "who_test": null,
                "is_inter_mission": false,
                "is_validate_sm" : false,
                "is_validate_dni" : false,
                "is_validate_fmi" : false,
            },

            departure_template: {},
            step_saving: false, //Indicateur de sauvegarde

            //Règles des champs du formulaire
            required: [
                value => !!value || 'Champs requis.'
            ],

            requiredCheck: [
                value => value !== null || 'Champs requis.'
            ],

            required_radio: [
                value => value !== null || 'Champs requis.'
            ],

            rules_pos: [
                value => value >= 0 || 'Valeur positive requise.'
            ]
        }
    },
    async mounted() {
        await this.getUo();
        await this.getReasons();
        await this.getDepart(); //Récupération des données du départ sélectionné
        await this.getClients()
        await this.getNatureBonus();
        await this.initNatureBonus();

        this.departure.who_test = this.$store.state.email
        if(this.departure.transco_motif_pe_label){
            this.departure.fk_departure_reason_id = this.departure.id_transco_motif_pe
        }

        if (this.departure.manager_email !== this.$store.state.email && window.location.pathname.split('/')[1] === "manager") {
            alert("Vous n'êtes pas le manager de ce départ. Vous ne pouvez pas le modifier.")
            window.location.href = "/home";
            return
        } else if (this.departure.fk_departure_status_id === 7 && this.isRedirect) {
            this.isRecap = true;
        }


        if(this.departure.is_inter_mission === 1){
            this.departure.is_inter_mission = true
        }else if(this.departure.is_inter_mission === 0 || this.departure.is_inter_mission === null){
            this.departure.is_inter_mission = false
        }


        if (this.departure.is_preavis_accepted) {
            this.date_souhaite_refuse = true;
        }else if(this.departure.is_preavis_accepted === 0){
            this.date_souhaite_refuse = false;
        }

        if(this.departure.is_liberate_collab === 1 ){
            this.departure.is_liberate_collab = true
        }else if(this.departure.is_liberate_collab === 0){
            this.departure.is_liberate_collab = false
        }

        if (this.departure.meeting_manager_consultant_date !== null) {
            this.isMeetingManager = true;
        }


        if (this.departure.fk_departure_second_reason_id !== null) {
            this.departure_reasons_2 = this.departure_reasons
            this.display_second_reason = true;
        }

        if (this.departure.fk_departure_third_reason_id !== null ) {
            this.departure_reasons_3 = this.departure_reasons
            this.display_third_reason = true;
        }

        if (this.isRecapProp) {
            this.isRecap = true;
        }

        this.is_loading = false;
    },
    methods: {
        async getDepart() { //Récupérer le départ sélectionné
            if (this.id_selected) {
                this.departure = await departsService.getDepartFromId(this.id_selected);

                this.initial_id_client = this.departure.id_client
                this.initial_date_mois_client = this.departure.date_mois_client
                this.departure_template = JSON.parse(JSON.stringify(this.departure));
                if (this.departure.pm_matricule) {
                    this.pm_selected = (await collaborateursService.getCollabFromIdSX(this.departure.pm_matricule));
                    this.items_manager = [
                        {
                            value: this.pm_selected,
                            text: `${this.pm_selected.matricule}. ${this.pm_selected.firstName} ${this.pm_selected.lastName}`
                        }
                    ];
                    this.isPM = true;
                }

            }
        },
        async getCollabs() { //Récupère la liste des collaborateurs
            if (this.id_selected)
                this.collaborateurs = await collaborateursService.getCollab();
        },
        async getReasons() {
            this.departure_reasons = await reasonsService.getReasons();
        },
        async getUo() {
            this.uo_list = (await referentielService.getUo())?.map(uo => {
                return {
                    value: uo.id_uo,
                    text: uo.label
                }
            });
        },
        async getClients() {
            var clients_list = await clientsService.getClients();
            clients_list = clients_list.map(client => {
                return {
                    value: client.id_client,
                    text: client.code_client,
                    date_mois: client.date_mois_client
                }
            })
            this.clients_list = clients_list;
        },


        async getNatureBonus() {
            let nature_bonus = await referentielService.getNatureBonus();
            this.nature_bonus_list = nature_bonus.map(nature_bonus => {
                return {
                    value: nature_bonus.id_nature_bonus,
                    text: nature_bonus.label
                }
            });
        },


        popNatureBonus(id_nature_bonus, nb_list) {
            switch (nb_list) {
                case 2:
                    this.nature_bonus_list_2 = this.nature_bonus_list.filter(nature_bonus => nature_bonus.value != id_nature_bonus);
                    break;
                case 3:
                    this.nature_bonus_list_3 = this.nature_bonus_list_2.filter(nature_bonus => nature_bonus.value != id_nature_bonus);
                    break;
            }
        },


        handleCheckboxChange(value) {
            // Désactiver les autres cases à cocher si l'une d'entre elles est cochée
            if (value !== null) {
                this.departure.is_liberate_collab = value;
                // Désactiver les autres cases à cocher
                // Vérifiez si une case à cocher est cochée et désactivez les autres
                if (value === true) {
                    this.$refs.nonCheckbox.$data.isActive = false;
                } else if (value === false) {
                    this.$refs.ouiCheckbox.$data.isActive = false;
                }
            }
        },

        async initNatureBonus() {
            let list_nature = await natureBonusService.getNatureBonusWithID(this.id_selected)
            if (list_nature.length > 0) {
                this.isNatureBonus = true
                for (let i = 0; i < list_nature.length; i++) {
                    if (i == 0) {
                        this.nature_bonus_selected.nature_bonus_1.id = list_nature[i].id_nature_bonus
                        this.nature_bonus_selected.nature_bonus_1.amount = list_nature[i].amount
                        this.nature_bonus_list_2 = this.nature_bonus_list.filter(nature_bonus => nature_bonus.value != this.nature_bonus_selected.nature_bonus_1.id);
                    }
                    if (i == 1) {
                        this.nature_bonus_selected.nature_bonus_2.id = list_nature[i].id_nature_bonus
                        this.nature_bonus_selected.nature_bonus_2.amount = list_nature[i].amount
                        this.isNatureBonus_2 = true
                        this.nature_bonus_list_3 = this.nature_bonus_list_2.filter(nature_bonus => nature_bonus.value != this.nature_bonus_selected.nature_bonus_2.id);
                    }
                    if (i == 2) {
                        this.nature_bonus_selected.nature_bonus_3.id = list_nature[i].id_nature_bonus
                        this.nature_bonus_selected.nature_bonus_3.amount = list_nature[i].amount
                        this.isNatureBonus_3 = true
                    }
                }
            }
            this.nature_bonus_selected_init = JSON.parse(JSON.stringify(this.nature_bonus_selected));
        },


        async saveActionNatureBonus() {
            let key_nature_bonus = Object.keys(this.nature_bonus_selected)
            for (let i = 0; i < key_nature_bonus.length; i++) {
                //On regarde pour créer la ligne en BDD
                if (this.nature_bonus_selected[key_nature_bonus[i]].id !== null && this.nature_bonus_selected_init[key_nature_bonus[i]].id === null) {
                    //Creation de la ligne
                    await natureBonusService.createNatureBonus(this.id_selected, this.nature_bonus_selected[key_nature_bonus[i]])
                } else if (this.nature_bonus_selected[key_nature_bonus[i]].id === null && this.nature_bonus_selected_init[key_nature_bonus[i]].id !== null) {
                    //Suppression de la ligne
                    await natureBonusService.deleteNatureBonusWithID(this.id_selected, this.nature_bonus_selected_init[key_nature_bonus[i]].id)
                }
            }
            await natureBonusService.updateNatureBonusWithID(this.id_selected, this.nature_bonus_selected)
        },


        closeForm(isSaving, snackbar_type) {
            if (window.location.pathname.split('/')[1] === "manager") {
                if (isSaving) {
                    this.snackbar = true;
                } else {
                    return window.location.href = "/home"
                }
            } //Fermer le formulaire
            this.$emit("closeForm", { is_close: isSaving, snackbar_type: snackbar_type });
            //reset the loader
            this.isValidate = false;
        },
        validateForm() { //Valider le formulaire
            this.step_one = this.$refs.step_one.validate()
            this.step_two = this.$refs.step_two.validate()
            this.step_three = this.$refs.step_three.validate()
            this.step_four = this.$refs.step_four.validate()
            if (this.$refs.form_departure.validate() && this.step_one && this.step_two
                && this.step_three && this.step_four) {
                this.confirm_form = true;
            }
            else
                this.$refs.form_departure.$el.scrollIntoView({ behavior: 'smooth' })
        },
        autoCompletePM(pm) {
            if (pm !== null) {
                this.departure.pm_matricule = pm.matricule;
                this.departure.pm_firstname = pm.firstName;
                this.departure.pm_lastname = pm.lastName;
                this.departure.pm_email = pm.email;
            }
        },
        async updateDepart(isSaving, isClosing) {
            this.departure.date_mois_client = this.initial_id_client == this.departure.id_client ? this.initial_date_mois_client : this.departure.id_client !== null ? this.clients_list.find(el => el.value == this.departure.id_client).date_mois : ""
            if (this.isPM) {
                await personnesService.updatePersonPm(this.departure.pm_matricule, this.departure.person_id)
            }

            if (!this.isMeetingManager)
                this.departure.meeting_manager_consultant_date = null

            if (this.isNatureBonus) {
                this.saveActionNatureBonus()
            }

            if (isSaving) {

                if (this.isPM && !this.$refs.autocomplete_manager.validate()) {
                    this.errorManager = "Veuillez sélectionner un manager"
                    this.step_two = false;
                    return
                } else if (this.isMeetingManager && !this.$refs.text_field_meeting_manager_consultant_date.validate()) {
                    this.errorDateRDV = "Veuillez sélectionner une date"
                    this.step_two = false;
                } else {
                    this.errorManager = ""
                    this.errorDateRDV = ""
                    this.step_two = true;
                    await departsService.updateDepartManager(this.departure, "saving", this.date_souhaite_refuse);
                }
            }
            else {
                //Trigger the loader to show in pop up
                this.isValidate = true;
                if (this.date_souhaite_refuse || !this.departure.wanted_departure_date || this.departure.is_wanted_early_departure){
                    this.departure.real_departure_date = this.departure.is_wanted_early_departure ? this.departure.real_departure_date : this.departure.wanted_departure_date
                }


                this.departure.real_departure_date = this.departure.real_departure_date === null ? this.departure.legal_date : this.departure.real_departure_date


                await departsService.updateDepartManager({
                    ...this.departure,
                    first_name: this.departure.firstname,
                    last_name: this.departure.lastname,
                    pole_label: this.departure.pole,
                    first_name_manager: this.departure.manager_firstname,
                    last_name_manager: this.departure.manager_lastname,
                }, "validation", this.date_souhaite_refuse);
            }

            this.confirm_form = false;
            this.closeForm(!isClosing, "depart_update");
        },
        saveForm() {
            this.updateDepart(true, false)
            this.departure_template = JSON.parse(JSON.stringify(this.departure));
        },


        filteringDepartureReasons(id_reason, nb) {
            if (nb === 1) {
                this.departure_reasons_2 = this.departure_reasons.filter(reason => reason.departure_reason_id !== id_reason)
            } else {
                this.departure_reasons_3 = this.departure_reasons_2.filter(reason => reason.departure_reason_id !== id_reason)
            }
        }
    },


    watch: {
        async searchManager(newValue) {
            if (newValue !== "" && newValue !== null && this.pm_selected === null) {
                this.items_manager = (await collaborateursService.getCollab(newValue)).map((collab) => {
                    return {
                        text: `${collab.matricule}. ${collab.firstName} ${collab.lastName}`,
                        value: {
                            ...collab
                        }
                    }
                })
            }
        },


        isPM(newValue) {
            if (!newValue) {
                this.departure.pm_matricule = null;
                this.departure.pm_firstname = null;
                this.departure.pm_lastname = null;
                this.departure.pm_email = null;

                this.pm_selected = null;
            }
        },

        isMeetingManager(newValue) {
            if (!newValue) {
                this.departure.meeting_manager_consultant_date = null;
            }
        },


        isNatureBonus(newValue) {
            if (!newValue) {
                this.nature_bonus_selected = {
                    nature_bonus_1: {
                        id: null,
                        amount: null,
                    },
                    nature_bonus_2: {
                        id: null,
                        amount: null,
                    },
                    nature_bonus_3: {
                        id: null,
                        amount: null,
                    },
                }
                this.isNatureBonus_2 = false;
                this.isNatureBonus_3 = false;
            }
        },

        "departure.real_departure_date"() {
            if (this.departure.real_departure_date !== null) {
                this.formatedDisplayDate.real_departure_date = moment(this.departure.real_departure_date).format("DD/MM/YYYY")
            }
        },
    }
}
</script>

<style scoped>
@media screen and (max-width: 1450px) {
    .v-input {
        transform: scale(0.8);
        transform-origin: left;
    }
}

.manager_form {
    background-color: white;
    height: fit-content;
    min-height: 25rem;
}

.v-alert {
    font-size: .8rem;
}

h2 {
    font-size: 1.5rem;
}

p {
    font-size: .8rem;
}

#close_form,
#save_form {
    transition-duration: .4s;
    transition-timing-function: cubic-bezier();
}

#close_form:hover {
    transform: scale(1.2) rotate(90deg);
}

#save_form:hover {
    transform: scale(1.2);
}

.switch_manager .v-input--selection-controls__input div {
    color: green;
}


.warning-message {
    background-color: rgba(255, 166, 0, 0.705);
    color: white;
    font-size: 15px;
    text-align: justify;
}
</style>