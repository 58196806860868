import apiClient from '../plugins/api_client.js'
import store from "../store/index.js"

export const baseUrl = process.env.VUE_APP_URL_API + "/departs";

export default class DepartsService {


  getNextStatus({ is_validate_fmi, is_validate_sm, is_validate_dni }) {
    if (is_validate_dni && is_validate_sm && is_validate_fmi) {
      return 5
    } else if (is_validate_sm && !is_validate_dni && !is_validate_fmi) {
      return 7
    } else if (is_validate_sm && is_validate_dni && !is_validate_fmi) {
      return 7
    } else if (is_validate_sm && !is_validate_dni && is_validate_fmi) {
      return 8
    } else if (!is_validate_sm && is_validate_dni && is_validate_fmi) {
      return 1
    } else if (!is_validate_sm && is_validate_dni && !is_validate_fmi) {
      return 10
    } else if (!is_validate_sm && !is_validate_dni && is_validate_fmi) {
      return 1
    }
  }



  getCollabWithoutDepart(limit, offset, filters) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${process.env.VUE_APP_URL_API}/onboarding`,
        {
          params: {
            limit: limit,
            offset: offset,
            uo: filters.uo_selected,
            pole: filters.poles_selected,
            search: filters.search
          },
          headers: { 'Authorization': 'Bearer ' + store.state.token }
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }



  getDeparts(limit, offset, filters, old) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/rh`,
        {
          params: {
            limit: limit,
            offset: offset,
            pole: filters.poles_selected,
            uo: filters.uo_selected,
            status: filters.status_selected,
            types: filters.types_selected,
            date_begin: filters.date_begin,
            date_finished: filters.date_finished,
            old: old === 1 || old === 2 ? old : 0,
            limit_date_sup: old === 1 ? (new Date()).toISOString().split('T')[0] : "none",
            limit_date_inf: old === 2 ? (new Date()).toISOString().split('T')[0] : "none",
            tab: old,
            search: filters.search
          },
          headers: { 'Authorization': 'Bearer ' + store.state.token }
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getDepartFromId(id) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/` + id,
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response.data[0]);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getManagerDeparts(email, limit, offset, filters, old) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/managers/email/${email}`,
        {
          params: {
            limit: limit,
            offset: offset,
            pole: filters.poles_selected,
            uo: filters.uo_selected,
            status: filters.status_selected,
            types: filters.types_selected,
            date_begin: filters.date_begin,
            date_finished: filters.date_finished,
            old: old,
            search: filters.search
          },
          headers: { 'Authorization': 'Bearer ' + store.state.token }
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getFmiDeparts(limit, offset, filters, old) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/fmi`,
        {
          params: {
            limit: limit,
            offset: offset,
            pole: filters.poles_selected,
            uo: filters.uo_selected,
            status: filters.status_selected,
            types: filters.types_selected,
            date_begin: filters.date_begin,
            date_finished: filters.date_finished,
            old: old,
            search: filters.search
          },
          headers: { 'Authorization': 'Bearer ' + store.state.token }
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getDniDeparts(limit, offset, filters, old) {

    let status = ""
    switch (old) {
      case 0:
        old = 0
        status = "is_to_type"
        break;
      case 1:
        old = 0
        status = "is_current"
        break;
      case 2:
        old = 0
        status = "is_to_delete"
        break;
      case 3:
        old = 1
        status = "all"
        break;
    }

    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/dni`,
        {
          params: {
            limit: limit,
            offset: offset,
            pole: filters.poles_selected,
            uo: filters.uo_selected,
            status: status,
            types: filters.types_selected,
            date_begin: filters.date_begin,
            date_finished: filters.date_finished,
            old: old,
            search: filters.search
          },
          headers: { 'Authorization': 'Bearer ' + store.state.token }
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  createDepart(departure, personId) {
    return new Promise((resolve, reject) => {
      apiClient.post(`${baseUrl}`,
        {
          fk_person_id: personId,
          matricule: departure.matricule,
          first_name: departure.firstname,
          last_name: departure.lastname,
          mail_collab: departure.email,
          mail_manager: departure.manager_email,
          uo_label: departure.uo_label,
          pole_label: departure.pole_label,
          departure_type_label: departure.departure_type_label,
          poste_label: departure.poste_label,
          fk_departure_type_id: departure.fk_departure_type_id,
          reception_date: departure.reception_date,
          delivery_receipt_date: departure.delivery_receipt_date,
          wanted_departure_date: departure.wanted_departure_date,
          legal_date: departure.legal_date,
          is_priornotice_reduction: departure.is_priornotice_reduction,
          is_priornotice_elongation: departure.is_priornotice_elongation,
          end_delay_prevenance: departure.end_delay_prevenance,
          date_information_consultant: departure.date_information_consultant, demand_date_approval: departure.demand_date_approval,
          end_date_refractation: departure.end_date_refractation,
          sign_date_convention: departure.sign_date_convention,
          preavis_reception_date: departure.preavis_reception_date,
          end_demand_date_approval: departure.end_demand_date_approval,
          return_date_collab: departure.return_date_collab,
          is_wanted_early_departure: departure.is_wanted_early_departure,
          early_rdv_pc_date: departure.early_rdv_pc_date,
          id_pe: departure.id_pe,
          who_test: departure.who_test,
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error);
        });
    })
  }

  updateDepart(departure, updateType) {
    return new Promise((resolve, reject) => {
      apiClient.patch(`${baseUrl}/rh/${departure.departure_id}`,
        {
          "fk_departure_status_id": updateType == "saving" ? departure.fk_departure_status_id : 3,
          "fk_departure_type_id": departure.fk_departure_type_id,
          "birth_date": departure.birth_date,
          "entry_date": departure.entry_date,
          "poste_label": departure.poste_label,
          "id_school": departure.id_school,
          "id_pole": departure.id_pole,
          "id_uo": departure.id_uo,
          "email": departure.email,
          "manager_email": departure.manager_email,
          "reception_date": departure.reception_date,
          "delivery_receipt_date": departure.delivery_receipt_date,
          "legal_date": departure.legal_date,
          "wanted_departure_date": departure.wanted_departure_date,
          "is_priornotice_reduction": departure.is_priornotice_reduction,
          "is_priornotice_elongation": departure.is_priornotice_elongation,
          "end_delay_prevenance": departure.end_delay_prevenance,
          "date_information_consultant": departure.date_information_consultant,
          "id_manager": departure.id_manager,
          "demand_date_approval": departure.demand_date_approval,
          "end_date_approval": departure.end_date_approval,
          "sign_date_convention": departure.sign_date_convention,
          "preavis_reception_date": departure.preavis_reception_date,
          "end_date_refractation": departure.end_date_refractation,
          "end_demand_date_approval": departure.end_demand_date_approval,
          "return_date_collab": departure.return_date_collab,
          "is_wanted_early_departure": departure.is_wanted_early_departure,
          "who_test": departure.who_test,
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  updateDepartManager(departure, updateType, date_souhaite_refuse) {


    if (updateType !== "saving") {
      departure.fk_departure_status_id = this.getNextStatus({
        ...departure,
        is_validate_sm: true
      })
    }


    return new Promise((resolve, reject) => {
      apiClient.patch(`${baseUrl}/managers/${departure.departure_id}`,
        {
          "first_name": departure.first_name,
          "last_name": departure.last_name,
          "pole_label": departure.pole_label,
          "poste_label": departure.poste_label,
          "first_name_manager": departure.first_name_manager,
          "last_name_manager": departure.last_name_manager,
          "account": departure.account,
          "fk_departure_reason_id": departure.fk_departure_reason_id,
          "fk_departure_second_reason_id": departure.fk_departure_second_reason_id,
          "fk_departure_third_reason_id": departure.fk_departure_third_reason_id,
          "fk_departure_status_id": departure.fk_departure_status_id,
          "meeting_manager_consultant_date": departure.meeting_manager_consultant_date,
          "real_departure_date": departure.real_departure_date,
          "commentary": departure.commentary,
          "loyalty_process": departure.loyalty_process,
          "quality_assessment": departure.quality_assessment,
          "potential_assessment": departure.potential_assessment,
          "internal_investment_assessment": departure.internal_investment_assessment,
          "is_future_client": departure.is_future_client,
          "is_ok_contacted_alumni": departure.is_ok_contacted_alumni,
          "wanted_departure_date": departure.wanted_departure_date,
          "being_entered_manager": updateType == "saving" ? 1 : 0,
          "special_role": departure.special_role,
          "wanted_departure_manager_date": departure.wanted_departure_manager_date,
          "is_priornotice_reduction": departure.is_priornotice_reduction,
          "is_priornotice_elongation": departure.is_priornotice_elongation,
          "is_preavis_accepted": date_souhaite_refuse,
          "matricule": departure.matricule,
          "id_client": departure.is_inter_mission ? null : departure.id_client,
          "date_mois_client": departure.date_mois_client,
          "is_ok_contacted_boomerang_one_year": departure.is_ok_contacted_boomerang_one_year,
          "is_ok_contacted_boomerang_three_months": departure.is_ok_contacted_boomerang_three_months,
          "is_wanted_early_departure": departure.is_wanted_early_departure,
          "is_validate_sm": updateType !== "saving" ? true : false,
          "is_liberate_collab": departure.is_liberate_collab,
          "is_inter_mission": departure.is_inter_mission,
          "fk_departure_type_id": departure.fk_departure_type_id,
          "update_type": updateType,
          "who_test": departure.who_test,
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  updateDepartFmi(departure, updateType) {


    if (updateType !== "saving") {
      console.log('HOLA')
      departure.fk_departure_status_id = this.getNextStatus({
        ...departure,
        is_validate_fmi: true
      })
    }

    return new Promise((resolve, reject) => {
      apiClient.patch(`${baseUrl}/fmi/${departure.departure_id}`,
        {
          "pc_number": departure.pc_number,
          "rdv_pc_date": departure.rdv_pc_date,
          "delivery_pc_date": departure.delivery_pc_date,
          "fk_departure_status_id": departure.fk_departure_status_id,
          "fmi_commentary": departure.fmi_commentary,
          "being_entered_fmi": updateType == "saving" ? 1 : 0,
          "first_name": departure.firstname,
          "last_name": departure.lastname,
          "pole_label": departure.pole,
          "who_test": departure.who_test,
          "is_validate_fmi": updateType !== "saving" ? true : false,
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  updateDepartDni(departure, updateType, is_to_delete) {


    if (updateType !== "saving") {
      departure.fk_departure_status_id = this.getNextStatus({
        ...departure,
        is_validate_dni: true
      })
    }


    return new Promise((resolve, reject) => {
      apiClient.patch(`${baseUrl}/dni/${departure.departure_id}`,
        {
          "ldap": departure.ldap,
          "microsoft": departure.microsoft,
          "google": departure.google,
          "cx": departure.cx,
          "glpi": departure.glpi,
          "whoz": departure.whoz,
          "suspention_google_date": departure.suspention_google_date,
          "deletion_google_date": departure.deletion_google_date,
          "fk_departure_status_id": departure.fk_departure_status_id,
          "being_entered_dni": updateType == "saving" ? 1 : 0,
          "ownership_transfer": departure.ownership_transfer,
          "is_to_delete": is_to_delete,
          "is_validate_dni": updateType !== "saving" ? true : false,
          "who_test": departure.who_test,
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  deleteDepart(id) {
    apiClient.delete(`${baseUrl}/` + id,
      { headers: { 'Authorization': 'Bearer ' + store.state.token } })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        return error
      });
  }

  updateRealDepartureDate(status_id, departure_id, real_departure_date, departure) {
    return new Promise((resolve, reject) => {
      apiClient.patch(`${baseUrl}/realdate/${departure_id}`,
        {
          "departure_status_id": status_id,
          "real_departure_date": real_departure_date,
          "old_real_departure_date": departure.real_departure_date,
          "first_name": departure.firstname,
          "last_name": departure.lastname,
          "pole_label": departure.pole,
          "uo_label": departure.uo,
          "manager_email": departure.manager_email,
          "fk_departure_type_id": departure.fk_departure_type_id,
          "who_test": departure.who_test,
        },
        { headers: { 'Authorization': 'Bearer ' + store.state.token } })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getCountDeparts(filters, old) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/rh/count`, {
        params: {
          pole: filters.poles_selected,
          uo: filters.uo_selected,
          status: filters.status_selected,
          types: filters.types_selected,
          date_begin: filters.date_begin,
          date_finished: filters.date_finished,
          old: old === 1 || old === 2 ? 1 : old,
          limit_date_sup: old === 1 ? (new Date()).toISOString().split('T')[0] : "none",
          limit_date_inf: old === 2 ? (new Date()).toISOString().split('T')[0] : "none",
          search: filters.search
        },
        headers: { 'Authorization': 'Bearer ' + store.state.token }
      })
        .then(function (response) {
          resolve(response.data[0]);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getCountManagerDeparts(email, filters, old) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/managers/count/${email}`,
        {
          params: {
            pole: filters.poles_selected,
            uo: filters.uo_selected,
            status: filters.status_selected,
            types: filters.types_selected,
            date_begin: filters.date_begin,
            date_finished: filters.date_finished,
            old: old,
            search: filters.search
          },
          headers: { 'Authorization': 'Bearer ' + store.state.token }
        })
        .then(function (response) {
          resolve(response.data[0]);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getCountFmiDeparts(filters, old) {
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/fmi/count`, {
        params: {
          pole: filters.poles_selected,
          uo: filters.uo_selected,
          status: filters.status_selected,
          types: filters.types_selected,
          date_begin: filters.date_begin,
          date_finished: filters.date_finished,
          old: old,
          search: filters.search
        },
        headers: { 'Authorization': 'Bearer ' + store.state.token }
      })
        .then(function (response) {
          resolve(response.data[0]);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }

  getCountDniDeparts(filters, old) {

    let status = ""
    switch (old) {
      case 0:
        old = 0
        status = "is_to_type"
        break;
      case 1:
        old = 0
        status = "is_current"
        break;
      case 2:
        old = 0
        status = "is_to_delete"
        break;
      case 3:
        old = 1
        status = "all"
        break;
    }
    return new Promise((resolve, reject) => {
      apiClient.get(`${baseUrl}/dni/count`, {
        params: {
          pole: filters.poles_selected,
          uo: filters.uo_selected,
          status: status,
          types: filters.types_selected,
          date_begin: filters.date_begin,
          date_finished: filters.date_finished,
          old: old,
          search: filters.search
        },
        headers: { 'Authorization': 'Bearer ' + store.state.token }
      })
        .then(function (response) {
          resolve(response.data[0]);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  }
}